import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import {
  BsFileEarmarkExcel,
  BsFileEarmarkPdf,
  BsFileExcelFill,
  BsFilePdfFill,
} from "react-icons/bs";
import CustomTable from "../../components/Table";
import { betHistoryAPI, currentBetsAPI } from "../../service/AuthService";
import CustomInput from "../../components/CustomInput";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  buttonColors,
  exportToCsvReactCsv,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import { useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import ExporttoPDF from "../../assets/img/pdf_img.svg";
import ExporttoExcel from "../../assets/img/excel_img.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function BetHistory() {
  const userData = useSelector((state) => state.account.userData);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [data, setData] = React.useState([]);
  const [betType, setBetType] = React.useState("ALL");
  const [betStatus, setBetStatus] = React.useState("MATCHED");
  const [totalElements, setTotalElements] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [totalBets_, setTotalBets] = React.useState(0);
  const [totalAmount_, setTotalAmount] = React.useState(0);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [csvReport, setCsvReport] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [reportType, setReportType] = React.useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = React.useState("CRICKET");
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const columns = [
    {
      name: "Date",
      id: "created_date",
      element: (item) => (
        <Td border="none">
          <Text>
            {moment(item.created_date + "Z").format("DD-MM-YYYY HH:mm:ss")}
          </Text>
        </Td>
      ),
    },
    {
      name: "Competition",
      id: "competition_name",
    },
    {
      name: "Event",
      id: "event_name",
    },
    {
      name: "Market",
      id: "marketName",
    },
    {
      name: "Runner",
      id: "runner_name",
    },
    {
      name: "Side",
      id: "side",
      element: (item) => (
        <Td border="none">
          <Text>{item.back_run ? "Back" : "Lay"}</Text>
        </Td>
      ),
    },
    {
      name: "Line",
      id: "",
      element: (item) => (
        <Td border="none">
          <Text>--</Text>
        </Td>
      ),
    },
    {
      name: "Rate",
      id: "rate",
      element: (item) => (
        <Td border="none">
          <Text>{item.back_run || item.lay_run}</Text>
        </Td>
      ),
    },
    {
      name: "Amount",
      id: "amount",
    },
    {
      name: "Profit/Loss",
      id: "profit",
      element: (item) => (
        <Td border="none">
          <Text color={item.profit > 0 ? "green.500" : "red.500"}>
            {item.profit}
          </Text>
        </Td>
      ),
    },
    {
      name: "Status",
      id: "amount",
      element: (item) => (
        <Td border="none">
          <Text>Placed</Text>
        </Td>
      ),
    },
  ];
  const isMobileView = window.innerWidth < 768;

  const betHistoryAPIHandler = useCallback(async () => {
    const payload = {
      from: moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
      matched: betStatus === "MATCHED",
      page: 0,
      size: rowsPerPage,
      sport: type,
      to: moment(endDate)
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS"),
      userName: userData.username,
    };

    const res = await betHistoryAPI(payload);
    console.log(res, "resData");
    if (res.status === 200) {
      setData(res.data.response.content);
    }
  }, [betStatus, endDate, startDate, type, userData.username]);

  useEffect(() => {
    betHistoryAPIHandler();
  }, [betHistoryAPIHandler, rowsPerPage]);

  const search = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") return betHistoryAPIHandler();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["comment", "debitInfo", "creditInfo", "updatedBalance"],
        e.target.value
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    betHistoryAPIHandler();
  };

  const handleReset = (e) => {
    setStartDate(moment().format("YYYY-MM-DDTHH:MM"));
    setEndDate(moment().format("YYYY-MM-DDTHH:MM"));
  };

  const tableRef = useRef(null);

  const generateReportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#table" });
    doc.save("table.pdf");
  };

  return (
    <Box
      bg={{ base: "#fff", md: "#fff" }}
      borderRadius={10}
      // p={{ base: 0, md: 2 }}
      fontSize="sm"
      textColor="lightgray"
      className="setting-page-main bet-history-main"
    >
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        color="white"
        background="#27A6C3"
        marginTop="-5px"
      >
        <Text textColor="white" fontSize="sm" color="#fff !important">
          Bet History
        </Text>
      </Box>
      <Box
        w="60%"
        display="flex"
        alignItems="center"
        justifyContent={{
          base: "space-between",
          md: "flex-start",
        }}
      >
        <Box
          flexDirection={{
            base: "row",
            md: "row",
          }}
          display={{base:"block", md:"flex"}}
          justifyContent="center"
          alignItems="center"
          ml={{base:"10px", md:"0px"}}
          my="2"
          width={"100%"}
          className="input-box-head"
        >
          <CustomSelect
            borderRadius="5"
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: isMobileView ? "50%" : "40rem",
            }}
            size="md"
            borderColor="#ccc"
            onChange={(e) => setType(e.target.value)}
            options={[
              {
                label: "Sports",
              },
              {
                label: "Cricket",
                value: "CRICKET",
              },
              {
                label: "Tennis",
                value: "TENNIS",
              },
              {
                label: "Football",
                value: "FOOTBALL",
              },
            ]}
            value={type}
            className="col-select"
          ></CustomSelect>
          <CustomInput
            placeholder="Select Start Date"
            type="date"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
              width: isMobileView ? "50%" : "40rem",
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
            max={moment().format("YYYY-MM-DD")}
            className="col-select"
          />
          <CustomInput
            placeholder="Select Start Date"
            type="date"
            bg="transparent"
            borderRadius="5"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: isMobileView ? "50%" : "40rem",
            }}
            size="md"
            max={moment().format("YYYY-MM-DD")}
            min={moment(startDate).format("YYYY-MM-DD")}
            className="col-select"
          />
          <Box display="flex">
            <Button
              className="gradiant-btn go-btn"
              size="sm"
              onClick={handleSubmit}
              mr={1}
            >
              Go
            </Button>

            <Button
              className="gradiant-btn reset-btn"
              size="sm"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Box>

      {/* web  */}
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={"flex-start"}
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
      >
        <Box
          w={{ base: "100%", md: "auto" }}
          borderRadius={{ base: "0px", md: "5px" }}
          display="flex"
          alignItems="center"
          m={{ base: "10px", md: "10px" }}
          border="1px solid #c7c7c7"
        >
          <CustomInput
            placeholder="Search"
            border="none"
            color="gray"
            _placeholder={{ color: "gray" }}
            _focus={{ boxShadow: "none" }}
            onChange={(e) => search(e)}
            p={{
              base: "10px",
              md: "20px",
            }}
            outline="none"
            mt="-10px"
            containerStyle={{
              paddingTop: "5px",
              width: "70%",
            }}
            value={searchValue}
          />{" "}
          {searchValue ? (
            <CloseIcon
              onClick={() => {
                setSearchValue("");
                betHistoryAPIHandler();
              }}
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
              cursor="pointer"
            />
          ) : (
            <SearchIcon
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
            />
          )}
        </Box>
        <Box display="flex">
          <img
            src={ExporttoPDF}
            alt="PDF Logo"
            onClick={generateReportPDF}
            style={{ cursor: "pointer" }}
          />

          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <img
              src={ExporttoExcel}
              alt="PPT Logo"
              style={{ cursor: "pointer" }}
            />{" "}
          </DownloadTableExcel>
        </Box>
        <Spacer />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          mr="5"
        >
          <Text className="text-black">Show</Text>
          <Select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            width="auto"
            ml="2"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
          <Text ml="2" className="text-black">
            entries
          </Text>
        </Box>
      </Box>

      {/* mobile  */}
      <Box
  display={{ base: "flex", md: "none" }}
  alignItems={{ base: "center", md: "center" }}
  justifyContent="center"
  flexDirection={{ base: "row", md: "row" }}
  w="100%"
>
  {/* Search input and icons */}
  <Box
    w={{ base: "100px", md: "auto" }}
    borderRadius="5px"
    display="flex"
    alignItems="center"
    m={{ base: "10px", md: "10px" }}
    border="1px solid #c7c7c7"
    width="25%"
  >
    <CustomInput
      placeholder="Search"
      border="none"
      color="gray"
      _placeholder={{ color: "gray" }}
      _focus={{ boxShadow: "none" }}
      onChange={(e) => search(e)}
      p={{ base: "10px", md: "20px" }}
      outline="none"
      mt="-10px"
      containerStyle={{ paddingTop: "5px", width: "90%" }}
      value={searchValue}
    />
    {searchValue ? (
      <CloseIcon
        onClick={() => {
          setSearchValue("");
          betHistoryAPIHandler();
        }}
        mx="10px"
        ml={{ base: "20px" }}
        color="gray"
        cursor="pointer"
      />
    ) : (
      <SearchIcon
        mx="10px"
        ml={{ base: "20px" }}
        color="gray"
      />
    )}
  </Box>

  {/* Export icons */}
  <Box display="flex" width={{ base: "15%", md: "auto" }}>
    <img
      src={ExporttoPDF}
      alt="PDF Logo"
      onClick={generateReportPDF}
      style={{ cursor: "pointer" }}
    />
    <DownloadTableExcel
      filename="users table"
      sheet="users"
      currentTableRef={tableRef.current}
    >
      <img
        src={ExporttoExcel}
        alt="PPT Logo"
        style={{ cursor: "pointer" }}
      />
    </DownloadTableExcel>
  </Box>

  {/* Show entries dropdown */}
  <Box
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    flexDirection={{ base: "row", md: "row" }}
    w={{ base: "50%", md: "auto" }}
    mr="5"
  >
    <Text className="text-black">Show</Text>
    <Select
      value={rowsPerPage}
      onChange={(e) => setRowsPerPage(Number(e.target.value))}
      width="auto"
      ml="2"
    >
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </Select>
    <Text ml="2" className="text-black">
      entries
    </Text>
  </Box>
</Box>;

      <CustomTable
        headerBg="#363636"
        columns={columns}
        rows={data}
        showPagination={true}
        rowsPerPage_={pageSize}
        totalPages_={totalElements}
        currentPage_={pageNumber}
      />
    </Box>
  );
}
