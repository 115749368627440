import React, { useEffect } from 'react';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

import { Navigate, Outlet } from 'react-router-dom';
import Header from '../includes/header';
import SidebarContent from '../includes/sidebar';
import MobileNav from '../includes/mobileNav';
import { useSelector } from 'react-redux';
import { getAnnouncementAPI } from '../service/AuthService';
import MobileHeader from '../includes/header/MobileHeader';
import Footer from '../includes/footer';

export default function PublicOutlet(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useSelector((state) => state.account.userData);
  const [announcement, setAnnouncement] = React.useState({});
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const getAnnouncement = async () => {
    const res = await getAnnouncementAPI();
    if (res.code === 200) {
      setAnnouncement(res.response);
    }
  };
  useEffect(() => {
    getAnnouncement();
  }, []);
  return (
    <>
      <Box display={{ base: 'block', md: 'none' }}>
        <MobileHeader />
      </Box>
      <Box 
      display={{ base: 'none', md: 'block' }}
      >
        <Header setIsSidebarOpen={setIsSidebarOpen} />
      </Box>
      {props.children}
      <Footer />
    </>
  );
}
