import {
  Box,
  Button,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { generateMFACode } from "./Service";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { Encrypt } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { resendOTP, verifyMFACode } from "../login/Service";

const SecurityAuth = () => {
  const toast = useToast();
  const [password, setPassword] = useState("");
  const [mfaDetails, setMfaDetails] = useState(null);
  const userData_ = useSelector((state) => state.account.userData);
  const userData = useMemo(() => userData_, [userData_]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisabledMFA, setIsDisabledMFA] = useState(false);

  const [otpValue, setOtpValue] = useState("");

  const handleChange = (value) => {
    setOtpValue(value);
  };
  const handleComplete = (value) => {
    verifyMFACodeHandler(value);
  };
  const verifyMFACodeHandler = async (otp) => {
    const res = await verifyMFACode(
      otp,
      userData?.username,
      "&isDisableMfa=true"
    );

    if (res?.data?.code === 200) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      toast({
        title: "Error",
        description: "Invalid code",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setOtpValue("");
    }
  };

  const generateMFACodeHandler = async () => {
    if (!password) {
      return toast({
        title: "Password.",
        description: "Please enter your password.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    const payload = {
      password: Encrypt(password),
    };
    const res = await generateMFACode(payload);

    if (res?.data?.code === 200) {
      setMfaDetails(res.data.response);
    } else {
      toast({
        title: "Password",
        description: res.data.response || "Something went wrong!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const resendOTPHandler = async () => {
    const res = await resendOTP(userData?.username);

    if (res.data.code === 200) {
      setIsDisabledMFA(true);
      toast({
        title: "OTP",
        description: "Sucessfully Code Generate.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: res.data.status,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      localStorage.clear();
      window.location.href = "/";
    }
  };

  return (
    <Box
      bg={{ base: "transparent", md: "#fff" }}
      textColor="#A6A6A6"
      className="setting-page-main"
    >
      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        background="#27A6C3"
        padding={2}
      >
        <Text fontSize="md" color="#fff !important">
          Secure Auth Verification
        </Text>
      </Box>
      <Box className="secure-auth-body">
        <Box>
          <h5>
            Secure Auth Verification Status:{" "}
            {userData.mfaEnabled ? (
              <Tooltip
                label="click here to disable secure auth verification"
                aria-label="A tooltip"
              >
                <span
                  onClick={() => resendOTPHandler()}
                  className="status enabled"
                >
                  ENABLED
                </span>
              </Tooltip>
            ) : (
              <span className="status disabled">DISABLED</span>
            )}
          </h5>
          {!userData.mfaEnabled && (
            <>
              <p>
                Please select below option to enable secure auth verification
              </p>
              <Tabs>
                <TabList>
                  <Tab>Enable Using Mobile App</Tab>
                  <Tab>Enable Using Telegram</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <p>coming soon</p>
                  </TabPanel>
                  <TabPanel>
                    <p>Please enter your login password to continue</p>
                    <Box className="credential-details">
                      <Input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your login password"
                      />
                      <Button
                        disabled
                        className="connect-btn blue-btn"
                        colorScheme="blue"
                        onClick={generateMFACodeHandler}
                      >
                        Get connection ID
                      </Button>
                    </Box>
                    {mfaDetails && (
                      <div class="mt-3 small-box">
                        <h4>
                          Please follow below instructions for the telegram
                          2-Step veriication:
                        </h4>
                        <p>
                          Find{" "}
                          <a
                            target="_blank"
                            href={`https://t.me/exchangeauthbot?start`}
                            class="text-primary"
                            rel="noreferrer"
                          >
                            @exchangeauthbot
                          </a>{" "}
                          in your telegram and type <kbd>/start</kbd> command.
                          Bot will respond you.
                        </p>
                        <p class="text-dark">
                          After this type{" "}
                          <kbd>/connect {mfaDetails?.mfaCode}</kbd> and send it
                          to BOT.
                        </p>
                        <p>
                          Now your telegram account will be linked with your
                          website account and 2-Step veriication will be
                          enabled.
                        </p>
                        <hr />
                        <div class="font-hindi mt-4">
                          <h4>
                            कृपया टेलीग्राम 2-Step verification के लिए नीचे दिए
                            गए निर्देशों का पालन करें:
                          </h4>
                          <p>
                            अपने टेलीग्राम में{" "}
                            <a
                              target="_blank"
                              href="https://t.me/exchangeauthbot?start"
                              class="text-primary"
                            >
                              @exchangeauthbot
                            </a>{" "}
                            खोजें और कमांड <kbd>/start</kbd> टाइप करें. BOT आपको
                            जवाब देगा.
                          </p>
                          <p class="text-dark">
                            इसके बाद <kbd>/connect {mfaDetails?.mfaCode}</kbd>{" "}
                            टाइप करें और इसे BOT पर भेजें.
                          </p>
                          <p>
                            अब आपका टेलीग्राम account आपके वेबसाइट account से
                            जुड़ जाएगा और 2-Step veriication चालू हो जाएगा.
                          </p>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}

          {isDisabledMFA && (
            <Box p={8} className="login-auth-2-box">
              <Text
                display="flex"
                as="h4"
                align="center"
                mb="3"
                color="#0088CC"
                fontSize="1.5rem"
                justifyContent="center"
                alignItems="center"
                className="title-text"
              >
                Security Code Verification Using Telegram App{" "}
              </Text>
              <Text className="sub-title">
                Enter 6-digit code from your telegram bot{" "}
                <Link onClick={() => resendOTPHandler()}>Resend Code</Link>
              </Text>
              <Box className="otp-box">
                <HStack>
                  <PinInput
                    otp
                    onChange={handleChange}
                    onComplete={handleComplete}
                    value={otpValue}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SecurityAuth;
