import React, { useCallback, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Card,
  CardHeader,
  CardBody,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  Img,
} from '@chakra-ui/react';
import { BiFootball } from 'react-icons/bi';
import { MdSportsCricket, MdSportsTennis } from 'react-icons/md';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllCricketMatchListAPI } from '../../service/cricket';
import {
  getAllSoccerMatchListAPI,
  getAllTennisMatchListAPI,
} from '../../service/soccer&tennis';
import { FaHorse } from 'react-icons/fa';
import { bgColor, buttonColors } from '../../utils/constants';
import { HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import { searchAPI, upcomingFixtureAPI } from '../../service/AuthService';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
const AllSportsLinkItems = [
  {
    name: 'cricket',
    icon: 'cricket',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/cricket.png'),
    nonStatic: true,
  },
  {
    name: 'football',
    icon: 'football',
    isMap: true,
    iconIsPng: require('../../assets/img/icons/football.png'),
    nonStatic: true,
  },
  {
    name: 'tennis',
    icon: 'tennis',
    isMap: true,
    iconIsPng: require('../../assets/img/icons/tennis.png'),
    nonStatic: true,
  },
  {
    name: 'tableTennis',
    icon: 'tableTennis',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/table-tennis.png'),
    noOfGames: 2,
  },
  {
    name: 'basketball',
    icon: 'basketball',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/basketball.png'),
    noOfGames: 6,
  },
  {
    name: 'volleyball',
    icon: 'volleyball',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/volleyball.png'),
    noOfGames: 2,
  },
  {
    name: 'snooker',
    icon: 'snooker',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/snooker.png'),
    noOfGames: 7,
  },
  {
    name: 'handball',
    icon: 'handball',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/handball.png'),
    noOfGames: 8,
  },
  {
    name: 'iceHockey',
    icon: 'iceHockey',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/ice-hockey.png'),
    noOfGames: 3,
  },
  {
    name: 'eGames',
    icon: 'eGames',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/e-games.png'),
    noOfGames: 6,
  },
  {
    name: 'futsal',
    icon: 'futsal',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/kabaddi.png'),
    noOfGames: 5,
  },
  {
    name: 'kabaddi',
    icon: 'kabaddi',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/kabaddi.png'),
    noOfGames: 8,
  },
  {
    name: 'golf',
    icon: 'golf',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/kabaddi.png'),
    noOfGames: 10,
  },
  {
    name: 'rugbyLeague',
    icon: 'rugbyLeague',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/kabaddi.png'),
    noOfGames: 2,
  },
  {
    name: 'boxing',
    icon: 'boxing',
    isMap: false,
    iconIsPng: require('../../assets/img/icons/kabaddi.png'),
    noOfGames: 7,
  },
];
const RacingLinkItems = [
  {
    name: 'Horse racing',
    icon: FaHorse,
    isMap: require('../../assets/img/icons/kabaddi.png'),
  },
];

const SidebarContent = ({ onClose, ...rest }) => {
  const naviagte = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchResultRef = useRef(null);

  const [currentFixture, setCurrentFixture] = useState({});
  const token = useSelector((state) => state.account.userData.token);
  const gameData = useSelector((state) => state.gameData.sideBarData);
  const [searchResults, setSearchResults] = useState({
    cricket: [],
    soccer: [],
    tennis: [],
  });
  const [isSearchPopOverOpen, setIsSearchPopOverOpen] = useState(false);
  const setSideBarData = useCallback(
    (data) => {
      dispatch({
        type: 'gameData/setSideBarData',
        payload: data,
      });
    },
    [dispatch]
  );

  const getCricketData = useCallback(async () => {
    const res = await getAllCricketMatchListAPI();

    if (res.status === 200) {
      setSideBarData({ cricket: res.response });
    }
  }, [setSideBarData]);

  const getSoccerData = useCallback(async () => {
    const res = await getAllSoccerMatchListAPI();
    console.log(res, 'res');
    if (res && res.code === 200) {
      setSideBarData({
        soccer: res.response,
      });
    }
  }, []);

  const getTennisData = useCallback(async () => {
    const res = await getAllTennisMatchListAPI();
    if (res.code === 200) {
      setSideBarData({
        tennis: res.response,
      });
    }
  }, []);
  const getUpcomingFixture = useCallback(async () => {
    const res = await upcomingFixtureAPI();
    if (res.code === 200) {
      setSideBarData({
        upcomingFixture: res.response,
      });
    }
  }, [setSideBarData]);

  useEffect(() => {
    Promise.all([getCricketData(), getUpcomingFixture()]);

    document.addEventListener('click', (e) => {
      if (
        searchResultRef?.current &&
        !searchResultRef?.current?.contains(e.target)
      ) {
        setIsSearchPopOverOpen(false);
      }
    });
  }, [getCricketData, getUpcomingFixture]);

  const setRandomCurrentFixture = useCallback(() => {
    const randomIndex = Math.floor(
      Math.random() * gameData?.upcomingFixture.length
    );
    setCurrentFixture(gameData?.upcomingFixture[randomIndex]);
  }, [gameData?.upcomingFixture]);
  useEffect(() => {
    const interval = setInterval(() => {
      setRandomCurrentFixture();
    }, 10000);
    return () => clearInterval(interval);
  }, [gameData.upcomingFixture]);

  const search = async (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      const res = await searchAPI(value);
      if (res.code === 200) {
        setSearchResults(res.response);
        setIsSearchPopOverOpen(true);
      }
    }
  };
  const navigateToGame = (id, gameEvent) => {
    naviagte(`/game/${id}/${gameEvent[id + 'Id']}`, {
      state: {
        gameEvent: {
          ...gameEvent,
          eventTime: gameEvent.eventDate
            ? gameEvent.eventDate
            : gameEvent.eventTime
            ? gameEvent.eventTime
            : gameEvent.startDate,
        },
      },
    });
  };
  return (
    <Box
      transition='3s ease'
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      className='sidebar'
      backgroundColor={{
        base: '#eee',
        md: '#494949',
      }}
      {...rest}
      overflowY='auto'
      zIndex={1000}
      display={location?.pathname?.startsWith('/report') ? 'none' : 'block'}
    >
      <Flex
        display={{ base: 'none', md: 'flex' }}
        h='20'
        alignItems='center'
        mx='8'
        justifyContent='space-between'
      >
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {token && (
        <Flex
          mt='2'
          borderRadius='25px'
          alignItems='center'
          justifyContent='center'
          bgColor={bgColor}
          className='side-input-flex'
          p='2'
        >
          <Input
            outline='none !important'
            placeholder='Search'
            w='80%'
            bg='transparent'
            border='none'
            size='sm'
            onChange={search}
          />
          <SearchIcon color='gray' />
        </Flex>
      )}

      {(searchResults?.cricket?.length > 0 ||
        searchResults?.soccer?.length > 0 ||
        searchResults?.tennis?.length > 0) &&
        isSearchPopOverOpen && (
          <div
            ref={searchResultRef}
            style={{
              zIndex: 'auto',
            }}
            className='search-list d-none-mobile'
          >
            <div className='search-title'>Search Result</div>{' '}
            <div className='search-content'>
              <div className='search-event-title'>Cricket</div>{' '}
              {searchResults?.cricket?.map((item) => (
                <a href={`/game/cricket/${item.id}`} key={item.id}>
                  <div className='search-game'>
                    <div className='search-game-name'>{item.eventName}</div>{' '}
                    <div className='search-game-time'>
                      {moment(item.eventDate).format('DD/MM/YYYY hh:mm:ss')}
                    </div>
                  </div>
                </a>
              ))}
              <div className='search-event-title'>Soccer</div>{' '}
              {searchResults?.soccer?.map((item) => (
                <a href={`/game/soccer/${item.id}`} key={item.id}>
                  <div className='search-game'>
                    <div className='search-game-name'>{item.eventName}</div>{' '}
                    <div className='search-game-time'>
                      {moment(item.eventDate).format('DD/MM/YYYY hh:mm:ss')}
                    </div>
                  </div>
                </a>
              ))}
              <div className='search-event-title'>Tennis</div>{' '}
              {searchResults?.tennis?.map((item) => (
                <a href={`/game/tennis/${item.id}`} key={item.id}>
                  <div className='search-game'>
                    <div className='search-game-name'>{item.eventName}</div>{' '}
                    <div className='search-game-time'>
                      {moment(item.eventDate).format('DD/MM/YYYY hh:mm:ss')}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      {!token && currentFixture?.eventName && (
        <Card mt='2' className='card'>
          <CardHeader>
            <h5 className='card_title'>Upcoming Fixtures</h5>
          </CardHeader>
          <CardBody>
            <div className='card_content'>
              <div className='sport_title_box'>
                <p>{currentFixture?.eventName}</p>
              </div>
              <p>
                <small>
                  {moment(currentFixture?.eventDate).format(
                    'DD/MM/YYYY hh:mm:ss'
                  )}
                </small>
              </p>
            </div>
          </CardBody>
        </Card>
      )}
      {token && (
        <Flex
          display={{ base: 'flex', md: 'none' }}
          alignItems='center'
          justifyContent='center'
        >
          <Flex
            borderRadius='6px'
            alignItems='center'
            justifyContent='center'
            bgColor={'#494949'}
            p='1'
            mt='5'
          >
            <Input
              outline='none !important'
              placeholder='Search'
              w='80%'
              bg='transparent'
              border='none'
              size='sm'
              onChange={search}
            />
            <SearchIcon color='gray' />
          </Flex>
          <HamburgerIcon onClick={onClose} mt='5' mx='2' fontSize='xl' />
        </Flex>
      )}

      {!token && (
        <Card mt='5' className='card'>
          <CardBody>
            <div className='card_content'>
              <h5
                style={{
                  color: buttonColors,
                }}
                className='sports_title'
              >
                Racing Sport
              </h5>

              <Accordion p='0' allowToggle>
                {RacingLinkItems.map((link, i) => (
                  <AccordionItem p='0' mt='1' border='none' key={i}>
                    <AccordionButton p='0'>
                      <div
                        key={i + 'k'}
                        onClick={() => naviagte('/')}
                        className='sport_title_box'
                      >
                        <i className='icon horse_racing'></i>{' '}
                        <p>
                          {link?.name?.charAt(0)?.toUpperCase() +
                            link?.name?.slice(1)}
                        </p>
                      </div>
                    </AccordionButton>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </CardBody>
        </Card>
      )}
      {!token && (
        <Card
          mt='10px'
          borderRadius={10}
          py='2'
          overflowY='auto'
          className='card'
        >
          <CardBody>
            <div className='card_content'>
              <h5
                style={{
                  color: buttonColors,
                }}
                className='sports_title'
              >
                All Sports
              </h5>
              <Accordion p='0' allowToggle>
                {gameData &&
                  AllSportsLinkItems?.map((link, i) => (
                    <AccordionItem p='0' mt='1' border='none' key={i}>
                      <AccordionButton cursor='pointer' p='0'>
                        <div key={i + 'k'} className='sport_title_box'>
                          {link?.iconIsPng ? (
                            <img
                              src={link?.iconIsPng}
                              alt='icon'
                              style={{ width: '20px', marginRight: '5px' }}
                            />
                          ) : (
                            <i className={`icon ${link?.icon}`}></i>
                          )}
                          {gameData[link.name] && (
                            <p>
                              {link?.name?.charAt(0)?.toUpperCase() +
                                link?.name?.slice(1)}{' '}
                              {link.nonStatic &&
                                (gameData[link.name]?.length > 0 ||
                                  Object.keys(gameData[link.name])?.length >
                                    0) &&
                                `(${
                                  !link.isMap
                                    ? gameData[link.name]?.length
                                    : Object.keys(gameData[link.name])?.length
                                })`}
                              {!link.nonStatic &&
                                `(${link.noOfGames ? link.noOfGames : 0})`}
                            </p>
                          )}
                        </div>
                        <link.icon />
                      </AccordionButton>

                      <AccordionPanel p='0' border='none'>
                        <Accordion p='0' bg='#3C444B' allowToggle border='none'>
                          {gameData[link.name] && link?.isMap
                            ? Object.keys(gameData[link.name])?.map(
                                (gameName, i) => (
                                  <AccordionItem mt='1' border='none' key={i}>
                                    <AccordionButton cursor='pointer' p='0.5'>
                                      <Text
                                        flex='1'
                                        textColor='#757C82'
                                        fontSize='sm'
                                        textAlign='left'
                                        fontWeight='bold'
                                        overflow='hidden'
                                        whiteSpace='nowrap'
                                        textOverflow='ellipsis'
                                      >
                                        {gameName}
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel
                                      bg='#2E3439'
                                      p='1'
                                      mt='1'
                                      border='none'
                                    >
                                      {link?.isMap &&
                                        gameData[link?.name][gameName]?.map(
                                          (match, i) => (
                                            <Box key={i}>
                                              <Text
                                                cursor='pointer'
                                                flex='1'
                                                textColor='#757C82'
                                                textAlign='left'
                                                fontWeight='bold'
                                                mt='1'
                                                fontSize='xs'
                                                overflow='hidden'
                                                whiteSpace='nowrap'
                                                textOverflow='ellipsis'
                                                onClick={() =>
                                                  navigateToGame(
                                                    link?.name,
                                                    match
                                                  )
                                                }
                                              >
                                                {match?.eventName}
                                              </Text>
                                            </Box>
                                          )
                                        )}
                                    </AccordionPanel>
                                  </AccordionItem>
                                )
                              )
                            : gameData[link?.name]?.map((event, i) => (
                                <AccordionItem p='1' border='none' key={i}>
                                  <Text
                                    flex='1'
                                    cursor='pointer'
                                    textColor='#757C82'
                                    textAlign='left'
                                    fontWeight='bold'
                                    mt='1'
                                    fontSize='xs'
                                    overflow='hidden'
                                    whiteSpace='nowrap'
                                    textOverflow='ellipsis'
                                    onClick={() =>
                                      navigateToGame(link?.name, event)
                                    }
                                  >
                                    {event?.eventName}
                                  </Text>
                                </AccordionItem>
                              ))}
                        </Accordion>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            </div>
          </CardBody>
        </Card>
      )}
      {token && (
        <Card
          mt='10px'
          borderRadius={10}
          py='2'
          overflowY='auto'
          className='card'
        >
          <CardBody>
            <div className='card_content'>
              <h5
                style={{
                  color: buttonColors,
                }}
                className='sports_title'
              >
                Racing Sport
              </h5>

              <Accordion p='0' allowToggle>
                {RacingLinkItems.map((link, i) => (
                  <AccordionItem p='0' mt='1' border='none' key={i}>
                    <AccordionButton
                      _expanded={{
                        bg: buttonColors,
                        p: '10',
                      }}
                      p='0'
                    >
                      <div
                        onClick={() => naviagte('/')}
                        className='sport_title_box'
                      >
                        <i className='icon horse_racing'></i>{' '}
                        <p>
                          {link?.name?.charAt(0)?.toUpperCase() +
                            link?.name?.slice(1)}
                        </p>
                      </div>
                    </AccordionButton>
                  </AccordionItem>
                ))}
              </Accordion>
              <h5
                style={{
                  color: buttonColors,
                }}
                className='sports_title'
              >
                All Sports
              </h5>
              <Accordion p='0' allowToggle>
                {gameData &&
                  AllSportsLinkItems?.map((link, i) => (
                    <AccordionItem p='0' mt='1' border='none' key={i}>
                      <AccordionButton
                        _expanded={{
                          bg: buttonColors,
                          p: '2',
                          color: 'black',
                          borderRadius: '20px',
                          mb: '-5px',
                        }}
                        cursor='pointer'
                        p='0'
                        className='inner-accordion'
                      >
                        <div className='sport_title_box'>
                          {link?.iconIsPng ? (
                            <img
                              src={link?.iconIsPng}
                              alt='icon'
                              style={{ width: '20px', marginRight: '5px' }}
                            />
                          ) : (
                            <i className={`icon ${link?.icon}`}></i>
                          )}
                          {gameData[link.name] && (
                            <p className='text-sport'>
                              {link?.name?.charAt(0)?.toUpperCase() +
                                link?.name?.slice(1)}{' '}
                              {link.nonStatic &&
                                (gameData[link.name]?.length > 0 ||
                                  Object.keys(gameData[link.name])?.length >
                                    0) &&
                                `(${
                                  !link.isMap
                                    ? gameData[link.name]?.length
                                    : Object.keys(gameData[link.name])?.length
                                })`}
                              {!link.nonStatic &&
                                `(${link.noOfGames ? link.noOfGames : 0})`}
                            </p>
                          )}
                        </div>
                        <link.icon />
                      </AccordionButton>

                      <AccordionPanel p='0' border='none'>
                        <Accordion p='0' allowToggle border='none'>
                          {gameData[link.name] && link?.isMap
                            ? Object.keys(gameData[link.name])?.map(
                                (gameName, i) => (
                                  <AccordionItem border='none' key={i}>
                                    <AccordionButton
                                      _expanded={{
                                        bg: buttonColors,
                                        color: 'black',
                                      }}
                                      cursor='pointer'
                                      p='0.5'
                                    >
                                      <Text
                                        flex='1'
                                        fontSize='sm'
                                        textAlign='left'
                                        overflow='hidden'
                                        whiteSpace='nowrap'
                                        textOverflow='ellipsis'
                                      >
                                        {gameName}
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel
                                      bg='#2E3439'
                                      p='1'
                                      border='none'
                                    >
                                      {link?.isMap &&
                                        gameData[link?.name][gameName]?.map(
                                          (match, i) => (
                                            <Box key={i}>
                                              <Text
                                                cursor='pointer'
                                                flex='1'
                                                textAlign='left'
                                                mt='1'
                                                fontSize='xs'
                                                onClick={() =>
                                                  navigateToGame(
                                                    link?.name,
                                                    match
                                                  )
                                                }
                                              >
                                                {match?.eventName}
                                              </Text>
                                            </Box>
                                          )
                                        )}
                                    </AccordionPanel>
                                  </AccordionItem>
                                )
                              )
                            : gameData[link?.name]?.map((event, i) => (
                                <AccordionItem p='1' border='none' key={i}>
                                  <Text
                                    flex='1'
                                    cursor='pointer'
                                    textAlign='left'
                                    mt='1'
                                    fontSize='xs'
                                    overflow='hidden'
                                    whiteSpace='nowrap'
                                    textOverflow='ellipsis'
                                    onClick={() =>
                                      navigateToGame(link?.name, event)
                                    }
                                  >
                                    {event?.eventName}
                                  </Text>
                                </AccordionItem>
                              ))}
                        </Accordion>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            </div>
          </CardBody>
        </Card>
      )}
    </Box>
  );
};

export default SidebarContent;
