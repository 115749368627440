import { Box, FormLabel, Input, InputRightElement, InputGroup, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function CustomInput({ label, containerStyle, type = "text", ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box className={rest.className} style={containerStyle}>
      {label && (
        <FormLabel color="#000" fontSize="sm">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Input
          borderRadius="5px"
          borderColor="#ccc"
          size="sm"
          type={showPassword ? "text" : type}
          {...rest}
        />
        {type === "password" && (
          <InputRightElement>
            <IconButton
            style={{backgroundColor:"transparent", color:"darkgray"}}
              aria-label={showPassword ? "Hide password" : "Show password"}
              icon={showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              variant="ghost"
              onClick={togglePasswordVisibility}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
}

