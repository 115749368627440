import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import PlaceBet from "../../game/PlaceBet";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";

export default function Poker1Day() {
  const odds_ = useSelector((state) => state.gameData.casinoOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const dispatch = useDispatch();
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = "POKER_1_DAY";
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  useEffect(() => {
    dispatch(setGameKey("poker"));
  }, [dispatch]);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box mb="2" pos="relative" className="casino-video">
          {odds.cards && (
            <Box top="8" p="2" pos="absolute" display="flex" bg="black">
              <img
                style={{
                  marginRight: "5px",
                }}
                className="cardimg"
                src={require(`../../../assets/img/casino/${odds?.cards[0][0]}.png`)}
              />
              <img
                className="cardimg"
                src={require(`../../../assets/img/casino/${odds?.cards[0][1]}.png`)}
              />
            </Box>
          )}
          <div class="market-title">Poker 1-Day</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3051`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}1 day poker-rules (1).jpeg`} />
        </Box>
        <div className="casino-detail XXsnipcss_extracted_selector_selectionXX snipcss0-0-0-1 tether-target-attached-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
          <div className="playerabox snipcss0-1-1-2">
            <div className="casino-box-row playerafabcy snipcss0-2-2-3">
              <div className="casino-nation-name snipcss0-3-3-4">
                <b className="snipcss0-4-4-5">Player A</b>
              </div>{" "}
              <div className="casino-bl-box snipcss0-3-3-6">
                <div className="back casino-bl-box-item">
                  <span className="casino-box-odd snipcss0-5-7-8">1.3</span>
                </div>{" "}
                <div className="lay casino-bl-box-item">
                  <span className="casino-box-odd snipcss0-5-9-10">1.33</span>
                </div>
              </div>{" "}
              <div className="casino-nation-name text-center w-100 snipcss0-3-3-11">
                <span className="d-none snipcss0-4-11-12" />
              </div>
            </div>{" "}
            <div className="casino-box poker1dayother mt-2 snipcss0-2-2-13">
              <div className="casino-bl-box snipcss0-3-13-14">
                <div className="casino-bl-box-item back suspended snipcss0-4-14-15">
                  <span className="casino-box-odd snipcss0-5-15-16">
                    2 Cards Bonus
                  </span>{" "}
                  <span className="d-none snipcss0-5-15-17">0</span>
                </div>{" "}
                <div className="casino-bl-box-item back suspended snipcss0-4-14-18">
                  <span className="casino-box-odd snipcss0-5-18-19">
                    7 Cards Bonus
                  </span>{" "}
                  <span className="d-none snipcss0-5-18-20">0</span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="playerabcardbox snipcss0-1-1-21">
            <div className="poker-icon snipcss0-2-21-22">
              <img
                src={require("../../../assets/img/poker.png")}
                className="snipcss0-3-22-23"
              />
            </div>{" "}
            <div className="row row5 w-100 snipcss0-2-21-24">
              <div className=" col-md-6 snipcss0-3-24-25">
                <div
                  style={{ textAlign: "center" }}
                  className="dealer-name playera snipcss0-4-25-26"
                >
                  Player A
                </div>{" "}
                <div
                  className="mt-1 snipcss0-4-25-27"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span className="snipcss0-5-27-28">
                    <img
                      src={ASSETS_URL + "9HH.png"}
                      className="snipcss0-6-28-29"
                    />
                  </span>{" "}
                  <span className="snipcss0-5-27-30">
                    <img
                      src={ASSETS_URL + "10CC.png"}
                      className="snipcss0-6-30-31"
                      style={{ marginRight: 0 }}
                    />
                  </span>
                </div>
              </div>{" "}
              <div className=" col-md-6 text-right snipcss0-3-24-32">
                <div
                  style={{ textAlign: "center" }}
                  className="dealer-name playerb snipcss0-4-32-33"
                >
                  Player B
                </div>{" "}
                <div
                  className="mt-1 snipcss0-4-32-34"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span className="snipcss0-5-34-35">
                    <img
                      src={ASSETS_URL + "8SS.png"}
                      className="snipcss0-6-35-36"
                    />
                  </span>{" "}
                  <span className="snipcss0-5-34-37">
                    <img
                      src={ASSETS_URL + "ACC.png"}
                      className="snipcss0-6-37-38"
                      style={{ marginRight: 0 }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="playerbbox snipcss0-1-1-39">
            <div className="casino-box-row playerbfabcy snipcss0-2-39-40">
              <div className="casino-nation-name snipcss0-3-40-41">
                <b className="snipcss0-4-41-42">Player B</b>
              </div>{" "}
              <div className="casino-bl-box snipcss0-3-40-43">
                <div className="back casino-bl-box-item suspended snipcss0-4-43-44">
                  <span className="casino-box-odd snipcss0-5-44-45">0</span>
                </div>{" "}
                <div className="lay casino-bl-box-item suspended snipcss0-4-43-46">
                  <span className="casino-box-odd snipcss0-5-46-47">0</span>
                </div>
              </div>{" "}
              <div className="casino-nation-name text-center w-100 snipcss0-3-40-48">
                <span className="d-none snipcss0-4-48-49" />
              </div>
            </div>{" "}
            <div className="casino-box poker1dayother mt-2 snipcss0-2-39-50">
              <div className="casino-bl-box snipcss0-3-50-51">
                <div className="casino-bl-box-item back suspended snipcss0-4-51-52">
                  <span className="casino-box-odd snipcss0-5-52-53">
                    2 Cards Bonus
                  </span>{" "}
                  <span className="d-none snipcss0-5-52-54">0</span>
                </div>{" "}
                <div className="casino-bl-box-item back suspended snipcss0-4-51-55">
                  <span className="casino-box-odd snipcss0-5-55-56">
                    7 Cards Bonus
                  </span>{" "}
                  <span className="d-none snipcss0-5-55-57">0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LastResults eventId={"POKER_1_DAY"} />
      </Box>

      <PlaceBet game="casino" eventId="POKER_1_DAY" />
    </Box>
  );
}
