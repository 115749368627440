import { Box, Grid, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './games/css/style.scss';
import './games/css/app.scss';
import { ASSETS_URL, createSocket } from '../../utils/constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export default function PokerList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [casinoList, setCasinoList] = React.useState([
    {
      name: 'Poker 20-20',
      image: 'poker20 20.jpg',
      link: '/live-casino/poker/20-20',
      group: 'poker',
    },
    {
      name: 'Poker 6 Players',
      image: 'poker6.jpg',
      link: '/live-casino/poker/poker-6-player',
      group: 'poker',
    },
  ]);

  useEffect(() => {
    if (searchParams.get('name')) {
      const tempList = [...casinoList];
      setCasinoList(
        tempList.filter((j) => j.group === searchParams.get('name'))
      );
    }
  }, []);

  return (
    <Grid
      mt={{ base: '1px !important', md: '0px' }}
      gap={2}
      templateColumns='repeat(3,1fr)'
      className='casino-live-page'
    >
      {casinoList.map((casino, index) => (
        <GridItem
          onClick={() => {
            window.location.href = casino.link;
          }}
          cursor='pointer'
          position='relative'
        >
          <Image
            h='200'
            width={'100%'}
            src={ASSETS_URL + casino.image}
            className='casino-game-img'
          />
        </GridItem>
      ))}
    </Grid>
  );
}
