import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Td,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import CustomModal from "../../components/CustomModal";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/Table";
import {
  getCasinoReportHistory,
  getIndividualResultAPI,
} from "../../service/casino";
import { getAccountStatementAPI } from "../../service/UserService";
import {
  bgColor,
  buttonColors,
  exportToCsvReactCsv,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import moment from "moment";
import CasinoResultModal from "../../components/CasinoResultModal/CasinoResultModal";

export default function CasinoResult() {
  const toast = useToast();
  const [from, setFrom] = React.useState(moment().format("YYYY-MM-DD"));
  const [sport, setSport] = React.useState("none");
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedMarket, setSelectedMarket] = React.useState({});
  const [marketData, setMarketData] = React.useState({});
  const [searchValue, setSearchValue] = useState("");
  const casinos = [
    {
      label: "Select Type",
      value: "none",
    },
    {
      label: "Dragon Tiger-1 2020",
      image: "dt20.jpg",
      value: "DRAGON_TIGER_20",
    },
    {
      label: "Dragon Tiger 2020 2",
      image: "dt202.jpg",
      value: "DRAGON_TIGER_20_2",
    },
    {
      label: "Dragon Tiger 1 Day",
      image: "dt6.jpg",
      value: "DRAGON_TIGER_6",
    },
    {
      label: "Dragon Tiger Lion",
      image: "dtl20.jpg",
      value: "DRAGON_TIGER_LION_20",
    },
    {
      label: "Teen Patti 2020",
      image: "teen20.jpg",
      value: "TEEN_PATTI_20",
    },

    {
      label: "Teen Patti Test",
      image: "teen9.jpg",
      value: "TEEN_9",
    },
    {
      label: "Teen Patti Open",
      image: "teen8.jpg",
      value: "TEEN_8",
    },
    {
      label: "Casino Queen",
      image: "queen.jpg",
      value: "CASINO_QUEEN",
    },
    {
      label: "32 Cards A",
      image: "card32.jpg",
      value: "CARD_32",
    },
    {
      label: "32 Cards B",
      image: "card32eu.jpg",
      value: "CARD32EU",
    },

    {
      label: "Poker 20-20",
      image: "poker20 20.jpg",
      value: "POKER_20",
    },
    {
      label: "Lucky 7-A",
      image: "lucky7.jpg",
      value: "LUCKY7",
    },
    {
      label: "Lucky 7-B",
      image: "lucky7eu.jpg",
      value: "LUCKY7EU",
    },
    {
      label: "Andar Bahar",
      image: "andar-bahar.jpg",
      value: "AB_20",
    },
    {
      label: "Andar Bahar 2",
      image: "andar-bahar2.jpg",
      value: "ABJ",
    },

    {
      label: "Bollywood Casino",
      image: "btable.jpg",
      value: "BOLLYWOOD_TABLE",
    },
    {
      label: "Amar Akbar Anthony",
      image: "aaa.jpg",
      value: "AAA",
    },
    {
      label: "Instant Worli",
      image: "instant worli.jpg",
      value: "WORLI2",
    },

    {
      label: "Casino War",
      image: "casino-war.jpg",
      value: "CASINO_WAR",
    },
    {
      label: "Race 20",
      image: "race-20.png",
      value: "RACE20",
    },
    {
      label: "5-5 Cricket",
      image: "five cricket.jpg",
      value: "/live-casino/cricketV3",
    },
    {
      label: "Cricket 20-20",
      image: "cmatch20.jpg",
      value: "/live-casino/cmatch20",
    },
    {
      label: "Baccart",
      image: "baccarat.jpg",
      value: "BACCARAT",
    },
    {
      label: "Baccart 2",
      image: "baccarat2.jpg",
      link: "BACCARAT2",
    },
    {
      label: "Worli Matka",
      image: "worli.jpg",
      link: "WORLI2",
    },
    {
      label: "Super Over",
      image: "superover.png",
      link: "SUPEROVER",
    },
    {
      label: "1-Day Poker",
      image: "poker 1 day.jpg",
      link: "POKER_1_DAY",
    },
    {
      label: "1 Day Teen Patti",
      image: "teen.jpg",
      link: "TEEN",
    },
    {
      label: "Poker 6 Players",
      image: "poker6.jpg",
      link: "POKER_9",
    },
    {
      label: "3 Cards Judgement",
      image: "3 card judgement.jpg",
      link: "THREE_CARD",
    },
    {
      label: "Casino Meter",
      image: "casino meter.jpg",
      link: "CASINO_WAR",
    },
  ];
  const getCasinoResult = async () => {
    if (sport === "none" || !from) {
      return toast({
        title: "Warning",
        description: "Please select sport and date",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    const res = await getCasinoReportHistory({
      casinoGames: sport,
      date: new Date(from),
      page,
      size,
    });
    if (res.code === 200) {
      setData(res.response.content);
    }
  };
  const search = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") return getCasinoResult();

    setData(
      searchSubstringInArrayObjects(
        data,
        ["round_id", "winner"],
        e.target.value
      )
    );
  };
  const getIndividualResult = async (marketId) => {
    if (marketId) {
      const res = await getIndividualResultAPI(marketId);
      if (res.code === 200) {
        setMarketData(res.response);
      }
    }
  };
  const generateReport = () => {
    exportToCsvReactCsv(data, "casino_report");
  };
  useEffect(() => {
    getIndividualResult(selectedMarket.marketId);
  }, [selectedMarket]);
  return (
    <Box
      // p={{ base: 0, md: 2 }}
      bg={{ base: "transparent", md: "#fff" }}
      textColor="#A6A6A6"
      pt={{ base: "60px", md: "0" }}
      className="setting-page-main casino-result-main"
    >
      <Modal
        size={"5xl"}
        isOpen={isResultModalOpen}
        onClose={setIsResultModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <CasinoResultModal
            marketId={isResultModalOpen}
            onClose={setIsResultModalOpen}
          />
        </ModalContent>
      </Modal>
      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        background="#27A6C3"
        padding={2}
        marginTop="-5px"
      >
        <Text textColor="white" fontSize="sm" color="#fff !important">
          Casino Results
        </Text>
      </Box>

      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box
          flexDirection={{
            base: "column",
            md: "row",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="2"
          width={{
            base: "100%",
            md: "50%",
          }}
          className="input-box-head"
        >
          <CustomInput
            placeholder="Date"
            type="date"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            value={from}
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
            max={moment().format("YYYY-MM-DD")}
            className="col-select"
          />

          <CustomSelect
            borderRadius="5"
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            size="md"
            onChange={(e) => {
              setSport(e.target.value);
            }}
            borderColor="#AAA391"
            options={casinos}
            className="col-select"
          ></CustomSelect>
          <Button
            px="80px"
            py="20px"
            mx="2"
            size="sm"
            className="blue-btn"
            bg={buttonColors}
            onClick={() => {
              getCasinoResult();
            }}
            alignSelf="center"
            color="black"
            borderRadius={5}
            mt={1}
            w={{ base: "100%", md: "auto" }}
          >
            Submit
          </Button>
        </Box>
      </Box>

      <CustomTable
        headerBg="#363636"
        columns={[
          {
            name: "Round Id",
            id: "round_id",
            element: (row) => (
              <Td border="none">
                <Text
                  onClick={() => {
                    setSelectedMarket({
                      marketId: row?.marketId,
                    });
                    setIsResultModalOpen(row?.marketId);
                  }}
                  color="blue.400"
                  className="blue-color"
                  textDecoration="underline"
                  cursor="pointer"
                >
                  {row?.marketId}
                </Text>
              </Td>
            ),
          },
          {
            name: "Winner",
            id: "winner",
          },
        ]}
        rows={data}
        currentPage_={page}
        sizePerPage_={size}
        totalSize_={data.length}
        rowsPerPage_={20}
      />
    </Box>
  );
}
