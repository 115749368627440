import { Box, Button, Input, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table";
import { profitLossReportAPI } from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import moment from "moment";

export default function ProfitLossReport() {
  const [from, setFrom] = useState(moment().format("YYYY-MM-DDTHH:MM"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DDTHH:MM"));
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = useState([]);
  const getProfitLossReport = async () => {
    const res = await profitLossReportAPI({
      from: from,
      to: to,
    });
    if (res.code === 200) {
      setData(res.response);
    }
  };
  useEffect(() => {
    getProfitLossReport();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    getProfitLossReport();
  };

  const handleReset = (e) => {
    setFrom(moment().format("YYYY-MM-DDTHH:MM"));
    setTo(moment().format("YYYY-MM-DDTHH:MM"));
  };

  return (
    <Box
      className="col-md-12 report-main-content setting-page-main"
      bg={{ base: "transparent", md: "#fff" }}
      borderRadius={10}
    >
      <Box
        className="col-md-12 report-main-content setting-page-main"
        bg={{ base: "#fff", md: "#fff" }}
        borderRadius={10}
      >
        <Box
          bg="#4F4F4F"
          p="2"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          fontWeight="bold"
          color="white"
          background="#27A6C3"
          padding={2}
          marginTop="-5px"
        >
          <Text fontSize="sm" color="#fff !important">
            Profit Loss Report
          </Text>
        </Box>
        <Box mt="2" display="flex" mb="2" w="50%" className="input-box-head">
          <Input
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            mx="1"
            type="datetime-local"
            max={from}
          />
          <Input
            onChange={(e) => setTo(e.target.value)}
            value={to}
            mx="1"
            type="datetime-local"
            min={from}
            max={to}
          />
          {/* <button
          style={{
            backgroundColor: "#0079FB",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
          onClick={getProfitLossReport}
        >
          Submit
        </button> */}
          <Box display="flex">
            <Button
              className="gradiant-btn go-btn"
              size="sm"
              onClick={handleSubmit}
              mr={1}
            >
              Go
            </Button>

            <Button
              className="gradiant-btn reset-btn"
              size="sm"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Box>
        <CustomTable
          columns={[
            { name: "Sports", id: "eventType" },
            { name: "Market Name", id: "eventName" },
            { name: "Amount", id: "amount" },
          ]}
          rows={data}
          showPagination={false}
          totalPages_={totalPages}
          rowsPerPage_={rowsPerPage}
          currentPage_={pageNumber}
        />
        {data.length === 0 && (
          <Text className="no-data-found">No profit & loss found</Text>
        )}
      </Box>
    </Box>
  );
}
