import React, { useState, useEffect } from 'react';
import './banner.css';
import homeBanner from '../../assets/img/home_banner.jpg';

const BannerSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [banners, setBanners] = useState([]);

  // const getDashboardBanners = async () => {
  //   try {
  //     const res = await dashboardBannerAPI();
  //     if (res.status === 200) {
  //       const data = res.data.response;

  //       const signedUrlsPromises = data.map(async (banner) => {
  //         const signedUrlRes = await getSignedUrlAPI(banner.bannerUrl);
  //         if (signedUrlRes?.data?.code === 200) {
  //           return signedUrlRes.data.response;
  //         } else {
  //           return null;
  //         }
  //       });

  //       const signedUrls = await Promise.all(signedUrlsPromises);

  //       const updatedBanners = data.map((banner, index) => {
  //         banner.bannerUrl = signedUrls[index];
  //         return banner;
  //       });

  //       console.log("Updated banners:", updatedBanners);
  //       setBanners(updatedBanners);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching banners:", error);
  //   }
  // };

  // useEffect(() => {
  //   getDashboardBanners();
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [banners]);

  const nextBanner = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  const prevBanner = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + banners.length) % banners.length
    );
  };

  console.log(currentIndex, 'showing current index');

  return (
    <>
      <>
        {/* logic to show banner from API  */}
        <img
          // style={{ height: '450px', width: '100%' }}
          className='banner-img'
          src={homeBanner}
          alt='banner'
        />
        {/* <div className="banner-container">
      <div className="banner-slider">




        {banners.length > 0 &&
          banners.map((banner, index) => (
            <div
              className={`banner ${index === currentIndex ? "active" : ""}`}
              key={index}
            >
              {index === currentIndex && (
                <img
                  className="banner-image"
                  src={banner.bannerUrl}
                  alt="banner"
                />
              )}
            </div>
          ))}
      </div>
      <button className="prev-btn" onClick={prevBanner}>
        &lt;
      </button>
      <button className="next-btn" onClick={nextBanner}>
        &gt;
      </button>
    </div> */}
      </>
    </>
  );
};

export default BannerSlider;
