export const INITIAL_STATE = {
  cricketOdds: {},
  tennisOdds: {},
  soccerOdds: {},
  tennisGames: [],
  cricketGames: [],
  soccerGames: [],
  casinoOdds: {},
  dragonTiger20Odds: {},
  teenPatti20Odds: {},
  dragonTiger202Odds: {},
  lucky7Odds: {},
  lucky7euOdds: {},
  casinoQueenOdds: {},
  aaaOdds: {},
  poker20Odds: {},
  teenOdds: {},
  andarBahar20Odds: {},
  card32Odds: {},
  teen8Odds: {},
  teen9Odds: {},
  dragonTigerLionOdds: {},
  casinoMeterOdds: {},
  poker9Odds: {},
  worliOdds: {},
  card32euOdds: {},
  baccarat2Odds: {},
  race20Odds: {},
  worli2Odds: {},
  bollywoodTableOdds: {},
  casinoWarOdds: {},
  abjOdds: {},
  baccaratOdds: {},
  cmatch20Odds: {},
  userData: {},
  cricketSettings: {},
  tennisSettings: {},
  soccerSettings: {},
  cricketv3Odds: {},
  dragonTigerOneDayOdds: {},
  loading: false,
  isDataLoaded: false,
  sideBarData: {
    cricket: [],
    tennis: [],
    soccer: [],
    tableTennis: [],
    badminton: [],
    volleyball: [],
    basketball: [],
    handball: [],
    baseball: [],
    snooker: [],
    eGames: [],

    iceHockey: [],
    rugby: [],
    futsal: [],
    kabaddi: [],
    golf: [],
    rugbyLeague: [],
    boxing: [],
  },
  ui: {
    loginPopOpen: false,
    changePasswordPopOpen: false,
    changeButtonPopOpen: false,
  },
  amountButtonValues: [],
};
