import React from "react";
import footerImg1 from "../../assets/img/footer-img-1.png";
import footerImg2 from "../../assets/img/footer-img-2.png";
import footerImg3 from "../../assets/img/footer-img-3.png";
import footerImg4 from "../../assets/img/footer-img-4.png";
import footerImg5 from "../../assets/img/footer-img-5.png";
import footerImg6 from "../../assets/img/footer-img-6.png";
import footerImg7 from "../../assets/img/footer-img-7.png";
import footerImg8 from "../../assets/img/footer-img-8.png";
import footerImg9 from "../../assets/img/footer-img-9.png";
import "./footer.css";

const Footer = () => {
  const footerImages = [
    footerImg1,
    footerImg2,
    footerImg3,
    footerImg4,
    footerImg5,
    footerImg6,
    footerImg7,
    footerImg8,
    footerImg9,
  ];
  return (
    <div className="footer-container">
      <div className="footer-image">
        {footerImages.map((image, index) => (
          <img key={index} src={image} alt={`Image ${index + 1}`} />
        ))}
      </div>
      <div className="footer-text">
        <p>
          You must be over 18 years old, or the legal age at which gambling or
          gaming activities are allowed under the law or jurisdiction that
          applies to you. You must reside in a country in which access to online
          gambling to its residents.
        </p>
      </div>
    </div>
  );
};

export default Footer;
