import { Box, Flex, Image, Text, WrapItem } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import OddsBox from "./OddsBox";

export default function OddsRow({
  data,
  match,
  index,
  num,
  setSelectedEventForBet,
  children,
  game,
  eventId,
  isBookmaker,
}) {
  const [exposure, setExposure] = React.useState({});
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const onClick = (item) => {
    setSelectedEventForBet(item);
  };
  var gameSettings = useSelector((state) => state.gameData[game + "Settings"]);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const ref2 = useRef();
  useEffect(() => {
    let exposure_;
    gameSettings?.plExposureResponseDTOs?.map((item) =>
      item?.runnerPlExposureDTOS?.map((event) => {
        if (isBookmaker) {
          if (
            event?.selectionId == match?.selectionId &&
            item?.marketIdExternal == data?.marketId
          ) {
            exposure_ = event;
          }
        } else {
          if (event?.selectionId == match?.selectionId) {
            exposure_ = event;
          }
        }
      })
    );
    setExposure(exposure_);
  }, [
    data?.marketId,
    gameSettings?.plExposureResponseDTOs,
    isBookmaker,
    match?.selectionId,
  ]);
  useEffect(() => {}, [selectedEventForBet]);

  const getPandL = (data, match) => {
    let pandl = null;
    if (
      match.selectionId == selectedEventForBet?.selectedId &&
      data?.marketName == selectedEventForBet?.marketName
    ) {
      if (selectedEventForBet?.type == "back") {
        pandl =
          parseInt(selectedEventForBet?.profit) +
          (parseInt(exposure?.exposure) ? exposure.exposure : 0);
      } else {
        pandl =
          (parseInt(exposure?.exposure) ? exposure.exposure : 0) -
          parseInt(selectedEventForBet?.loss);
      }
    }
    if (
      match.selectionId != selectedEventForBet?.selectedId &&
      data?.marketName == selectedEventForBet?.marketName
    ) {
      if (selectedEventForBet?.type == "lay") {
        pandl =
          parseInt(selectedEventForBet?.profit) +
          (parseInt(exposure?.exposure) ? exposure.exposure : 0);
      } else {
        pandl =
          (parseInt(exposure?.exposure) ? exposure.exposure : 0) -
          parseInt(selectedEventForBet?.loss);
      }
    }
    if (pandl == null || pandl == undefined) {
      return "";
    } else if (isNaN(pandl)) {
      return "";
    } else {
      return pandl;
    }
  };

  return (
    <WrapItem
      h="100%"
      alignItems="center"
      w="100%"
      className="match-odds-item-wrap"
    >
      <Box
        className={`match-odds-box ${num == 2 ? "" : "nation-name"}`}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        w={num == 2 ? "50%" : `calc(100% - ${num == 6 ? "354px" : "305px"})`}
        justifyContent="space-between"
        style={{ width: num == 2 ? "50% !important" : "" }}
      >
        <Flex
          w="100%"
          my="5px"
          justifyContent={{
            base: "space-between",
            md: "flex-end",
          }}
          alignItems="center"
          className="match-odds-flex-bb"
        >
          <Text
            className="runner-name text-black"
            fontSize="sm"
            fontWeight={{ base: 400, md: 600 }}
            w="100% !important"
            color={{
              base: "#A6A6A6",
            }}
          >
            {match.runnerName ? match.runnerName : match.nat}
          </Text>
          <Text
            display={{ base: "block", md: "none" }}
            fontSize="sm"
            color={exposure?.exposure >= 0 ? "green.400" : "red.400"}
          >
            {exposure?.exposure}
          </Text>
        </Flex>

        <Text
          display={{ base: "none", md: "block" }}
          fontSize="sm"
          color={exposure?.exposure >= 0 ? "green.400" : "red.400"}
        >
          {exposure?.exposure?.toFixed(2)}
        </Text>
      </Box>
      <Text
        display={{ base: "none", md: "block" }}
        fontSize="sm"
        color={getPandL(data, match) >= 0 ? "green.400" : "red.400"}
        alignSelf="center"
        mr="2"
        w="70px"
        textAlign="right"
      >
        {getPandL(data, match)}
      </Text>

      {num == 2 && (
        <Flex
          data-title={match.status}
          className={`flex-match-odds-ff ${
            match.status != "ACTIVE" &&
            match.status != "" &&
            match.status != null
              ? "suspendedtext"
              : ""
          }`}
          ml="auto"
          ref={ref2}
        >
          <OddsBox
            data={data}
            match={match}
            oddsKey={"back1"}
            index={index}
            onClick={(data) =>
              onClick({
                ...data,
                isBookmaker,
              })
            }
            back={true}
            isBookmaker={isBookmaker}
            isBmTwo={true}
          />
          <OddsBox
            data={data}
            match={match}
            oddsKey={"lay1"}
            index={index}
            isBookmaker={isBookmaker}
            back={false}
            isBmTwo={true}
            onClick={(data) =>
              onClick({
                ...data,
                isBookmaker,
              })
            }
          />
        </Flex>
      )}

      {num !== 2 && (
        <Flex
          data-title={match?.status}
          className={`flex-match-odds-ff ${
            match.status != "ACTIVE" &&
            match.status != "" &&
            match.status != null
              ? "suspendedtext"
              : ""
          }`}
        >
          {Object.keys(match)
            .sort((a, b) => -1)
            .map((oddsKey) =>
              oddsKey.startsWith("back") && !oddsKey.startsWith("backSize") ? (
                <OddsBox
                  isBookmaker={isBookmaker}
                  data={data}
                  match={match}
                  oddsKey={oddsKey}
                  index={index}
                  onClick={onClick}
                />
              ) : null
            )}

          {Object.keys(match).map((oddsKey) =>
            oddsKey.startsWith("lay") && !oddsKey.startsWith("laySize") ? (
              <OddsBox
                isBookmaker={isBookmaker}
                data={data}
                match={match}
                oddsKey={oddsKey}
                back={false}
                index={index}
                onClick={onClick}
              />
            ) : null
          )}
        </Flex>
      )}
    </WrapItem>
  );
}
