import React, { useEffect, useMemo } from "react";
import { animate, motion } from "framer-motion";
import { Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
export default function OddsBox({
  match,
  onClick,
  data,
  oddsKey,
  index,
  back = true,
  isFancy,
  isBookmaker,
  isBmTwo,
  soccerTennis,
  ...rest
}) {
  const ref = React.useRef({
    element: {
      current: { style: { backgroundColor: "rgba(114, 187, 239, 1)" } },
    },
  });
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = React.useState(0);
  const userData = useSelector((state) => state.account.userData);
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const convertToLacs = (num) => {
    if (num > 1000 && num < 99999) {
      return parseInt(num / 1000) + "K";
    }
    if (num > 100000) {
      return parseInt(num / 100000) + "L";
    } else {
      return num;
    }
  };

  useEffect(() => {
    const colorMapH = {
      back12: "rgba(114, 187, 239, 1)",
      back11: "rgba(114, 187, 239, 1)",
      back1: "rgba(114, 187, 239, 1)",
      lay12: "rgba(249, 148, 186, 1)",
      lay11: "rgba(249, 148, 186, 1)",
      lay1: "rgba(249, 148, 186, 1)",
    };
    const colorMapI = {
      back3: "rgba(114, 187, 239, 0.5)",
      back2: "rgba(114, 187, 239, 0.75)",
      back1: "rgba(114, 187, 239, 1)",
      lay3: "rgba(249, 148, 186, 0.5)",
      lay2: "rgba(249, 148, 186, 0.75)",
      lay1: "rgba(249, 148, 186, 1)",
    };
    var colorMap;
    if (rest.home) {
      colorMap = colorMapH;
    } else {
      colorMap = colorMapI;
    }
    const element = ref?.current;
    if (element && element.style) {
      if (currentValue > rest.overRide ? match["back1"] : match[oddsKey]) {
        element.style.backgroundColor = "yellow";
      } else if (
        currentValue == rest.overRide ? match["back1"] : match[oddsKey]
      ) {
        element.style.backgroundColor = rest.overRide
          ? colorMap["back1"]
          : colorMap[oddsKey];
      } else {
        element.style.backgroundColor = "#c22121";
        setTimeout(() => {
          element.style.backgroundColor = rest.overRide
            ? colorMap["back1"]
            : colorMap[oddsKey];
        }, 200);
      }
      setTimeout(() => {
        element.style.backgroundColor = rest.overRide
          ? colorMap["back1"]
          : colorMap[oddsKey];
      }, 500);
    }
    setCurrentValue(match[oddsKey]);
  }, [match[oddsKey], ref]);

  return (match[oddsKey] == 0 || match == 0) && rest.home ? (
    <div ref={ref} className={`no-val ${oddsKey}  bl-box`}>
      <span className="d-block odds">—</span>
    </div>
  ) : (
    <motion.div
      style={{
        width: (isFancy || isBmTwo) && isMobile ? "100%" : "55px",
      }}
      onClick={() => {
        if (!userData?.token) {
          dispatch({
            type: "accountData/setLoginPopSate",
            payload: true,
          });
          return;
        }
        if (match[oddsKey] == 0 || match == 0) {
          return;
        }
        console.log(match[oddsKey], rest.overRide, "match[oddsKey]");
        onClick({
          runnerName: match.runnerName,
          isBookmaker: isBookmaker,
          selectedOdd: rest.overRide
            ? match[oddsKey]
            : oddsKey
            ? match[oddsKey]
            : match,
          selectedId: match.selectionId,
          type: rest.overRide ? "back" : back ? "back" : "lay",
          selectedRate:
            match[
              rest.overRide
                ? "lay1".slice(0, 3) + "Size"
                : oddsKey.slice(0, back ? 4 : 3) +
                  "Size" +
                  oddsKey.slice(back ? 4 : 3)
            ],
        });
      }}
      ref={ref}
      {...rest}
      className={`${back ? "oddsBoxb" : "oddsBoxr"} oddsBoxH`}
    >
      <Text fontSize="md" fontWeight="600">
        {isFancy || isBookmaker
          ? parseFloat(oddsKey ? (match[oddsKey] ? match[oddsKey] : 0) : match)
          : oddsKey
          ? match[oddsKey]
          : match}
      </Text>
      <Text mt="-1" fontSize="11px">
        {convertToLacs(
          isFancy || isBookmaker
            ? parseFloat(
                match[
                  oddsKey.slice(0, back ? 4 : 3) +
                    "Size" +
                    oddsKey.slice(back ? 4 : 3)
                ]
                  ? match[
                      oddsKey.slice(0, back ? 4 : 3) +
                        "Size" +
                        oddsKey.slice(back ? 4 : 3)
                    ]
                  : 0
              )
            : oddsKey
            ? match[oddsKey.slice(0, back ? 4 : 3) + "Size" + (index + 1)]
            : match
        )}
      </Text>
    </motion.div>
  );
}
