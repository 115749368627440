/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  IconButton,
  Img,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./header.css";
import { FiMenu } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { getAnnouncementAPI, searchAPI } from "../../service/AuthService";
import { useSearchParams } from "react-router-dom";
import royalExch from "../../assets/img/royalExch.svg";
import announcementIcon from "../../assets/img/announcement_icon.png";
import LoginModal from "../../pages/login/loginModal";
import personIcon from "../../assets/img/personIcon.png";

import coinIcon from "../../assets/img/coinIcon.svg";
import SideMenu from "./SideMenu";

const Header = ({ setIsSidebarOpen }) => {
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const [isDropMenuOpen, setIsDropMenuOpen] = React.useState(false);
  const location = useLocation();
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [announcement, setAnnouncement] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const token = useSelector((state) => state.account.userData.token);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const userData = useSelector((state) => state.account.userData);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const search = async (value) => {
    setSearchValue(value);
    if (!value) {
      setIsSearchResultOpen(false);
    }
    if (value.length >= 3) {
      const res = await searchAPI(value);
      if (res && res.code === 200) {
        setSearchResults(res.response);
        setIsSearchResultOpen(true);
      }
    }
  };

  const getAnnouncement = async () => {
    const res = await getAnnouncementAPI();
    console.log("announcement", res);
    if (res?.status === 200) {
      setAnnouncement(res.data?.response.announcement);
    }
  };

  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      setCurrentDate(now.toLocaleString("en-US", options));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleCloseSideMenu = (event) => {
    if (
      !event.target.closest(".account") &&
      !event.target.closest(".close-icon")
    ) {
      setIsDropMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseSideMenu);
    return () => {
      document.removeEventListener("click", handleCloseSideMenu);
    };
  }, []);

  console.log(userData.balance, "user data");
  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
      className=" "
    >
      <div className="header-announcement">
        <img className="announcement-icon" src={announcementIcon} alt="icon" />
        <div className="announcement-text">
          <p>
            Enjoy Unmatched Betting Excitement and Access 500+ Casino and Online
            Games
          </p>
        </div>
        <div className="announcement-date header-bg">{currentDate}</div>
      </div>

      <div className="header-top col-md-12">
        <IconButton
          display={{ base: "none", md: "none" }}
          onClick={() => setIsSidebarOpen((prev) => !prev)}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
          className="menu-btn"
        />
        <div className="float-left logo-box">
          <a href="/" className="logo">
            <Img src={royalExch} className="logo-icon" />
          </a>
        </div>
        <ul className="float-right d-flex align-items-center">
          {!token ? (
            <>
              <LoginModal isOpen={isOpen} onClose={onClose} />
              <li className="float-left download-apklink">
                <div>
                  <a
                    href="javascript:void(0)"
                    onClick={onOpen}
                    className="rules-link m-r-5"
                  >
                    <Button className="d-flex home-login-button">
                      <Img src={personIcon} alt="img" />
                      <Text>Login</Text>
                    </Button>
                  </a>
                </div>
              </li>
            </>
          ) : (
            <>
              <li className="account float-left">
                <span onClick={() => setIsDropMenuOpen(!isDropMenuOpen)}>
                  <div className="balance-box">
                    <img
                      src={coinIcon}
                      alt="icon"
                      style={{ width: 25, height: 25 }}
                    />
                    <span>{userData.balance}</span>
                  </div>
                  <div className="balance-box">
                    <text>Exp :</text>
                    <span>{userData.liability}</span>
                  </div>
                </span>
                <SideMenu
                  isDropMenuOpen={isDropMenuOpen}
                  handleCloseSideMenu={handleCloseSideMenu}
                />
              </li>
            </>
          )}
        </ul>
      </div>

      <div className="header-bottom col-md-12">
        <nav className="navbar navbar-expand-md btco-hover-menu">
          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse">
            <ul className="list-unstyled navbar-nav">
              <li className="nav-item active">
                <a
                  href="/"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  <b>Home</b>
                </a>
              </li>
              <li className="nav-item active">
                <a
                  href="/in-play"
                  className={
                    location.pathname.includes("in-play") ? "active" : ""
                  }
                >
                  <b>In-Play</b>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/games/cricket"
                  className={
                    location.pathname.includes("cricket") ? "active" : ""
                  }
                >
                  <b>Cricket</b>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/games/tennis"
                  className={
                    location.pathname.includes("tennis") ? "active" : ""
                  }
                >
                  <b>Tennis</b>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/games/soccer"
                  className={
                    location.pathname.includes("soccer") ? "active" : ""
                  }
                >
                  <b>Football</b>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/live-casino"
                  className={
                    location.pathname.includes("live-casino") &&
                    !searchParams.get("name")
                      ? "active"
                      : ""
                  }
                >
                  <b>Live Casino</b>
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="/games/kabaddi">
                  <b>Kabaddi</b>
                </a>
              </li> */}

              <li className="nav-item">
                <a
                  href="/baccarat-list"
                  className={location.pathname.includes("baccarat-list") &&
                    !searchParams.get("name") ? "active" : ""
                  }
                >
                    <b>Baccarat</b>
                </a>
              </li>
              <li className="nav-item">
              <a
                  href="/cards32-list"
                  className={location.pathname.includes("cards32-list") &&
                    !searchParams.get("name") ? "active" : ""
                  }
                >
                  <span>
                    <b>32 Cards</b>
                  </span>
                </a>
              </li>
              <li className="nav-item">
              <a
                  href="/teenpatti-list"
                  className={location.pathname.includes("teenpatti-list") &&
                    !searchParams.get("name") ? "active" : ""
                  }
                >
                  <span>
                    <b>Teenpatti</b>
                  </span>
                </a>
              </li>
              <li className="nav-item">
              <a
                  href="/poker-list"
                  className={location.pathname.includes("poker-list") &&
                    !searchParams.get("name") ? "active" : ""
                  }
                >
                  <span>
                    <b>Poker</b>
                  </span>
                </a>
              </li>
              <li className="nav-item">
              <a
                  href="/lucky7-list"
                  className={location.pathname.includes("lucky7-list") &&
                    !searchParams.get("name") ? "active" : ""
                  }
                >
                  <span>
                    <b>Lucky 7</b>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
