import {
  Box,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import { unSetteledBetsAPI } from "../../service/AuthService";
import "../../includes/header/header.css";

export default function UnSetteledBetModal({ isOpen, onClose }) {
  const [from, setFrom] = useState(getCurrentDateTimeLocal(true));
  const [to, setTo] = useState(getCurrentDateTimeLocal());
  const [selectedCategory, setSelectedCategory] = useState("MATCHED");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);

  const getUnSetteledBets = async () => {
    let payload = {
      pageNumber: page,
      pageSize: size,
      search: "",
      betStatus: selectedCategory,
    };
    const {
      response: { content },
    } = await unSetteledBetsAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,
      no: index + 1,
      side: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
      line: "-",
      status: "Placed",
    }));
    setData(data_);
  };

  useEffect(() => {
    getUnSetteledBets();
  }, [selectedCategory]);

  return (
    <>
    {/* <Button
        onClick={isOpen}
        className="modal-btn"
        style={{
          fontSize: "13px !important",
          fontWeight: 500,
          paddingLeft: "8px",
        }}
      >
        Un-Settled Bet
      </Button> */}
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent>
        <Box
          borderRadius={10}
          border="1px solid lightgray"
          className="setting-page-main unsetteled-main"
          overflow="hidden"
        >
          <Box
            p="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            fontWeight="bold"
            color="white"
            background="#27A6C3"
            padding={2}
            // marginTop="-5px"
            className="bg-gradiant"
            height={30}
          >
            <Text fontSize="sm" color="#fff !important">
             
            </Text>
            <ModalCloseButton style={{ paddingBottom: "15px", color: "black" }} />
          </Box>
          <Box p="2">
            <CustomTable
              currentPage_={page}
              pageSize_={size}
              total_={data.length}
              showPagination={true}
              rowsPerPage_={[10, 20, 30, 40, 50]}
              columns={[
                { name: "Competition", id: "competition" },
                { name: "Event", id: "event_name" },
                { name: "Market", id: "marketName" },
                { name: "Runner", id: "runner_name" },
                { name: "Side", id: "side" },
                { name: "Line", id: "line" },
                { name: "Rate", id: "rate" },
                { name: "Amount", id: "amount" },
                { name: "PP", id: "profit" },
                { name: "PL", id: "loss" },
                { name: "Status", id: "status" },
                { name: "Date", id: "created_date" },
              ]}
              rows={data}
            />
          </Box>
        </Box>
      </ModalContent>
    </Modal>
    </>
  );
}
