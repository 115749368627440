import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  IconButton,
  Flex,
  useToast,
  Img,
  Text,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { loginAPI } from '../../service/AuthService';
import './loginModal.css';
import { Encrypt } from '../../utils/constants';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import captchaImg from '../../assets/img/captcha_Img.svg';
import reloadImg from '../../assets/img/reload.svg';

const LoginModal = ({ isOpen, onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [answer, setAnswer] = useState('');

  function generateCaptcha() {
    const num1 = Math.floor(Math.random() * 100);
    const num2 = Math.floor(Math.random() * 10);
    return { num1, num2, result: num1 + num2 };
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (parseInt(answer) !== captcha.result) {
      toast({
        title: 'Error',
        description: 'Invalid Captcha! Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setCaptcha(generateCaptcha());
      setAnswer('');
      return;
    }

    try {
      const res = await loginAPI(userName, Encrypt(password));
      const resData = res.data;

      if (resData.code === 200) {
        localStorage.setItem('token', resData.response.token);
        localStorage.setItem('user', JSON.stringify(resData.response.username));
        dispatch({
          type: 'accountData/setUserData',
          payload: resData.response,
        });
        toast({
          title: 'Success',
          description: 'Login successful!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: 'Error',
          description: resData.response || 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setCaptcha(generateCaptcha());
        setAnswer('');
      }
    } catch (error) {
      console.error('Login Error:', error);
      toast({
        title: 'Error',
        description: 'Failed to login. Please try again later.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleReloadCaptcha = () => {
    handleReloadCaptcha();
    toast({
      title: 'Success',
      description: 'Captcha Updated!',
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        setUserName('');
        setPassword('');
        setAnswer('');
      }}
      size='xl'
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent className='login-modal'>
        <ModalHeader textAlign='center'>Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleLogin}>
            <FormControl>
              <FormLabel>User ID</FormLabel>
              <Input
                type='text'
                placeholder='User ID'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                border='1px solid black'
              />
            </FormControl>
            <Flex justifyContent='flex-end' mt={4}>
              <Checkbox
                defaultChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                Remember me
              </Checkbox>
            </Flex>
            <FormControl mb={2}>
              <FormLabel>Password</FormLabel>
              <Flex align='center'>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  border='1px solid black'
                />
                <IconButton
                  className='pass-hide-btn'
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                  icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                  onClick={togglePasswordVisibility}
                />
              </Flex>
            </FormControl>

            <Flex
              align='center'
              border='1px solid black'
              bgColor='#D3D3D3'
              p={3}
              mb={4}
              borderRadius='5px'
            >
              <Text bgImage={captchaImg} width='50%' m='0px 30px 0px 30px'>
                <Flex justify='center' gap={'5px'}>
                  <Text fontSize='18px'>{captcha.num1}</Text> +{' '}
                  <Text fontSize='18px'>{captcha.num2}</Text>
                </Flex>
              </Text>
              <Input
                placeholder='Solve Captcha'
                value={answer}
                onChange={(e) => handleChange(e)}
                width='40%'
                bgColor='white'
                mr={2}
              />
              {/* <Img
                src={reloadImg}
                alt='reload'
                height='30px'
                width='30%'
                textAlign='center'
                onClick={handleReloadCaptcha}
                cursor='pointer'
              /> */}
            </Flex>

            <Button type='submit' className='login-btn' mt={5} mb={5} width='full'>
              Login
            </Button>
            
            {/* <Button
              className='login-btn'
              mt={5}
              mb={5}
              width='full'
              onClick={onClose}
            >
              Login with demo account
            </Button> */}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
