import React from 'react';
import BannerSlider from '../banner/banner';
import image1 from '../../assets/img/home-img-1.png';
import image2 from '../../assets/img/home-img-2.png';
import image3 from '../../assets/img/home-img-3.png';
import image4 from '../../assets/img/home-img-4.png';
import image5 from '../../assets/img/home-img-4.png';

import topGame1 from '../../assets/img/Top Game/topGame1.png';
import topGame2 from '../../assets/img/Top Game/topGame2.png';
import topGame3 from '../../assets/img/Top Game/topGame3.png';
import topGame4 from '../../assets/img/Top Game/topGame4.png';
import topGame5 from '../../assets/img/Top Game/topGame5.png';
import topGame6 from '../../assets/img/Top Game/topGame6.png';
import topGame7 from '../../assets/img/Top Game/topGame7.png';
import topGame8 from '../../assets/img/Top Game/topGame8.png';
import topGame9 from '../../assets/img/Top Game/topGame9.png';
import { Flex, Text } from '@chakra-ui/react';
import SportOddsTable from '../home/SportOddsTable';
import ExportTabs from '../home/exportTabs';
import "./landingPage.css";

const LandingPage = () => {
  const container1 = [image1, image2, image3];
  const container2 = [image4, image5];
  const topGames = [
    topGame1,
    topGame2,
    topGame3,
    topGame4,
    topGame5,
    topGame6,
    topGame7,
    topGame8,
    topGame9,
  ];
  return (
    <>
      <BannerSlider />
      <Flex flexDir={'column'} padding={'10px 7px'} gap={'11px'}>
        <Flex gap='10px'>
          {container1.map((image, index) => (
            <img
              key={index}
              src={image}
              // style={{ height: '179.032px', width: '33.333%' }}
              className="conatainer-1-img"
              alt={`Image ${index + 1}`}
            />
          ))}
        </Flex>

        <Flex gap='10px'>
          {container2.map((image, index) => (
            <img
              key={index}
              src={image}
              // style={{ height: '115px', width: '50%' }}
              className="conatainer-2-img"
              alt={`Image ${index + 4}`}
            />
          ))}
        </Flex>

        <Text fontSize={'15px'} fontWeight={600}>
          Top Games
        </Text>
        <Flex className='top-games'>
          {topGames.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 4}`} />
          ))}
        </Flex>
        <br/>
        {/* <SportOddsTable sportName={'cricket'} />
        <SportOddsTable sportName={'soccer'} />
        <SportOddsTable sportName={'tennis'} /> */}
        <ExportTabs />
      </Flex>
    </>
  );
};

export default LandingPage;
