import React, { useState, useEffect } from "react";
import accountStatementIcon from "../../assets/img/accStatIcon.svg";
import CGAccStatIcon from "../../assets/img/CGaccStatIcon.svg";
import PnLIcon from "../../assets/img/PnLIcon.svg";
import betHistoryIcon from "../../assets/img/betHistoryIcon.svg";
import CGbetHistIcon from "../../assets/img/CGbetHistIcon.svg";
import unSetBetIcon from "../../assets/img/unSetBetIcon.svg";
import CGunSetBetIcon from "../../assets/img/CGunSetBetIcon.svg";
import setStakeIcon from "../../assets/img/setStakeIcon.svg";
import rulesIcon from "../../assets/img/rulesIcon.svg";
import changePassIcon from "../../assets/img/changePassIcon.svg";
import logoutIcon from "../../assets/img/logoutIcon.svg";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import personIconYellow from "../../assets/img/personIconYellow.svg";
import { useDispatch, useSelector } from "react-redux";
import UnSetteledBetModal from "../../pages/unSetteledBet/unSetBetModal";
import { Text } from "@chakra-ui/react";

const SideMenu = ({ isDropMenuOpen, fromPrivate, onClose }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(isDropMenuOpen);

  useEffect(() => {
    setIsSideMenuOpen(isDropMenuOpen);
  }, [isDropMenuOpen]);

  const openModal = () => {
    console.log("modal opened");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseSideMenu = () => {
    setIsSideMenuOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={fromPrivate ? "is-private" : ""}>
      {fromPrivate && (
        <Text bg="#27A6C3" color={"#fff !important"} p={"5px 9px"}>
          Main Menu
        </Text>
      )}
{/* right-side-bar */}
<ul className={isSideMenuOpen ? "d-block" : ""}
    style={
        isSideMenuOpen
        ? {
            position: "fixed",
            top: 0,
            right: 0,
            height: "100%",
            width: "65%",
            maxWidth: "270px",
            overflowX: "hidden",
            transition: "0.5s",
            transform: "translateX(0)",
            transition: "transform 0.9s ease, width 0.9s ease",
            backgroundColor: "#045662",
            zIndex: 1000,
            padding: "20px",
          }
        : {}
    }
>

        {!fromPrivate && (
          <>

            <div>
              <img
                className="close-icon"
                src={closeIcon}
                alt="icon"
                onClick={handleCloseSideMenu}
                style={{ cursor: "pointer", float: "right" }}
              />
            </div>
            <div style={{ justifyContent: "start", paddingLeft: "25px" }}>
              <img
                src={personIconYellow}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: 500,
                  marginTop: "-5px",
                }}
              >
                {userData.username} - ({userData.balance})
              </div>
            </div>
            <div>
              <div className="side-menu-box-1" onClick={openModal}>
                <text>Exposure</text>
                <span>{userData.liability}</span>
              </div>
              <div className="side-menu-box-1">
                <text>P&L</text>
                <span>{userData.ownPl}</span>
              </div>
            </div>
            <div>
              <div className="side-menu-box-2">
                <text>CG Exposure</text>
                <span>0.00</span>
              </div>
              <div className="side-menu-box-2">
                <text>CG P&L</text>
                <span>0.00</span>
              </div>
            </div>
          </>
        )}
        <li>
          <img src={accountStatementIcon} alt="icon" />
          <a href="/report/account-statement">Account Statement</a>
        </li>
        {/* <li>
            <img src={CGAccStatIcon} alt="icon" />
            <a href="/report/cg/account-statement">CG Account Statement</a>
          </li> */}
        <li>
          <img src={PnLIcon} alt="icon" />
          <a href="/report/profit-loss-report">Profit Loss Report</a>
        </li>
        <li>
          <img src={betHistoryIcon} alt="icon" />
          <a href="/report/bet-history">Bet History</a>
        </li>
        <li>
          <img src={CGbetHistIcon} alt="icon" />
          <a href="/report/cg/bet-history">CG Bet History</a>
        </li>
        <li>
          <img src={unSetBetIcon} alt="icon" />
          <a href="/report/unsetteled-bet">Unsettled Bet</a>
        </li>
        <li>
          <img src={CGunSetBetIcon} alt="icon" />
          <a href="/report/cg/unsetteled-bet">CG Unsetteled Bet</a>
        </li>
        <li>
          <img src={setStakeIcon} alt="icon" />
          <a href="/report/changebtnvalue">Set Stake</a>
        </li>
        <li>
          <img src={rulesIcon} alt="icon" />
          <a href="/rules">Rules</a>
        </li>
        <li>
          <img src={changePassIcon} alt="icon" />
          <a href="/report/changepassword">Change Password</a>
        </li>
        <li>
          <img src={logoutIcon} alt="icon" />
          <a
            href="#"
            onClick={() => {
              dispatch({ type: "gameData/logOut" });
              dispatch({ type: "accountData/logOut" });
              const _lastBetCount = parseInt(
                localStorage.getItem("lastBetCount")
              );
              localStorage.clear();
              localStorage.setItem("lastBetCount", _lastBetCount);
              window.location.href = "/";
            }}
          >
            Sign out
          </a>
        </li>
      </ul>
      <UnSetteledBetModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default SideMenu;


// import React, { useState, useEffect } from "react";
// import accountStatementIcon from "../../assets/img/accStatIcon.svg";
// import CGAccStatIcon from "../../assets/img/CGaccStatIcon.svg";
// import PnLIcon from "../../assets/img/PnLIcon.svg";
// import betHistoryIcon from "../../assets/img/betHistoryIcon.svg";
// import CGbetHistIcon from "../../assets/img/CGbetHistIcon.svg";
// import unSetBetIcon from "../../assets/img/unSetBetIcon.svg";
// import CGunSetBetIcon from "../../assets/img/CGunSetBetIcon.svg";
// import setStakeIcon from "../../assets/img/setStakeIcon.svg";
// import rulesIcon from "../../assets/img/rulesIcon.svg";
// import changePassIcon from "../../assets/img/changePassIcon.svg";
// import logoutIcon from "../../assets/img/logoutIcon.svg";
// import closeIcon from "../../assets/img/icons/closeIcon.svg";
// import personIconYellow from "../../assets/img/personIconYellow.svg";
// import { useDispatch, useSelector } from "react-redux";
// import UnSetteledBetModal from "../../pages/unSetteledBet/unSetBetModal";
// import { Text } from "@chakra-ui/react";

// const SideMenu = ({ isDropMenuOpen, fromPrivate, onClose }) => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.account.userData);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isSideMenuOpen, setIsSideMenuOpen] = useState(isDropMenuOpen);

//   useEffect(() => {
//     setIsSideMenuOpen(isDropMenuOpen);
//   }, [isDropMenuOpen]);

//   const openModal = () => {
//     console.log("modal opened")
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleCloseSideMenu = () => {
//     setIsSideMenuOpen(false);
//     if (onClose) {
//       onClose();
//     }
//   };

//   return (
//     <div className={fromPrivate ? "is-private" : ""}>
//       {fromPrivate && (
//         <Text bg="#27A6C3" color={"#fff !important"} p={"5px 9px"}>
//           Main Menu
//         </Text>
//       )}

//       <ul className={isSideMenuOpen ? "d-block" : ""}>
//         {!fromPrivate && (
//           <>
//             <div>
//               <img
//                 className="close-icon"
//                 src={closeIcon}
//                 alt="icon"
//                 onClick={handleCloseSideMenu}
//               />
//             </div>
//             <div style={{ justifyContent: "start", paddingLeft: "25px" }}>
//               <img
//                 src={personIconYellow}
//                 alt="icon"
//                 style={{ width: "20px", height: "20px" }}
//               />
//               <div
//                 style={{
//                   color: "white",
//                   fontSize: 16,
//                   fontWeight: 500,
//                   marginTop: "-5px",
//                 }}
//               >
//                 {userData.username} - ({userData.balance})
//               </div>
//             </div>
//             <div>
//               <div className="side-menu-box-1" onClick={openModal}>
//                 <text>Exposure</text>
//                 <span>{userData.liability}</span>
//               </div>
//               <div className="side-menu-box-1">
//                 <text>P&L</text>
//                 <span>{userData.ownPl}</span>
//               </div>
//             </div>
//             <div>
//               <div className="side-menu-box-2">
//                 <text>CG Exposure</text>
//                 <span>0.00</span>
//               </div>
//               <div className="side-menu-box-2">
//                 <text>CG P&L</text>
//                 <span>0.00</span>
//               </div>
//             </div>
//           </>
//         )}

//         <li>
//           <img src={accountStatementIcon} alt="icon" />
//           <a href="/report/account-statement" className>
//             Account Statement
//           </a>
//         </li>
//         {/* <li>
//           <img src={CGAccStatIcon} alt="icon" />
//           <a href="/report/cg/account-statement" className>
//             CG Account Statement
//           </a>
//         </li> */}
//         <li>
//           <img src={PnLIcon} alt="icon" />
//           <a href="/report/profit-loss-report" className>
//             Profit Loss Report
//           </a>
//         </li>
//         <li>
//           <img src={betHistoryIcon} alt="icon" />
//           <a href="/report/bet-history" className>
//             Bet History
//           </a>
//         </li>
//         <li>
//           <img src={CGbetHistIcon} alt="icon" />
//           <a href="/report/cg/bet-history" className>
//             CG Bet History
//           </a>
//         </li>
//         {/* <li>
//     <img src={unSetBetIcon} alt="icon" />
//     <a href="/report/unsetteled-bet" className>
//       Unsetteled Bet
//     </a>
//   </li> */}
//         <li >
//           <img src={unSetBetIcon} alt="icon" />
//           {/* <UnSetteledBetModal openModal={openModal} /> */}
//           {/* <span>Unsetteled Bet</span> */}
//           <a href="/report/unsetteled-bet" className>
//           Unsettled Bet</a>
//         </li>

//         <li>
//           <img src={CGunSetBetIcon} alt="icon" />
//           <a href="/report/cg/unsetteled-bet" className>
//             CG Unsetteled Bet
//           </a>
//         </li>
//         <li>
//           <img src={setStakeIcon} alt="icon" />
//           <a href="/report/changebtnvalue" className>
//             Set Stake
//           </a>
//         </li>
//         <li>
//           <img src={rulesIcon} alt="icon" />
//           <a href="/rules" className>
//             Rules
//           </a>
//         </li>
//         {/* <li>
//           <img src={betHistoryIcon} alt='icon' />
//           <a href='/settings/secureauth' className>
//             Security Auth Verification
//           </a>
//         </li> */}
//         <li>
//           <img src={changePassIcon} alt="icon" />
//           <a href="/report/changepassword" className>
//             Change Password
//           </a>
//         </li>
//         <li>
//           <img src={logoutIcon} alt="icon" />
//           <a
//             href="#"
//             onClick={() => {
//               dispatch({ type: "gameData/logOut" });
//               dispatch({ type: "accountData/logOut" });
//               const _lastBetCount = parseInt(
//                 localStorage.getItem("lastBetCount")
//               );
//               localStorage.clear();
//               localStorage.setItem("lastBetCount", _lastBetCount);
//               window.location.href = "/";
//             }}
//           >
//             signout
//           </a>
//         </li>
//       </ul>
//       <UnSetteledBetModal isOpen={isModalOpen} onClose={handleCloseModal} />
//     </div>
//   );
// };

// export default SideMenu;
