import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  Tab,
  TabList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CasinoResultModal from "../../components/CasinoResultModal/CasinoResultModal";

export default function RulesPage() {
  const toast = useToast();
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  return (
    <Box
      bg={{ base: "transparent", md: "#fff" }}
      textColor="#A6A6A6"
    //   pt={{ base: "60px", md: "0" }}
      className="setting-page-main change-button-value-main"
    >
      <Modal
        size={"5xl"}
        isOpen={isResultModalOpen}
        onClose={setIsResultModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <CasinoResultModal
            marketId={isResultModalOpen}
            onClose={setIsResultModalOpen}
          />
        </ModalContent>
      </Modal>
      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        background="#27A6C3"
        padding={2}
        marginTop="-5px"
      >
        <Text
          textColor="white"
          fontSize="sm"
          color="#fff !important"
          fontWeight="bold"
        >
          Rules
        </Text>
      </Box>

      <Box mt={5} className="change-button-value-box" style={{width:"100% !important"}}>
        <Tabs p="0" variant="enclosed">
          <TabList mb="2" borderBottom="none" style={{justifyContent:"center"}}>
            <Tab
              _selected={{
                bg: "#262626",
              }}
              fontSize="xs"
              borderRadius={5}
              fontWeight="bold"
            >
              English
            </Tab>
            <Tab
              borderRadius={5}
              fontSize="xs"
              _selected={{
                bg: "#262626",
              }}
              ml={2}
              fontWeight="bold"
            >
              Hindi
            </Tab>
          </TabList>
          <TabPanels p="0" width="100%" style={{ width: "100%" }}>
            <TabPanel className="text-black" p="0">
              <Text fontSize="lg" fontWeight="bold" mt="4">
                PLEASE READ OUR RULES BEFORE PLACING BET
              </Text>
              <Text>
                <b>1. SCORECARD RULES:</b>
                <br />
                <b>
                  1.1) Live scores and other data on this site is sourced from
                  third party feeds and may be subject to time delays and/or be
                  inaccurate. If you rely on this data to place bets, you do so
                  at your own risk. Our exchange does not accept responsibility
                  for loss suffered as a result of reliance on this data.
                </b>
                <br />
                1.2) If Any Dispute in Fancy Results, then the Company Decision
                Will be Final.
                <br />
                <br />
                <b>2. CHEAT RULES:</b>
                <br />
                2.1) In Betfair & Fancy markets, If anyone is suspected using
                ground commentary or ground line or courtsiding(being in ground
                while betting), company will void all winning bets(without
                providing any proof). This is a zero tolerance policy and no
                arguments will be entertained. The bets will be voided after the
                match is completed. Company's decision will be final decision.
                <br />
                2.2) If Exchange resettle any bets or Markets due to wrong
                Result Declaration or any other reason & if the Player has lost
                Money or If Player gets Negative Balance, In this case Company
                will do the Settlement from Agent for the same, all decision
                will be Full And Final, No Arguments will be listened.
                <br />
                <br />
                <b>3. MATCH ODDS RULES:</b>
                <br />
                3.1) Cricket: We will follow Betfair result.
                <br />
                3.2) Soccer: We will follow Betfair result.
                <br />
                3.3) Tennis: We will follow Betfair result.
                <br />
                3.4) IF Any Client Found Hedging Or Doing Manipulation in Any
                Match Odds, then company has the right to Void the Bets.
                <br />
                3.5) If Exchange resettle any bets or Markets due to wrong
                Result Declaration or any other reason & if the Player has lost
                Money or If Player gets Negative Balance, In this case Company
                will do the Settlement from Agent for the same, all decision
                will be Full And Final, No Arguments will be listened.
                <br />
                3.6) Company reserves the right to void any bets (only winning
                bets) of any event at any point of the match if the company
                believes there is any cheating/wrong doing in that particular
                event by the players (either batsman/bowler)
                <br />
                3.7) If there is a super over then the match odds of the Super
                Over will be settled after the Final result of winner team is
                declared but in case of Bookmaker we will Settle or Void the
                Particular super over Bookmaker Based upon that Particular
                Markets Result
                <br />
                <br />
                <b>4. BOOK-MAKER MARKET RULES:</b>
                <br />
                4.1) Due to any reason any team will be getting advantage or
                disadvantage we are not concerned.
                <br />
                4.2) Wrong team selection by our mistake all back and lays bets
                will be deleted.
                <br />
                4.3) wrong rate bets also voided.
                <br />
                4.4) If Exchange resettle any bets or Markets due to wrong
                Result Declaration or any other reason & if the Player has lost
                Money or If Player gets Negative Balance, In this case Company
                will do the Settlement from Agent for the same, all decision
                will be Full And Final, No Arguments will be listened.
                <br />
                4.5) Company reserves the right to void any bets (only winning
                bets) of any event at any point of the match if the company
                believes there is any cheating/wrong doing in that particular
                event by the players (either batsman/bowler)
                <br />
                <br />
                <b>5. FANCY:</b>
                <br />
                5.1) Advance fancy: - Advance Session, Player Runs and all Fancy
                Bets are only valid for 20 Over/50 over full match each side.
                (Please Note this condition is applied only in case of Advance
                Fancy Bets only).
                <br />
                5.2) All advance fancy bets market will be suspended 60 mins
                prior to match and will be settled.
                <br />
                5.3) Advance session and Lambi 1st inning valid only.
                <br />
                5.4) Total match playing over adv: - We Will Settle this Market
                after Whole Match gets Completed.
                <br />
                5.5) Criteria: - We Will Count Only Round- Off Over For Both the
                Innings While Settling (For Ex: - If 1st Batting team gets all
                out at 17.3, 18.4 or 19.5 we Will Count Such Overs as 17, 18 and
                19 Respectively and if Match gets Ended at 17.2, 18.3 or 19.3
                Overs then we will Count that as 17, 18 and 19 Over
                Respectively... and this Will Remain Same For Both the Innings.
                <br />
                5.6) In Case of Rain or if Over gets Reduced then this Market
                will get Voided.
                <br />
                5.7) 3 Wkt or more by bowler in match adv:- We Will Settle this
                Market after Whole Match gets Completed.
                <br />
                5.8) Once all session/fancy bets are completed and settled there
                will be no reversal even if the Match is Tied or is Abandoned.
                <br />
                5.9) Under the rules of Session/Fancy Bets if a market gets
                Suspended for any reason whatsoever and does not resume then all
                previous Bets will remain Valid and become HAAR/JEET bets.
                <br />
                5.10) Incomplete Session/Fancy Bet will be cancelled but
                Complete Session will be settled.
                <br />
                5.11) In the case of Running Match getting Cancelled/ No Result/
                Abandoned but the session is complete it will still be settled.
                Player runs / fall of wicket/Wicket lost to ball/How many ball
                face by player will be also settled at the figures where match
                gets stopped due to rain for the inning (D/L) , cancelled ,
                abandoned , no result.
                <br />
                5.12) If a player gets Retired Hurt and one ball is completed
                after you place your bets then all the betting till then is and
                will remain valid.
                <br />
                5.13) Should a Technical Glitch in Software occur, we will not
                be held responsible for any losses.
                <br />
                5.14) Should there be a power failure or a problem with the
                Internet connection at our end and session/fancy market does not
                get suspended then our decision on the outcome is final.
                <br />
                5.15) All decisions relating to settlement of wrong market being
                offered will be taken by management. Management will consider
                all actual facts and decision taken will be full in final.
                <br />
                5.16) Any bets which are deemed of being suspicious, including
                bets which have been placed from the stadium or from a source at
                the stadium maybe void at anytime. The decision of whether to
                void the particular bet in question or to void the entire market
                will remain at the discretion of Company. The final decision of
                whether bets are suspicious will be taken by Company and that
                decision will be full and final.
                <br />
                5.17) Any sort of cheating bet, any sort of Matching (Passing of
                funds), Court Siding (Ghaobaazi on commentary), Sharpening,
                Commission making is not allowed in Company, If any company User
                is caught in any of such act then all the funds belonging that
                account would be seized and confiscated. No argument or claim in
                that context would be entertained and the decision made by
                company management will stand as final authority.
                <br />
                5.18) If any case wrong rate has been given in fancy,that
                particular bets will be cancelled (Wrong Commentary).
                <br />
                5.19) In case customer make bets in wrong fancy we are not
                liable to delete, no changes will be made and bets will be
                considered as confirm bet.
                <br />
                5.20) any query regarding result or rate has to be contacted
                within 7 days from the event, query after 7 days from the event
                will not be considered as valid.
                <br />
                5.21) The Rules Which we have added Regarding
                Cheating/Hedging/manipulation of Odds are ONLY for the Ones with
                Wrong Intensions Not for Genuine Clients.
                <br />
                5.22) Penalty Runs - Any Penalty Runs Awarded in the Match (In
                Any Running Fancy or ADV Fancy) Will Not be Counted While
                Settling in our Exchange.
                <br />
                5.23) Player Boundaries Fancy:- We will only consider Direct
                Fours and Sixes hit by BAT.
                <br />
                5.24) Bowler run session rule:- If Bowler Bowl 1.1 over,then
                valid(For Bowler 2 over runs session) If Bowler Bowl 2.1
                over,then valid(For Bowler 3 over runs session) If Bowler Bowl
                3.1 over,then valid(For Bowler 4 over runs session) If Bowler
                Bowl 4.1 over,then valid(For Bowler 5 over runs session) If
                Bowler Bowl 9.1 over,then valid(For Bowler 10 over runs session)
                <br />
                5.25) Any cricket event which is being held behind closed doors
                in that if any players found to be taking advantage of
                groundline in fancy bets in such cases bets can be void after
                the market ends. Company decision to be final.
                <br />
                5.26) Company reserves the right to suspend/void any id/bets if
                the same is found to be illegitimate. For example,In case of
                VPN/robot-use/multiple entry from same IP/ multiple bets at same
                time (Punching) and others. Note: - only winning bets will be
                voided, For example: If we find such entries (above mentioned)
                from any id and their bets are (200000 lay in a 6 over session
                for the rate 40 and 200000 back for the rate of 48) and the
                actual score is 38, bets of 40 lay will be voided and the bets
                for 48 back will be considered valid.
                <br />
                5.27) Odd-even rules:- Incomplete games will be deleted. Over
                reduced or abandoned all bets will be deleted.
                <br />
                5.28) In any circumstances company decision will be final.
                <br />
                5.29) If Users Found Betting In Group's then We Will Void "Only
                The Winning Bets of the Users" and Company Decision Will be Full
                and Final.
                <br />
                5.30) If Exchange resettle any bets or Markets due to wrong
                Result Declaration or any other reason & if the Player has lost
                Money or If Player gets Negative Balance, In this case Company
                will do the Settlement from Agent for the same, all decision
                will be Full And Final, No Arguments will be listened.
                <br />
                5.31) Company reserves the right to void any bets (only winning
                bets) of any event at any point of the match if the company
                believes there is any cheating/wrong doing in that particular
                event by the players (either batsman/bowler)
                <br />
                5.32) Our Exchange Is Trusted and Fair.
                <br />
                <br />
                <b>6. IPL RULES:</b>
                <br />
                6.1) If Over Reduced in Match, we will not count the actual
                scores of the Over Reduced Matches instead we will count the
                Market’s Average Scores.
                <br />
                6.2) If Match is Abandoned, we will not count the actual scores
                of the Abandoned Matches instead we will count the Market’s
                Average Scores.
                <br />
                6.3) Total Fours: - Average 26 Fours will be given if the match
                is abandoned or over reduced.
                <br />
                6.4) Total Sixes: - Average 13 Sixes will be given if the match
                is abandoned or over reduced.
                <br />
                6.5) Total Wide: - Average 8 Wide’s will be given if the match
                is abandoned or over reduced.
                <br />
                6.6) Total Extras: - Average 14 Extras will be given if the
                match is abandoned or over reduced.
                <br />
                6.7) Total No Ball: - Average 1 No Ball will be given if the
                match is abandoned or over reduced.
                <br />
                6.8) Total Duck: - Average 1 Duck will be given if the match is
                abandoned or over reduced.
                <br />
                6.9) Total Fifties: - Average 2 Fifties will be given if the
                match is abandoned or over reduced.
                <br />
                6.10) Total Century: -Average 0 Century will be given if the
                match is abandoned or over reduced.
                <br />
                6.11) Total Run Out: - Average 1 Run Out will be given if the
                match is abandoned or over reduced.
                <br />
                6.12) Total Caught out: - Average 8 Caught Out will be given if
                the match is abandoned or over reduced.
                <br />
                6.13) Total Maiden Over: - Average 0 Maiden Over will be given
                if the match is abandoned or over reduced.
                <br />
                6.14) Total LBW: - Average 1 LBW will be given if the match is
                abandoned or over reduced.
                <br />
                6.15) Total Bowled: - Average 2 Bowled will be given if the
                match is abandoned or over reduced.
                <br />
                6.16) In case IPL Matches Gets Stopped or Interrupted Due to
                "COVID-19" or Any "ACT OF GOD" Reasons, under 45 Matches then
                All IPL 2021 Tournament Fancy Markets will be Voided, but if 45
                or Above Matches are Being Played then we will Settle all the
                Fancy Markets and Also If there are NON-Played Matches Above 45
                then we will settle the markets as per our Markets Average.
                <br />
                6.17) If IPL 2021 gets stopped due to “Covid-19” or Any “Act of
                God” reason then We will wait till 45 days from the day IPL gets
                stopped, and if IPL Matches gets Resumed before 45th day then we
                will continue as Usual Score Countation but if IPL 2021 does not
                resume until 45th day then we will settle the markets according
                to our above-mentioned Markets Averages and In case of Void, We
                will Void the under 45 matches on 45th day from the day IPL 2021
                stopped.
                <br />
                <br />
                <b>7. Casino RULES:</b>
                <br />
                7.1) If Company voided any bet due to wrong rate or technical
                issue in casino games and the Player has lost Money or If Player
                gets Negative Balance, In this case Company will do the
                Settlement from Agent for the same, all decision will be Full
                And Final, No Arguments will be listened.
                <br />
                7.2) Exchange accept maximum bet as per below casino: Evolution:
                2,00,000 INR Pragmatic: 2,00,000 INR Bet Games: 2,00,000 INR
                Vivo: 2,00,000 INR
                <br />
                <br />
                <b>8. Soccer RULES:</b>
                <br />
                8.1) Here comes the VAR verdict. NO GOAL for Ecuador due to an
                offside in the build-up play.
                <br />
                <br />
                <b>9. Lottery RULES:</b>
                <br />
                9.1) Lottery means Last Digit.
                <br />
                9.2) Completed Lottery Will be Settled, Incomplete Lottery Will
                be voided.
                <br />
                9.3) Example :- We will Count Last Digit of particular Lottery
                Market, if in 6 over Market the Score is 42, so we will settle
                that particular market @ 2.
                <br />
                9.4) In case of Rain or If Over Gets Reduced then this Market
                will get Voided.
                <br />
                <br />
                <b>10. Tennis RULES:</b>
                <br />
                10.1) In tennis if First set is not completed and the player is
                injured..and he does not play the game...then the opposite
                player will be given the winner...
              </Text>
            </TabPanel>
            <TabPanel className="text-black" p="0">
              <Text>
                <b>नियम पाठ्यक्रम:</b>
                <br />
                1) स्कोरकार्ड नियम: -
                <br />
                1.1) लाइव स्कोर और अन्य डेटा इस साइट से तृतीय पक्ष फीड से
                स्रोतित किए जाते हैं और समय देरी और/या गलत भी हो सकते हैं। यदि
                आप बेट लगाने के लिए इस डेटा पर आश्रित हैं, तो आप यह अपने खुद के
                जोखिम पर करते हैं। हमारा एक्सचेंज इस डेटा पर आश्रित हानि को
                स्वीकार नहीं करता।
                <br />
                1.2) अगर किसी भी विवाद में फैंसी परिणाम होता है, तो कंपनी का
                निर्णय अंतिम होगा।
                <br />
                2) धोखाधड़ी के नियम: -
                <br />
                2.1) बेटफेयर और फैंसी बाजारों में, यदि कोई भी आधार कमेंट्री या
                आधार लाइन या कोर्टसाइडिंग (बेटिंग के दौरान मैदान में होना) का
                संदेह होता है, तो कंपनी सभी विजयी बेटों को रद्द कर देगी (किसी
                सबूत के बिना)।
                <br />
                3) मैच अनुमान नियम: -
                <br />
                3.1) क्रिकेट: हम बेटफेयर के परिणाम का पालन करेंगे।
                <br />
                3.2) सॉकर: हम बेटफेयर के परिणाम का पालन करेंगे।
                <br />
                3.3) टेनिस: हम बेटफेयर के परिणाम का पालन करेंगे।
                <br />
                3.4) यदि किसी क्लाइंट को किसी भी मैच अनुमान में हेजिंग या
                मानिपुलेशन करते पाया जाता है, तो कंपनी को बेट रद्द करने का
                अधिकार है।
                <br />
                3.5) यदि एक्सचेंज किसी गलत परिणाम घोषणा या किसी अन्य कारण से
                किसी भी बेट या बाजार को पुनः निर्धारित करता है और यदि खिलाड़ी को
                पैसे खो दिए जाते हैं या यदि खिलाड़ी का नकारात्मक शेष प्राप्त
                होता है, तो इस मामले में कंपनी एजेंट से समाधान करेगी, सभी निर्णय
                पूर्ण और अंतिम होंगे, कोई आपत्तियाँ सुनी जाएंगी नहीं।
                <br />
                3.6) कंपनी किसी खिलाड़ी (या बैट्समैन/गेंदबाज) द्वारा उस विशेष
                घटना में किसी भी धोखाधड़ी/गलत क्रियावली मानती है तो संबंधित घटना
                के किसी भी बेटों (केवल विजयी बेटों) को किसी भी समय खेल के दौरान
                रद्द करने का अधिकार सुरक्षित रखती है।
                <br />
                3.7) यदि सुपर ओवर होता है तो सुपर ओवर की मैच अनुमान को विजेता
                टीम के अंतिम परिणाम के घोषणा के बाद सेटल किया जाएगा, लेकिन
                बुकमेकर के मामले में हम विशेष सुपर ओवर बुकमेकर के आधार पर सेटल
                या रद्द करेंगे।
                <br />
                <b>4) बुक-मेकर मार्केट नियम:</b>
                <br />
                4.1) किसी भी कारणवश किसी भी टीम को लाभ या हानि हो रही है हम
                चिंतित नहीं हैं।
                <br />
                4.2) हमारी गलती से गलत टीम चयन के सभी पिछवाड़ा और लेज बेट्स को
                हटा दिया जाएगा।
                <br />
                4.3) गलत दर बेट्स भी रद्द किए गए।
                <br />
                4.4) यदि एक्सचेंज किसी गलत परिणाम की घोषणा या किसी अन्य कारण से
                किसी भी बेट या बाजार को पुनः निर्धारित करता है और यदि खिलाड़ी को
                पैसे खो दिए हैं या यदि खिलाड़ी का नकारात्मक शेष प्राप्त होता है,
                तो इस मामले में कंपनी एजेंट से समाधान करेगी, सभी निर्णय पूर्ण और
                अंतिम होंगे, कोई आपत्तियाँ सुनी जाएंगी नहीं।
                <br />
                4.5) कंपनी खिलाड़ियों (बैट्समैन/गेंदबाज) द्वारा उस विशेष घटना
                में किसी भी धोखाधड़ी/गलत क्रियावली मानती है तो किसी भी समय खेल
                के दौरान किसी भी बेट (केवल विजयी बेटों) को रद्द करने का अधिकार
                सुरक्षित रखती है।
                <br />
                <b>5) फैंसी:-</b>
                <br />
                5.1) एडवांस फैंसी: - एडवांस सत्र, खिलाड़ी के रन और सभी फैंसी
                बेट्स केवल 20 ओवर/50 ओवर पूरे मैच हर तरफ वैध हैं।
                <br />
                5.2) सभी एडवांस फैंसी बेट्स मार्केट 60 मिनट पूर्व बंद किए जाएंगे
                और समाप्त हो जाएंगे।
                <br />
                5.3) एडवांस सत्र और लम्बी 1st inning केवल वैध है।
                <br />
                5.4) कुल मैच खेलने वाले ओवर एडवांस: - हम इस मार्केट को पूरे मैच
                के पूरा होने के बाद सेटल करेंगे।
                <br />
                5.5) मानदंड: - हम दोनों इनिंग के लिए केवल गोलाईयां गिनेंगे जब
                सेटल किया जाता है (उदा। - यदि पहले बैटिंग टीम 17.3, 18.4 या 19.5
                पर सभी आउट होती है तो हम ऐसे ओवर को 17, 18 और 19 के रूप में
                गिनेंगे, और यदि मैच 17.2, 18.3 या 19.3 ओवर में समाप्त होता है तो
                हम ऐसे ओवर को 17, 18 और 19 के रूप में गिनेंगे, और यह दोनों इनिंग
                के लिए समान रहेगा)।
                <br />
                5.6) बारिश के मामले में या यदि ओवर कम होता है तो यह मार्केट रद्द
                हो जाएगा।
                <br />
                5.7) मैच के पूरे होने के बाद हम इस मार्केट को सेटल करेंगे (या
                रद्द करेंगे) जब कि बुकमेकर के मामले में हम किसी विशेष सुपर ओवर
                बुकमेकर के आधार पर सेटल या रद्द करेंगे।
                <br />
                5.8) सभी सत्र/फैंसी बेट्स को पूरा होने के बाद और सेटल कर दिया
                जाने के बाद कोई पलटाव नहीं होगा चाहे मैच टाई हो या अबंदन हो।
                <br />
                5.9) सत्र/फैंसी बेट्स के नियमों के अंतर्गत अगर किसी कारणवश किसी
                बाजार को सस्पेंड किया जाता है और कभी फिर से नहीं शुरू होता है तो
                सभी पिछले बेट्स वैध रहेंगे और हार/जीत बेट्स बन जाएंगे।
                <br />
                5.10) अधूरे सत्र/फैंसी बेट्स को रद्द किया जाएगा, लेकिन पूर्ण
                सत्र को सेटल किया जाएगा।
                <br />
                5.11) चल रहे मैच के रद्द होने के मामले में, यदि सत्र पूरा हो
                जाता है, तो भी इसे सेटल किया जाएगा। खिलाड़ी रन/विकेट की
                गिरावट/विकेट गए बॉल/खिलाड़ी द्वारा कितने गेंद खेले जाते हैं वह
                भी वहां पर सेटल हो जाएगा जहां मैच वर्षा के कारण बंद होता है
                (डी/एल) , रद्द, त्यागी, नतीजा नहीं होने के कारण।
                <br />
                5.12) यदि कोई खिलाड़ी चोटिल हो जाता है और आप अपनी बेट रख चुके
                हैं और एक गेंद पूरी होते ही तब तक सभी बेटिंग वैध होती है।
                <br />
                5.13) यदि किसी सॉफ्टवेयर में तकनीकी खराबी होती है, तो हम किसी भी
                हानि के लिए जिम्मेदार नहीं होंगे।
                <br />
                5.14) यदि हमारे पास बिजली आउट हो जाती है या हमारे इंटरनेट
                कनेक्शन में समस्या होती है और सत्र/फैंसी मार्केट सस्पेंड नहीं
                होता है तो हमारा निर्णय पूर्ण और अंतिम होगा।
                <br />
                5.15) हम किसी भी कारणवश किसी भी टीम को लाभ या हानि हो रही है हम
                चिंतित नहीं हैं।
                <br />
                5.16) यदि कोई खिलाड़ी अबंदन कर दिया है तो सभी रन/विकेट सेटल हो
                जाएंगे।
                <br />
                <b>6. आईपीएल नियम:</b>
                <br />
                6.1) मैच में ओवर कम होने पर, हम असली स्कोरों का मापन नहीं
                करेंगे, बजाय इसके हम बाजार के औसत स्कोर का मापन करेंगे।
                <br />
                6.2) अगर मैच छोड़ दिया जाता है, तो हम असली स्कोरों का मापन नहीं
                करेंगे, बजाय इसके हम बाजार के औसत स्कोर का मापन करेंगे।
                <br />
                6.3) कुल चौकों: - औसत 26 चौकों दिए जाएंगे अगर मैच छोड़ा गया हो
                या ओवर कम हो गया हो।
                <br />
                6.4) कुल छक्कों: - औसत 13 छक्के दिए जाएंगे अगर मैच छोड़ा गया हो
                या ओवर कम हो गया हो।
                <br />
                6.5) कुल वाइड: - औसत 8 वाइड्स दिए जाएंगे अगर मैच छोड़ा गया हो या
                ओवर कम हो गया हो।
                <br />
                6.6) कुल एक्स्ट्रा: - औसत 14 एक्स्ट्रा दिए जाएंगे अगर मैच छोड़ा
                गया हो या ओवर कम हो गया हो।
                <br />
                6.7) कुल नो-बॉल: - औसत 1 नो-बॉल दिया जाएगा अगर मैच छोड़ा गया हो
                या ओवर कम हो गया हो।
                <br />
                6.8) कुल डक: - औसत 1 डक दिया जाएगा अगर मैच छोड़ा गया हो या ओवर
                कम हो गया हो।
                <br />
                6.9) कुल फिफ्टीज: - औसत 2 फिफ्टीज दिए जाएंगे अगर मैच छोड़ा गया
                हो या ओवर कम हो गया हो।
                <br />
                6.10) कुल सेंचुरी: - औसत 0 सेंचुरी दिए जाएंगे अगर मैच छोड़ा गया
                हो या ओवर कम हो गया हो।
                <br />
                6.11) कुल रन आउट: - औसत 1 रन आउट दिया जाएगा अगर मैच छोड़ा गया हो
                या ओवर कम हो गया हो।
                <br />
                6.12) कुल कॉट आउट: - औसत 8 कॉट आउट दिए जाएंगे अगर मैच छोड़ा गया
                हो या ओवर कम हो गया हो।
                <br />
                6.13) कुल मेडन ओवर: - औसत 0 मेडन ओवर दिया जाएगा अगर मैच छोड़ा
                गया हो या ओवर कम हो गया हो।
                <br />
                6.14) कुल एलबीडब्ल्यू: - औसत 1 एलबीडब्ल्यू दिया जाएगा अगर मैच
                छोड़ा गया हो या ओवर कम हो गया हो।
                <br />
                6.15) कुल बोल्ड: - औसत 2 बोल्ड दिए जाएंगे अगर मैच छोड़ा गया हो
                या ओवर कम हो गया हो।
                <br />
                6.16) अगर आईपीएल मैच को "कोविड-19" या किसी "भगवान की कृपा" के
                कारण रोका गया है, तो 45 मैचों से कम हैं तो सभी आईपीएल 2021
                टूर्नामेंट फैंसी मार्केट रद्द किए जाएंगे, लेकिन यदि 45 या अधिक
                मैच खेले जा रहे हैं तो हम सभी फैंसी मार्केट को सेटल करेंगे और
                यदि 45 मैच से अधिक नहीं खेले जाते हैं तो हम अपनी बाजारों की औसत
                के अनुसार बाजारों को सेटल करेंगे।
                <br />
                6.17) यदि आईपीएल 2021 "कोविड-19" या किसी "भगवान की कृपा" के कारण
                बंद हो जाती है, तो हम 45 दिन तक इंतजार करेंगे जब तक कि आईपीएल
                मैच फिर से शुरू नहीं हो जाते, और अगर आईपीएल 2021 45 वें दिन से
                पहले ही फिर से शुरू हो जाती है तो हम जैसे ही असामान्य घटनाओं को
                सेटल करेंगे। कृपया ध्यान दें: - 45 दिन के बाद आईपीएल 2021 बंद
                होने के दिन से अगर आईपीएल मैच फिर से शुरू नहीं होती है तो हम 45
                दिन के बाद जितने भी मैच अधूरे रह जाएंगे, हम उन बाजारों को हटा
                देंगे।
                <br />
                <b>7. कैसीनो नियम:</b>
                <br />
                7.1) अगर कंपनी किसी गलत दर या कैसीनो खेलों में तकनीकी समस्या के
                कारण किसी भी बेट को रद्द करती है और खिलाड़ी को पैसे हार गए हैं
                या यदि खिलाड़ी को नकारात्मक शेष मिलता है, तो इस मामले में कंपनी
                से एजेंट के द्वारा बिल संरचना की जाएगी, सभी निर्णय पूरे और अंतिम
                होंगे, कोई तर्क नहीं सुना जाएगा।
                <br />
                7.2) एक्सचेंज द्वारा अधिकतम बेट की स्वीकृति कैसीनो: इवोल्यूशन:
                2,00,000 इंडियन रुपये प्राग्मेटिक: 2,00,000 इंडियन रुपये बेट
                गेम्स: 2,00,000 इंडियन रुपये विवो: 2,00,000 इंडियन रुपये
                <br />
                <br />
                <b>8. फुटबॉल नियम:</b>
                <br />
                8.1) यहां वीएआर निर्णय आता है। ईक्वेडोर के लिए बिल्ड-अप प्ले में
                ऑफसाइड के कारण कोई गोल नहीं है।
                <br />
                <br />
                <b>9. लॉटरी नियम:</b>
                <br />
                9.1) लॉटरी का मतलब लास्ट डिजिट होता है।
                <br />
                9.2) पूरी लॉटरी को सेटल किया जाएगा, अपूर्ण लॉटरी को रद्द किया
                जाएगा।
                <br />
                9.3) उदाहरण: - हम किसी विशेष लॉटरी मार्केट के अंतिम अंक को गिनते
                हैं, अगर 6 ओवर के मार्केट में स्कोर 42 है, तो हम उस विशेष
                मार्केट को 2 पर सेट करेंगे।
                <br />
                9.4) बारिश के मामले में या अगर ओवर कम हो गया है तो यह मार्केट
                रद्द हो जाएगा।
                <br />
                <br />
                <b>10. टेनिस नियम:</b>
                <br />
                10.1) टेनिस में पहला सेट पूरा नहीं होने पर और खिलाड़ी चोटिल है
                ... और वह खेल नहीं खेलता ... तो विपरीत खिलाड़ी को विजेता घोषित
                किया जाएगा।
              </Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
