import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoLastTenResultsAPI,
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import Chart from "react-apexcharts";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
export default function Baccart2() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.baccarat2Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const toast = useToast();
  const [lastTenResult, setLastTenResult] = useState({});
  const dispatch = useDispatch();
  const gameName = "BACCARAT2";
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    if (isLocked) return;
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  const getCasinoLastResults = async () => {
    const { data } = await getCasinoLastTenResultsAPI(gameName, userData.token);
    setLastTenResult(data);
  };

  useEffect(() => {
    dispatch(setGameKey("baccarat2"));
  }, [dispatch]);

  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  useEffect(() => {
    const interval = setInterval(() => {
      getCasinoLastResults();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const getPlExposure = async (id) => {
    const { response, code } = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (code == 200) {
      setPlExposure(response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box
          mb="2"
          pos="relative"
          className="casino-video"
        >
          <div class="market-title">Baccarat 2</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3033`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className="rules-body">
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/rules/baccarat2-rules.jpg"
                  className="rules-body"
                />
              </div>
            }
          />
        </Box>
        <Flex flexDirection={{ md: "row", base: "column" }}>
          <Box w={{ base: "100%" }} className="baccarat-bets-odds">
            <div className="baccarat-odds tether-abutted tether-abutted-top tether-target-attached-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
              <div
                onClick={() => setEvent("Score 1-4")}
                className="baccarat-odd-block"
              >
                <div
                  className={`baccarat-odd-name ${isLocked ? "suspended" : ""}`}
                  style={{ color: "black" }}
                >
                  Score 1-4
                </div>
                <div className="baccarat-odd-val d-none">
                  {getData("Score 1-4").back1}
                </div>
                {getExposureForEntity("Score 1-4")?.exposure}
              </div>
              <div
                onClick={() => setEvent("Score 5-6")}
                className="baccarat-odd-block"
              >
                <div
                  className={`baccarat-odd-name ${isLocked ? "suspended" : ""}`}
                  style={{ color: "black" }}
                >
                  Score 5-6
                </div>
                <div className="baccarat-odd-val d-none">
                  {getData("Score 5-6").back1}
                </div>
                {getExposureForEntity("Score 5-6")?.exposure}
              </div>
              <div
                onClick={() => setEvent("Score 7")}
                className="baccarat-odd-block"
              >
                <div
                  className={`baccarat-odd-name ${isLocked ? "suspended" : ""}`}
                  style={{ color: "black" }}
                >
                  Score 7
                </div>
                <div className="baccarat-odd-val d-none">
                  {getData("Score 7").back1}
                </div>
                {getExposureForEntity("Score 7")?.exposure}
              </div>
              <div
                onClick={() => setEvent("Score 8")}
                className="baccarat-odd-block"
              >
                <div
                  className={`baccarat-odd-name ${isLocked ? "suspended" : ""}`}
                  style={{ color: "black" }}
                >
                  Score 8
                </div>
                <div className="baccarat-odd-val d-none">
                  {getData("Score 8").back1}
                </div>
                {getExposureForEntity("Score 8")?.exposure}
              </div>
              <div
                onClick={() => setEvent("Score 9")}
                className="baccarat-odd-block"
              >
                <div
                  className={`baccarat-odd-name ${isLocked ? "suspended" : ""}`}
                  style={{ color: "black" }}
                >
                  Score 9
                </div>
                <div className="baccarat-odd-val d-none">
                  {getData("Score 9").back1}
                </div>
                {getExposureForEntity("Score 9")?.exposure}
              </div>
            </div>
            <div className="baccarat-bets">
              <div
                onClick={() => setEvent("Player Pair")}
                className="player-pair"
              >
                <div
                  className={`baccarat-bets-name ${
                    isLocked ? "suspended" : ""
                  }`}
                >
                  <div>Player Pair</div>
                  <div className="mb-0">
                    {parseInt(getData("Player Pair").back1)}:1
                  </div>
                </div>
                <div className="baccarat-bets-val d-none">
                  {getData("Player Pair").back1}
                </div>
                {getExposureForEntity("Player Pair")?.exposure}
              </div>
              <div className="player">
                <div
                  onClick={() => setEvent("Player")}
                  className={`baccarat-bets-name ${
                    isLocked ? "suspended" : ""
                  }`}
                >
                  <div>Player {parseInt(getData("Player").back1)}:1</div>
                  <div className="mb-0">
                    <Flex>
                      {odds?.cards &&
                        odds?.cards[0]?.map((item) => (
                          <span>
                            <Image
                              style={{
                                width: "30px",
                                height: "38px",
                              }}
                              src={ASSETS_URL + item + ".png"}
                            />
                          </span>
                        ))}
                    </Flex>
                  </div>
                </div>
                <div className="baccarat-bets-val d-none">
                  {getData("Player").back1}
                </div>
              </div>
              <div onClick={() => setEvent("Tie")} className="tie">
                <div
                  className={`baccarat-bets-name ${
                    isLocked ? "suspended" : ""
                  }`}
                >
                  <div>Tie</div>
                  <div className="mb-0">{parseInt(getData("Tie").back1)}:1</div>
                </div>
                <div className="baccarat-bets-val d-none">
                  {getData("Tie").back1}
                </div>
              </div>
              <div onClick={() => setEvent("Banker")} className="banker">
                <div
                  className={`baccarat-bets-name ${
                    isLocked ? "suspended" : ""
                  }`}
                >
                  <div>Banker {parseInt(getData("Banker").back1)}:1</div>
                  <div className="mb-0">
                    <Flex>
                      {odds?.cards &&
                        odds?.cards[1]?.map((item) => (
                          <span>
                            <Image
                              style={{
                                width: "30px",
                                height: "38px",
                              }}
                              src={ASSETS_URL + item + ".png"}
                            />
                          </span>
                        ))}
                    </Flex>
                  </div>
                </div>
                <div className="baccarat-bets-val d-none">
                  {getData("Banker").back1}
                </div>
                {getExposureForEntity("Banker")?.exposure}
              </div>
              <div
                onClick={() => setEvent("Banker Pair")}
                className="banker-pair"
              >
                <div
                  className={`baccarat-bets-name ${
                    isLocked ? "suspended" : ""
                  }`}
                >
                  <div>Banker Pair</div>
                  <div className="mb-0">
                    {parseInt(getData("Banker Pair").back1)}:1
                  </div>
                </div>
                <div className="baccarat-bets-val d-none">
                  {getData("Banker Pair").back1}
                </div>
                {getExposureForEntity("Banker Pair")?.exposure}
              </div>
            </div>
          </Box>
          {lastTenResult?.baccaratStatistics && (
            <Box
              flexDirection="column"
              display={{ base: "flex", md: "block" }}
              w={{ md: "35%", base: "100%" }}
            >
              <Text color="gray" fontSize="sm" fontWeight="bold" ml="20px">
                STATISTICS
              </Text>
              <Chart
                options={{
                  chart: {
                    width: 380,
                    type: "pie",
                  },
                  labels: lastTenResult?.baccaratStatistics?.map(
                    (item) => item.mainWinner
                  ),
                  responsive: [
                    {
                      breakpoint: 780,
                      options: {
                        chart: {
                          width: "80%",
                        },
                        legend: {
                          position: "right",
                          color: "white",
                        },
                      },
                    },
                  ],
                }}
                series={lastTenResult?.baccaratStatistics?.map(
                  (item) => item.count
                )}
                type="pie"
                height="auto"
              />
            </Box>
          )}
        </Flex>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
