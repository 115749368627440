import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import ExportTabs from "../home/exportTabs";
import { dashboardBannerAPI, getSignedUrlAPI } from "../../service/AuthService";

import { useDispatch, useSelector } from "react-redux";
import "./dashboard.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WatchLiveIcon from "../../components/Icons/WatchLive";
import { getCricketInPlayMatches, getCricketLiveMatches } from "../../service/cricket";
import { getTennisInPlayMatches, getTennisSoccerLiveMatches } from "../../service/soccer&tennis";
import { getInplayMatches } from "../../redux/features/gameSlice";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDataLoaded } = useSelector((state) => state.gameData);
  const [images, setImages] = useState([]);
  const [homeImages, setHomeImages] = useState([]);
  const { sportName } = useParams();

  const getBannerImages = async () => {
    try {
      const res = await dashboardBannerAPI();
      if (res.status === 200) {
        let data = res.data.response;
        const bannerPromises = data.map(async (item) => {
          const signedUrlRes = await getSignedUrlAPI(item.bannerUrl);
          if (signedUrlRes?.data?.code === 200) {
            item.bannerUrl = signedUrlRes.data.response;
            return signedUrlRes.data.response;
          }
          return null;
        });

        const newHomeImages = await Promise.all(bannerPromises);
        setHomeImages((prevHomeImages) => [...prevHomeImages, ...newHomeImages]);
        setImages(data.map((item) => item.bannerUrl));
      }
    } catch (error) {
      console.error("Error fetching banner images:", error);
    }
  };

  useEffect(() => {
    getBannerImages();
  }, []);

  const handleCricketClick = () => {
    navigate("/games/cricket");
  };
  const handleSoccerClick = () => {
    navigate("/games/soccer");
  };
  const handleTennisClick = () => {
    navigate("/games/tennis");
  };

  const inPlay = location.pathname.includes("in-play");

  const getCricketLiveMatchesHandler = async () => {
    try {
      const res = await getCricketLiveMatches();
      if (res.status === 200) {
        dispatch({
          type: 'gameData/setCricketGames',
          payload: res.data.response,
        });
      }
    } catch (error) {
      console.error("Error fetching cricket live matches:", error);
    }
  };

  const getTennisSoccerLiveMatchesHandler = async () => {
    try {
      const res = await getTennisSoccerLiveMatches('tennis');
      if (res.status === 200) {
        if (sportName === 'soccer') {
          dispatch({
            type: 'gameData/setSoccerGames',
            payload: res?.data?.response?.soccerAllMatchesResponseDTOS,
          });
        } else {
          dispatch({
            type: 'gameData/setTennisGames',
            payload: res?.data?.response?.tennisAllMatchesResponseDTOS,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching tennis or soccer live matches:", error);
    }
  };

  useEffect(() => {
      getCricketLiveMatchesHandler();
      getTennisSoccerLiveMatchesHandler();
  }, []);


  const getCricketInPlayMatchesHandler = async () => {
    const res = await getCricketInPlayMatches();

    if (res.status === 200) {
      dispatch({
        type: "gameData/setCricketGames",
        payload: res.data.response,
      });
    }
  };

  const getTennisInPlayMatchesHandler = async () => {
    const res = await getTennisInPlayMatches();

    if (res.status === 200) {
      console.log(res, "resDataTennisSoccer");
      if (sportName === "tennis") {
        dispatch({
          type: "gameData/setTennisGames",
          payload: res?.data?.response?.tennisAllMatchesResponseDTOS,
        });
      } else {
        dispatch({
          type: "gameData/setSoccerGames",
          payload: res?.data?.response?.soccerAllMatchesResponseDTOS,
        });
      }
    }
  };

  useEffect(() => {
      getCricketInPlayMatchesHandler();
      getTennisInPlayMatchesHandler();
  }, []);

  return (
    <>
      <Box padding={"5px"}>
        {!inPlay ? (
          <Box className="dash-banner"></Box>
        ) : (
          <Box>
            <Text
              height={"23px"}
              bg="#27A6C3"
              color="#fff !important"
              paddingLeft="10px"
              fontWeight={600}
            >
              In-Play
            </Text>
            <Flex
              p={"10px"}
              bg="#fff"
              mb="15px"
              gap="40px"
              className="in-play-games"
              overflowX="scroll"
            >
              <Flex flexDir="column" align="center" justify="center">
                <WatchLiveIcon />
                <Text fontSize="14px" fontWeight={600} mt="5px" flexDir="row !important">
                  Watch Live
                </Text>
              </Flex>
              <Flex flexDir="column" align="center" justify="center">
                <i className="icon joystick"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  All Games
                </Text>
              </Flex>
              <Flex
                cursor="pointer"
                flexDir="column"
                align="center"
                justify="center"
                onClick={handleCricketClick}
              >
                <i className="icon cricket-icon"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  Cricket
                </Text>
              </Flex>
              <Flex
                cursor="pointer"
                flexDir="column"
                align="center"
                justify="center"
                onClick={handleSoccerClick}
              >
                <i className="icon soccer-icon"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  Soccer
                </Text>
              </Flex>
              <Flex
                cursor="pointer"
                flexDir="column"
                align="center"
                justify="center"
                onClick={handleTennisClick}
              >
                <i className="icon tennis-icon"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  Tennis
                </Text>
              </Flex>
              <Flex flexDir="column" align="center" justify="center">
                <i className="icon hourse-icon"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  Horse
                </Text>
              </Flex>
              <Flex flexDir="column" align="center" justify="center">
                <i className="icon grayHound-icon"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  Gray Hound
                </Text>
              </Flex>
              <Flex flexDir="column" align="center" justify="center">
                <i className="icon election-icon"></i>
                <Text fontSize="14px" fontWeight={600} mt="5px">
                  Election
                </Text>
              </Flex>
            </Flex>
          </Box>
        )}

        {!isDataLoaded && (
          <div className="loader-box">
            <Spinner />
          </div>
        )}
        <>
          <ExportTabs />
        </>
      </Box>
    </>
  );
};

export default Dashboard;
