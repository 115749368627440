import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Spacer,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import Popover from "../../../components/Popover";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function CasinoWar() {
  const location = useLocation();
  const [showRules, setShowRules] = useState(false);
  const dispatch = useDispatch();
  const odds_ = useSelector((state) => state.gameData.casinoWarOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [plExposure, setPlExposure] = useState(null);
  const gameName = "CASINO_WAR";
  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : "sm"}
      my="2"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="pattiButton"
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = useCallback(
    (id) => {
      let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
      return data ? data : {};
    },
    [odds?.oddDetailsDTOS]
  );

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  useEffect(() => {
    dispatch(setGameKey("casinoWar"));
  }, [dispatch]);

  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );
      return data ? data : null;
    }
  };
  const toggleRules = () => {
    setShowRules(!showRules);
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box w={{ base: "100%", sm: "100%", lg: "70%" }} mr="1">
        <Box
          mb="2"
          pos="relative"
          className="casino-video"
        >
          {odds?.cards && (
            <CardDrawer
              multiLevel={true}
              labels={["DEALER"]}
              cards={[odds?.cards[6]]}
            />
          )}
          <div className="market-title">Casino War</div>
          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3038`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}casino war-rules.jpg`} />
        </Box>
        <Grid bgColor={"#eee"}>
          <Grid
            ml="35%"
            display={{ base: "none", md: "grid" }}
            templateColumns="repeat(7,1fr)"
            className="casino-war-grid-7-1"
          >
            <GridItem></GridItem>
            {odds?.cards?.slice(0, 6).map((item, index) => (
              <GridItem
                display="flex"
                alignItems="center"
                textAlign="center"
                flexDirection="column"
                justifyContent="center"
                className="war-title"
              >
                <Image
                  h="30px"
                  w="25px"
                  my={1}
                  src={ASSETS_URL + item + ".png"}
                />
                <Text>{index + 1}</Text>
              </GridItem>
            ))}
          </Grid>
          <div className="table-responsive main-market">
            <table className="table coupon-table table table-bordered ">
              <tbody>
                <tr>
                  <td className="box-4">
                    <b>Winner</b>
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                      <td
                        key={`Winner ${item}`}
                        onClick={() => setEvent(`Winner ${item}`)}
                        className={`box-1 back ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                      >
                        <button>
                          <span className="odd d-block">
                            {getData(`Winner ${item}`).back1}
                          </span>{" "}
                          <span className="d-block">
                            {getExposureForEntity(`Winner ${item}`)?.exposure}
                          </span>
                        </button>
                      </td>
                    ))}
                  </td>
                </tr>

                <tr>
                  <td className="box-4">
                    <b>Black</b>{" "}
                    <span className="card-icons" style={{ marginTop: "10px" }}>
                      <span className="card-black">
                        <img
                          className="dtl-img"
                          src={require(`../../../assets/img/casino/spade.png`)}
                          alt=""
                        />
                      </span>
                    </span>
                    <span className="card-icons" style={{ marginTop: "10px" }}>
                      <span className="card-black">
                        <img
                          className="dtl-img"
                          src={require(`../../../assets/img/casino/club.png`)}
                          alt=""
                        />
                      </span>
                    </span>
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Black ${item}`}
                          onClick={() => setEvent(`Black ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Black ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Black ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4">
                    <b>Red</b>{" "}
                    <span className="card-icons" style={{ marginTop: "10px" }}>
                      <span className="card-red">
                        <img
                          className="dtl-img"
                          src={require(`../../../assets/img/casino/heart.png`)}
                          alt=""
                        />
                      </span>
                    </span>{" "}
                    <span className="card-icons" style={{ marginTop: "10px" }}>
                      <span className="card-red">
                        {" "}
                        <img
                          className="dtl-img"
                          src={require(`../../../assets/img/casino/diamond.png`)}
                          alt=""
                        />
                      </span>
                    </span>
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Red ${item}`}
                          onClick={() => setEvent(`Red ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Red ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Red ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4">
                    <b>Odd</b>
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Odd ${item}`}
                          onClick={() => setEvent(`Odd ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Odd ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Odd ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4">
                    <b>Even</b>
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Even ${item}`}
                          onClick={() => setEvent(`Even ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Even ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Even ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4 card-type-icon">
                    <img
                      className="card-icons"
                      src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/cards/spade.png"
                    />
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Spade ${item}`}
                          onClick={() => setEvent(`Spade ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Spade ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Spade ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4 card-type-icon">
                    <img
                      className="card-icons"
                      src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/cards/club.png"
                    />
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Club ${item}`}
                          onClick={() => setEvent(`Club ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Club ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Club ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4 card-type-icon">
                    <img
                      className="card-icons"
                      src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/cards/heart.png"
                    />
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Heart ${item}`}
                          onClick={() => setEvent(`Heart ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Heart ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {getExposureForEntity(`Heart ${item}`)?.exposure}
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="box-4 card-type-icon">
                    <img
                      className="card-icons"
                      src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/cards/diamond.png"
                    />
                    <div className="info-block float-right">
                      <div className="text-right">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 25000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <td
                          key={`Diamond ${item}`}
                          onClick={() => setEvent(`Diamond ${item}`)}
                          className={`box-1 back ${
                            isLocked ? "suspendedtd" : ""
                          }`}
                        >
                          <button>
                            <span className="odd d-block">
                              {getData(`Diamond ${item}`).back1}
                            </span>{" "}
                            <span className="d-block">
                              {
                                getExposureForEntity(`Diamond ${item}`)
                                  ?.exposure
                              }
                            </span>
                          </button>
                        </td>
                      ))}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
