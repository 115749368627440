import React, { useEffect } from "react";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Live from "../../components/live";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import OddsBox from "../game/Ui/OddsBox";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import "./exportTabs";
import { getCricketInPlayMatches } from "../../service/cricket";
import { getTennisInPlayMatches } from "../../service/soccer&tennis";
import { getInplayMatches } from "../../redux/features/gameSlice";

export default function TabsContent({ id, bg, icon }) {
  const dispatch = useDispatch();
  const data_ = useSelector((state) => state.gameData[id + "Games"]);
  const data = useMemo(() => data_, [data_]);
  const { sportName } = useParams();

  const naviagte = useNavigate();
  const calculateTime = (time) => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let matchDate = new Date(time);
    let payload = {
      string: "Today",
      hours: matchDate.getHours(),
      minutes: matchDate.getMinutes(),
    };
    if (matchDate.getDate() == today.getDate()) {
      payload = {
        string: "Today",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    if (matchDate.getDate() == yesterday.getDate()) {
      payload = {
        string: "Yesterday",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    if (matchDate.getDate() == tomorrow.getDate()) {
      payload = {
        string: "Tomorrow",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    return payload;
  };

  const navigateToGame = (gameEvent) => {
    console.log(gameEvent, "gameEvent");
    if (id == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(
        `/game/${id}/${gameEvent[id + "Id"]}?gameId=${gameEvent.gameId || gameEvent.eventId
        }`,
        {
          state: { gameEvent },
        }
      );
    }
  };

  useEffect(() => {
    if (!data || (data && data.length === 0)) {
      localStorage.setItem("no-data", true);
    } else {
      dispatch({
        type: "gameData/setIsDataLoaded",
        payload: true,
      });
      localStorage.removeItem("no-data");
    }
  }, [data]);

  return (
    <>
      <Box display={{ base: "block", md: "none" }}>
        <Flex
          w={"100%"}
          color="#fff !important"
          fontSize={14}
          fontWeight={600}
          background="#18ADC5"
          padding={"0 11px"}
          height={"23px"}
          textTransform="capitalize"
          align="center"
        >
          {" "}
          {id}
        </Flex>

        <TableContainer>
          {data ? (
            <>
              <Box display={{ base: "block", md: "none" }}>
                <div className='game-listing-container'>
                  {isEmpty(data) && (
                    <Text mt={3} className='norecords'>
                      No Data Found
                    </Text>
                  )}
                  <div>
                    <div className='cricket-content'>
                      {Array.isArray(data) &&
                        data.map((gameEvent, index) => (
                          <a
                            href='#'
                            onClick={() => navigateToGame(gameEvent)}
                            className='text-dark'
                          >
                            <div className='game-list pt-1 pb-1 container-fluid'>
                              <div className='row row5'>
                                <div className='col-8'>
                                  <p className='mb-0 game-name' >
                                    <div style={{ display: 'flex', marginBottom: "-15px", marginTop: "10px" }} >
                                      <strong> {gameEvent.eventName}</strong>
                                      {" "}
                                      {gameEvent.inPlay && (
                                        <Text className="live-text-blue">Live Now</Text>
                                      )}
                                    </div>
                                    <br />
                                    {/* </p>
                                <p className='mb-0' style={{ fontSize: 12 }}> */}
                                    {' '}
                                    {moment(gameEvent.eventTime).format(
                                      'MMM DD YYYY'
                                    )}{' '}
                                    {moment(gameEvent.eventTime).format(
                                      'hh:mm A zz'
                                    )}{' '}
                                  </p>
                                </div>
                                {/* <div className='col-4 text-right'>
                                <div className='game-icons'>
                                  <span className='game-icon'>
                                    <span className='active-icon'></span>
                                  </span>{' '}
                                  <span className='game-icon'>
                                    <i className='fas fa-tv'></i>
                                  </span>{' '}
                                  <span className='game-icon'>
                                    <img src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/ic_fancy.png' />
                                  </span>{' '}
                                  <span className='game-icon vir-icon'>
                                    <img src='https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/game-icon.svg' />
                                  </span>
                                </div>
                              </div> */}
                              </div>
                              <div className='row row5'>
                                <div className='col-12'>
                                  <div className='text-center game-col game-home'>
                                    <b>1</b>
                                  </div>
                                  <div className='text-center game-col game-home'>
                                    <b>X</b>
                                  </div>
                                  <div className='text-center game-col game-home'>
                                    <b>2</b>
                                  </div>
                                </div>
                              </div>
                              <div className='row row5'>
                                <div className='col-12'>
                                  <div className='text-center game-col game-home d-flex'>
                                    <OddsBox
                                      match={gameEvent}
                                      oddsKey='back1'
                                      back={true}
                                      home={true}
                                      index={index}
                                    />
                                    <OddsBox
                                      match={gameEvent}
                                      oddsKey='lay1'
                                      back={false}
                                      home={true}
                                      index={index}
                                    />
                                  </div>{' '}
                                  <div className='text-center game-col game-home d-flex'>
                                    <OddsBox
                                      match={gameEvent}
                                      oddsKey='back11'
                                      back={true}
                                      home={true}
                                      index={index}
                                    />
                                    <OddsBox
                                      match={gameEvent}
                                      oddsKey='lay11'
                                      back={false}
                                      home={true}
                                      index={index}
                                    />
                                  </div>{' '}
                                  <div className='text-center game-col game-home d-flex'>
                                    <OddsBox
                                      match={gameEvent}
                                      oddsKey='back12'
                                      back={true}
                                      home={true}
                                      index={index}
                                    />
                                    <OddsBox
                                      match={gameEvent}
                                      oddsKey='lay12'
                                      back={false}
                                      home={true}
                                      index={index}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </Box>
            </>
          ) : (
            <Text mt={3} className="norecords">
              No Data Found
              {console.log("data not found 111")}
            </Text>
          )}
        </TableContainer>
      </Box>
      <Box display={{ base: "none", md: "block" }}>
        <Flex
          w={"100%"}
          color="#fff !important"
          fontSize={14}
          fontWeight={600}
          background="#18ADC5"
          padding={"0 11px"}
          height={"23px"}
          textTransform="capitalize"
          align="center"
        >
          {" "}
          {id}
        </Flex>

        <TableContainer>
          {data ? (
            <>
              <Table
                variant="simple"
                style={{ color: "#aaafb5" }}
                colorScheme="whiteAlpha"
                size="sm"
              >
                <Thead className={id}>
                  <Tr>
                    <Th
                      padding="10px"
                      w="55%"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      {/* <Text textColor="white">{id}</Text> */}
                    </Th>
                    <Th w="15%">
                      <Text
                        style={{
                          fontWeight: "800",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        1
                      </Text>
                    </Th>
                    <Th w="15%">
                      <Text
                        style={{
                          fontWeight: "800",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        X
                      </Text>
                    </Th>
                    <Th w="15%">
                      <Text
                        style={{
                          fontWeight: "800",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        2
                      </Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(data)
                    ? data.map((gameEvent, index) => (
                      <Tr key={index}>
                        <Td
                          cursor="pointer"
                          onClick={() => navigateToGame(gameEvent)}
                          display="flex"
                          alignItems="center"
                        >
                          {/* <Text
                      fontSize="sm"
                      fontWeight="bold"
                      p="1"
                      mr="5px"
                      textColor="grey.300"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className="time"
                    >
                      {gameEvent.inPlay ? (
                        <Box>
                          <Live />
                        </Box>
                      ) : (
                        `${moment(
                          gameEvent.eventTime
                            ? gameEvent.eventTime
                            : gameEvent.eventDate
                        ).calendar(moment().startOf("day"))}`
                      )}
                    </Text> */}
                          <Text
                            mr="1"
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {gameEvent.eventName} <br />
                            <span>
                              {gameEvent.seriesName
                                ? `(${gameEvent.seriesName})`
                                : ""}
                            </span>
                          </Text>{" "}
                          /
                          <Text ml={1} style={{ fontWeight: "bold" }}>
                            {/* {moment(
                        gameEvent.eventTime
                          ? gameEvent.eventTime
                          : gameEvent.eventDate
                      ).calendar(moment().startOf("day"))} */}
                            {moment(gameEvent.eventTime).format(
                              "MMM DD YYYY"
                            )}{" "}
                            {moment(gameEvent.eventTime).format("hh:mm A zz")}
                          </Text>{" "}
                          {gameEvent.inPlay && (
                            <Text className="live-text-blue">Live Now</Text>
                          )}
                        </Td>
                        <Td>
                          <Box display="flex" flexDirection="row">
                            <OddsBox
                              match={gameEvent}
                              oddsKey="back1"
                              back={true}
                              home={true}
                              index={index}
                            />

                            <OddsBox
                              match={gameEvent}
                              oddsKey="lay1"
                              back={false}
                              home={true}
                              index={index}
                            />
                          </Box>
                        </Td>
                        <Td>
                          <Box display="flex" flexDirection="row">
                            <OddsBox
                              match={gameEvent}
                              oddsKey="back11"
                              back={true}
                              home={true}
                              index={index}
                            />

                            <OddsBox
                              match={gameEvent}
                              oddsKey="lay11"
                              back={false}
                              home={true}
                              index={index}
                            />
                          </Box>
                        </Td>
                        <Td>
                          <Box display="flex" flexDirection="row">
                            <OddsBox
                              match={gameEvent}
                              oddsKey="back12"
                              back={true}
                              home={true}
                              index={index}
                            />

                            <OddsBox
                              match={gameEvent}
                              oddsKey="lay12"
                              back={false}
                              home={true}
                              index={index}
                            />
                          </Box>
                        </Td>
                      </Tr>
                    ))
                    : null}
                </Tbody>
              </Table>
              {isEmpty(data) && (
                <Text mt={3} className="norecords">
                  No Data Found
                </Text>
              )}
            </>
          ) : (
            <Text mt={3} className="norecords">
              No Data Found
              {console.log("data not found")}
            </Text>
          )}
        </TableContainer>
      </Box>
    </>
  );
}
