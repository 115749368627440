import {
  Box,
  MenuItem,
  Select,
  Spacer,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import "./fixture.css";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import pdf_logo from "../assets/img/pdf_img.svg";
import ppt_logo from "../assets/img/excel_img.svg";
import jsPDF from "jspdf";
import { DownloadTableExcel } from "react-export-table-to-excel";

export default function CustomTable({
  rows,
  columns,
  showPagination,
  totalPages_,
  rowsPerPage_,
  currentPage_,
  headerBg,
  ref,
  onPageChange,
}) {
  const [currentPage, setCurrentPage] = React.useState(currentPage_);
  const [totalPages, setTotalPages] = React.useState(totalPages_);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPage_);
  const [perROwsPageOptions, setRowsPerPageOptions] = useState([
    5, 10, 20, 50, 100,
  ]);
  const location = useLocation();
  const isCG = location.pathname.includes("cg");
  console.log(isCG, "mmmm");

  React.useEffect(() => {
    if (rows) {
      setRowsPerPageOptions([5, 10, 20, 50, 100]);
    }
  }, [rows, rowsPerPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  useEffect(() => {
    setTotalPages(totalPages_);
  }, [totalPages_]);

  const getShowingEntriesText = () => {
    const startEntry = currentPage * rowsPerPage + 1;
    const endEntry = Math.min((currentPage + 1) * rowsPerPage, rows.length);
    return `Showing ${startEntry}-${endEntry} of ${rows.length} entries`;
  };

  const tableRef = useRef(null);

  const generateReportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#table" });
    doc.save("table.pdf");
  };

  return (
    <>
      {/* <Stack className='generic-table-main-container-inner'>
        <Stack className='generic-table-main-container-inner-1'>
          <p className='generic-table-text-1'>Show</p>
          <Select
            value={perPageData}
            onChange={handleDropdownChange}
            className='generic-table-main-conatainer-select'
          >
            {perPageOptions?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className='generic-table-menu-item'
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <p className='generic-table-text-1'>entries</p>
        </Stack>
        <Stack className='generic-table-main-container-inner-5'>
          <img
            src={pdf_logo}
            alt='PDF Logo'
            onClick={generateReportPDF}
            style={{ cursor: 'pointer' }}
          />

          <DownloadTableExcel
            filename='users table'
            sheet='users'
            currentTableRef={tableRef.current}
          >
            <img src={ppt_logo} alt='PPT Logo' style={{ cursor: 'pointer' }} />{' '}
          </DownloadTableExcel>
        </Stack>
      </Stack> */}
      <TableContainer ref={ref}>
        {/* <table id='table'> */}
        <Table className="custom-table">
          <Thead backgroundColor={isCG ? "#0000" : "#028dad"}>
            <Tr border="none" color="white">
              {columns.map((column) => (
                <Th className={isCG ? "custom-table-cg" : "custom-table"}>
                  {column.name}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
  {rows && rows.length > 0 ? (
    rows.map((row, index) => (
      <Tr
        key={index} 
        border="none"
        bg={
          row.back_run
            ? "blue.100"
            : row.lay_run
            ? "pink.100"
            : "transparent"
        }
      >
        {columns.map((column) =>
          column.element ? (
            column.element(row, index)
          ) : column.type === "date" ? (
            <Td key={column.id} border="none">
              <Text color="#A6A6A6">{new Date(row[column.id])}</Text>
            </Td>
          ) : (
            <Td key={column.id} border="none">
            {typeof row[column.id] === "string" ||
            typeof row[column.id] === "number" ? (
              // Check if column.id is "event_type" to show "Live Casino"
              column.id == "event_type" ? (
                <Text fontSize="sm">
                  {row[column.id] == "event_type" || !row[column.id] ? "Live Casino" : row[column.id]}
                </Text>
              ) : (
                <Text fontSize="sm">{row[column.id]}</Text>
              )
            ) : typeof row[column.id] === "boolean" ? (
              <Switch
                size="sm"
                defaultChecked={
                  column.id === "isBetLocked"
                    ? !row[column.id]
                    : row[column.id]
                }
                isDisabled
              />
            ) : null}
          </Td>
          
          )
        )}
      </Tr>
    ))
  ) : (
    <Tr>
      <Td border="none" colSpan={columns.length}>
        <Box textAlign="center">
          <Text color="red">No data Found</Text>
        </Box>
      </Td>
    </Tr>
  )}
</Tbody>


          {showPagination && (
            <Tfoot>
              <Tr>
                <Th colSpan={columns.length}>
                  <Box>
                    <Box>
                      <Stack className="generic-table-main-container-inner-1">
                        {/* <p className="generic-table-text-1">Show</p> */}
                        {/* <Select
                        value={perPageData}
                        onChange={handleDropdownChange}
                        className="generic-table-main-conatainer-select"
                      >
                        {perPageOptions?.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            className="generic-table-menu-item"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select> */}
                        {/* <Select>
                        <MenuItem className="generic-table-menu-item">
                        top</MenuItem>
                      </Select>
                      <p className="generic-table-text-1">entries</p> */}
                      </Stack>
                    </Box>

                    <Spacer />
                  </Box>
                  <Box className="pagination-container">
                    {/* <Text className="pagination-text">
                    Page {currentPage} of {totalPages}
                  </Text> */}
                    <Text className="text-black">
                      {getShowingEntriesText()}
                    </Text>
                    <Spacer />
                    <Box>
                      <button
                        className={
                          isCG
                            ? "pagination-button cg-gradiant-btn go-btn"
                            : "pagination-button gradiant-btn go-btn"
                        }
                        onClick={prevPage}
                        disabled={currentPage === 0}
                      >
                        <i className="fa fa-chevron-left"></i> Prev
                      </button>
                      <button
                        className={
                          isCG
                            ? "pagination-number-btn cg-gradiant-btn"
                            : "pagination-number-btn gradiant-btn"
                        }
                      >
                        {currentPage}
                      </button>
                      <button
                        className={
                          isCG
                            ? "pagination-button cg-gradiant-btn reset-btn"
                            : "pagination-button gradiant-btn reset-btn"
                        }
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next <i className="fa fa-chevron-right"></i>
                      </button>
                    </Box>
                  </Box>
                </Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
        {/* </table> */}
      </TableContainer>
    </>
  );
}
