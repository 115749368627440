import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect, useRef } from "react";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/Table";
import { getAccountStatementAPI } from "../../service/UserService";
import {
  bgColor,
  blueColor,
  buttonColors,
  convertEpochToDate,
  exportToCsvReactCsv,
  exportToPdf,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { getBetsFromAccountLogs } from "../../service/AuthService";
import moment from "moment";
import Pagination from "../../components/Pagination";
import ExporttoPDF from "../../assets/img/pdf_img.svg";
import ExporttoExcel from "../../assets/img/excel_img.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import "./pagination.css";
export default function AccountStatement() {
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [reportType, setReportType] = React.useState(null);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");
  const [logData, setLogData] = React.useState({});
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const dataRef = useRef();
  const isMobileView = window.innerWidth < 768;

  const getData = async (id, TYPE = "ALL") => {
    setSelectedLogId(id);
    const res = await getBetsFromAccountLogs(
      selectedLogId ? selectedLogId : id
    );
    if (res.code === 200) {
      let response_ = res.response;
      let betResponseDTOList = response_.betResponseDTOList;
      let betResponseDTOList_ = [];
      betResponseDTOList.forEach((item) => {
        if (item.back_rate && item.event_type == "Casino") {
          item.type = "back";
          item.rate = item.back_rate;
        } else if (item.lay_rate && item.event_type == "Casino") {
          item.type = "lay";
          item.rate = item.lay_rate;
        }
        if (
          item.back_run &&
          (item.event_type == "Cricket" ||
            item.event_type == "Soccer" ||
            item.event_type == "Tennis")
        ) {
          item.type = "back";
          item.rate = item.back_run;
        } else if (
          item.lay_run &&
          (item.event_type == "Cricket" ||
            item.event_type == "Soccer" ||
            item.event_type == "Tennis")
        ) {
          item.type = "lay";
          item.rate = item.lay_run;
        }
        betResponseDTOList_.push(item);
      });
      response_.betResponseDTOList = betResponseDTOList_;

      if (TYPE == "ALL") {
        response_ = res.response;
      }
      if (TYPE == "BACK") {
        response_.betResponseDTOList = response_.betResponseDTOList.filter(
          (item) => item.type == "back"
        );
      }
      if (TYPE == "LAY") {
        response_.betResponseDTOList = response_.betResponseDTOList.filter(
          (item) => item.type == "lay"
        );
      }
      let map = {};
      map[selectedLogId ? selectedLogId : id] = response_;
      setLogData(map);
    }
  };

  const getAccountStatement = async () => {
    let payload = {
      pageNumber: currentPage,
      pageSize: rowsPerPage,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const res = await getAccountStatementAPI(payload);
    if (res.code === 200) {
      const openingBalance_ = res.response[0]?.updatedBalance ?? 0;
      const closingBalance_ =
        res.response[res.response.length - 1]?.updatedBalance ?? 0;
      setOpeningBalance(openingBalance_);
      setClosingBalance(closingBalance_);

      setNoOfPages(Math.ceil(res.response.length / rowsPerPage));
      setCurrentPageData(res.response.slice(0, rowsPerPage));
      setData(res.response);
    }
  };

  const search = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value.trim() === "") {
      setCurrentPageData(
        data.slice(
          pageNumber * rowsPerPage,
          pageNumber * rowsPerPage + rowsPerPage
        )
      );
    } else {
      const filteredData = data.filter((item) => {
        const commentMatch = item.comment.toLowerCase().includes(value);
        const creditInfoMatch =
          typeof item.creditInfo === "string" &&
          item.creditInfo.toLowerCase().includes(value);
        const debitInfoMatch =
          typeof item.debitInfo === "string" &&
          item.debitInfo.toLowerCase().includes(value);
        const updatedBalanceMatch = item.updatedBalance
          .toString()
          .toLowerCase()
          .includes(value);
        const currentBalanceMatch = item.currentBalance
          .toString()
          .toLowerCase()
          .includes(value);

        return (
          commentMatch ||
          creditInfoMatch ||
          debitInfoMatch ||
          updatedBalanceMatch ||
          currentBalanceMatch
        );
      });

      setData(filteredData);
      setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
      setCurrentPageData(filteredData.slice(0, rowsPerPage));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getAccountStatement();
  };

  const handleReset = (e) => {
    setStartDate(moment().format("YYYY-MM-DDTHH:MM"));
    setEndDate(moment().format("YYYY-MM-DDTHH:MM"));
  };

  useEffect(() => {
    getAccountStatement();
  }, [type, selectedLogId, currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType]);

  const generateReport = (isPDF = false) => {
    let data_ = [];
    data.forEach((item) => {
      data_.push({
        Credit: item.creditInfo,
        Debit: item.debitInfo,
        Comment: item.comment,
        "Updated Balance": item.updatedBalance,
        "Updated Date": moment(item.updatedDate).format("DD-MM-YYYY HH:mm:ss"),
        Date: moment(item.date).format("DD-MM-YYYY HH:mm:ss"),
      });
    });
    if (isPDF) {
    } else {
      exportToCsvReactCsv(data_, "Account Statement");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const tableRef = useRef(null);

  const generateReportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#table" });
    doc.save("table.pdf");
  };

  return (
    <Box
      // p={{ base: 0, md: 2 }}
      bg="#fff"
      borderRadius={10}
      textColor="#A6A6A6"
      paddingTop="0 !important"
      className="setting-page-main"
    >
      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
        background="#27A6C3"
        padding={2}
        marginTop="-5px"
      >
        <Text fontSize="sm" color="#fff !important">
          Account Statement
        </Text>
      </Box>

      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="input-head"
      >
        <Box
          flexDirection={{
            base: "column",
            md: "row",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="2"
          width={{
            base: "100%",
            sm: "100%",
            md: "80%",
            lg: "60%",
          }}
          className="head-body"
        >
          <CustomSelect
            borderRadius="5"
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: isMobileView ? "100%" : "40rem",
            }}
            size="md"
            borderColor="#ccc"
            onChange={(e) => setType(e.target.value)}
            options={[
              {
                label: "All",
                value: "ALL",
              },
              {
                label: "Balance Report",
                value: "BALANCE_REPORT",
              },
              {
                label: "Game Report",
                value: "GAME_REPORT",
              },
              {
                label: "Settlement Report",
                value: "SETTLEMENT_REPORT",
              },
            ]}
          ></CustomSelect>

          <CustomInput
            placeholder="Select Start Date"
            type="date"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
            max={moment().format("YYYY-MM-DD")}
          />
          <CustomInput
            placeholder="Select End Date"
            type="date"
            bg="transparent"
            borderRadius="5"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            size="md"
            max={moment().format("YYYY-MM-DD")}
            min={moment(startDate).format("YYYY-MM-DD")}
          />

          <Box display="flex">
            <Button
              className="gradiant-btn go-btn"
              size="sm"
              onClick={handleSubmit}
              mr={1}
            >
              Go
            </Button>

            <Button
              className="gradiant-btn reset-btn"
              size="sm"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="start"
          height="auto"
          width="100%"
          ml={{
            base:"0px",
            md:"10px"
          }}
        >
          <Grid templateColumns="70% 30%" gap={0}>
            <GridItem>
              <Box
                m={1}
                p={2}
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                height="100%"
                display="flex"
                alignItems="center"
                minWidth="150px"
              >
                <Text
                  className="text-black"
                  fontWeight="bold"
                  fontSize="sm"
                  whiteSpace="nowrap"
                >
                  Opening Balance
                </Text>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                m={1}
                p={2}
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text className="text-black" fontWeight="bold" fontSize="sm">
                  {openingBalance}
                </Text>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                m={1}
                p={2}
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                height="100%"
                display="flex"
                alignItems="center"
                minWidth="150px"
              >
                <Text
                  className="text-black"
                  fontWeight="bold"
                  fontSize="sm"
                  whiteSpace="nowrap"
                >
                  Closing Balance
                </Text>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                m={1}
                p={2}
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text className="text-black" fontWeight="bold" fontSize="sm">
                  {closingBalance}
                </Text>
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Box>

{/* web  */}
      <Box
        display={{ base: "none", md: "flex" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={"flex-start"}
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
      >
        <Box
          w={{ base: "100%", md: "auto" }}
          borderRadius={{ base: "0px", md: "5px" }}
          display="flex"
          alignItems="center"
          m={{ base: "10px", md: "10px" }}
          border="1px solid #c7c7c7"
        >
          <CustomInput
            placeholder="Search"
            border="none"
            color="gray"
            _placeholder={{ color: "gray" }}
            _focus={{ boxShadow: "none" }}
            onChange={(e) => search(e)}
            p={{
              base: "10px",
              md: "20px",
            }}
            outline="none"
            mt="-10px"
            containerStyle={{
              paddingTop: "5px",
              width: "70%",
            }}
            value={searchValue}
          />{" "}
          {searchValue ? (
            <CloseIcon
              onClick={() => {
                setSearchValue("");
                getAccountStatement();
              }}
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
              cursor="pointer"
            />
          ) : (
            <SearchIcon
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
            />
          )}
        </Box>
        <Box display="flex">
          <img
            src={ExporttoPDF}
            alt="PDF Logo"
            onClick={generateReportPDF}
            style={{ cursor: "pointer" }}
          />

          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <img
              src={ExporttoExcel}
              alt="PPT Logo"
              style={{ cursor: "pointer" }}
            />{" "}
          </DownloadTableExcel>
        </Box>
        <Spacer />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          mr="5"
        >
          <Text className="text-black">Show</Text>
          <Select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            width="auto"
            ml="2"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
          <Text ml="2" className="text-black">
            entries
          </Text>
        </Box>
      </Box>

      {/* mobile  */}
      <Box
  display={{ base: "flex", md: "none" }}
  alignItems={{ base: "center", md: "center" }}
  justifyContent="center"
  flexDirection={{ base: "row", md: "row" }}
  w="100%"
>
  {/* Search input and icons */}
  <Box
    w={{ base: "100px", md: "auto" }}
    borderRadius="5px"
    display="flex"
    alignItems="center"
    m={{ base: "10px", md: "10px" }}
    border="1px solid #c7c7c7"
    width="25%"
  >
    <CustomInput
      placeholder="Search"
      border="none"
      color="gray"
      _placeholder={{ color: "gray" }}
      _focus={{ boxShadow: "none" }}
      onChange={(e) => search(e)}
      p={{ base: "10px", md: "20px" }}
      outline="none"
      mt="-10px"
      containerStyle={{ paddingTop: "5px", width: "90%" }}
      value={searchValue}
    />
    {searchValue ? (
      <CloseIcon
        onClick={() => {
          setSearchValue("");
          getAccountStatement();
        }}
        mx="10px"
        ml={{ base: "20px" }}
        color="gray"
        cursor="pointer"
      />
    ) : (
      <SearchIcon
        mx="10px"
        ml={{ base: "20px" }}
        color="gray"
      />
    )}
  </Box>

  {/* Export icons */}
  <Box display="flex" width={{ base: "15%", md: "auto" }}>
    <img
      src={ExporttoPDF}
      alt="PDF Logo"
      onClick={generateReportPDF}
      style={{ cursor: "pointer" }}
    />
    <DownloadTableExcel
      filename="users table"
      sheet="users"
      currentTableRef={tableRef.current}
    >
      <img
        src={ExporttoExcel}
        alt="PPT Logo"
        style={{ cursor: "pointer" }}
      />
    </DownloadTableExcel>
  </Box>

  {/* Show entries dropdown */}
  <Box
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    flexDirection={{ base: "row", md: "row" }}
    w={{ base: "50%", md: "auto" }}
    mr="5"
  >
    <Text className="text-black">Show</Text>
    <Select
      value={rowsPerPage}
      onChange={(e) => setRowsPerPage(Number(e.target.value))}
      width="auto"
      ml="2"
    >
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </Select>
    <Text ml="2" className="text-black">
      entries
    </Text>
  </Box>
</Box>


      {/* <table id='table'> */}
      <CustomTable
        ref={dataRef}
        color="white"
        columns={[
          {
            name: "Date",
            id: "createdAt",
            element: (row) => (
              <Td border="none">
                <Text>{convertEpochToDate(row.createdAt)}</Text>
              </Td>
            ),
          },
          { name: "Credit", id: "creditInfo", style: { textAlign: "end" } },
          {
            name: "Debit",
            id: "debitInfo",
            element: (row) => (
              <Td border="none">
                <Text>
                  {row?.debitInfo && parseFloat(row?.debitInfo).toFixed(2)}
                </Text>
              </Td>
            ),
          },
          {
            name: "Balance",
            id: "updatedBalance",
            element: (row) => (
              <Td border="none">
                <Text>{row?.updatedBalance?.toFixed(2)}</Text>
              </Td>
            ),
          },
          {
            name: "Description",
            id: "comment",
            element: (row) => (
              <Td w="auto" display="flex" border="none">
                <Text
                  bg={row.type == "GAME_REPORT" ? buttonColors : ""}
                  onClick={() => {
                    if (row.type == "GAME_REPORT") {
                      setSelectedLogId(row.accountLogId);
                      getData(row.accountLogId, "ALL");
                      setIsModalOpen(true);
                    }
                  }}
                  cursor="pointer"
                  p="2px 5px"
                  borderRadius="5px"
                  color={row.type == "GAME_REPORT" ? "#fff !important" : ""}
                >
                  {row.comment}
                </Text>
              </Td>
            ),
          },
        ]}
        onPageChange={handlePageChange}
        rows={currentPageData}
        showPagination={true}
        Pagination
        totalPages_={noOfPages}
        rowsPerPage_={currentPageData.length}
        currentPage_={currentPage}
        headerBg="#363636"
      />
      {/* </table> */}
      {data.length === 0 && (
        <Text className="no-data-found">No Statements</Text>
      )}
      {/* <Pagination /> */}

      {logData[selectedLogId] && (
        <Modal
          size={"6xl"}
          onClose={() => {
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          motionPreset="slideInBottom"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent marginTop={10} bg={"rgb(51,51,51)"}>
            <ModalCloseButton />
            <ModalBody p="2" bg={"#2E3439"} borderRadius={"5px"}>
              <div
                id="__BVID__2570___BV_modal_content_"
                tabIndex={-1}
                className="modal-content snipcss-z5ed3"
              >
                <header
                  id="__BVID__2570___BV_modal_header_"
                  className="modal-header"
                >
                  <h5
                    id="__BVID__2570___BV_modal_title_"
                    className="modal-title"
                  >
                    Result
                  </h5>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    type="button"
                    aria-label="Close"
                    className="close"
                  >
                    ×
                  </button>
                </header>
                <div id="__BVID__2570___BV_modal_body_" className="modal-body">
                  <div className="mt-1">{logData[selectedLogId]?.header}</div>
                  <div className="mt-1">
                    <div className="row">
                      <div className="col-6">
                        Winner: {logData[selectedLogId]?.winner}
                      </div>
                      <div className="col-6 text-right">
                        Game Time:
                        <span>
                          {moment(logData[selectedLogId]?.eventDateTime).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="soda-all"
                          name="example"
                          onClick={() => getData(selectedLogId, "ALL")}
                          defaultValue="all"
                          defaultChecked={selectedType == "ALL" ? true : false}
                          className="custom-control-input"
                        />
                        <label
                          htmlFor="soda-all"
                          className="custom-control-label"
                        >
                          All
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="soda-back"
                          name="example"
                          defaultValue="back"
                          onClick={() => getData(selectedLogId, "BACK")}
                          className="custom-control-input"
                          defaultChecked={selectedType == "BACK" ? true : false}
                        />
                        <label
                          htmlFor="soda-back"
                          className="custom-control-label"
                        >
                          Back
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="soda-lay"
                          name="example"
                          onClick={() => getData(selectedLogId, "LAY")}
                          defaultValue="lay"
                          className="custom-control-input"
                          defaultChecked={selectedType == "LAY" ? true : false}
                        />
                        <label
                          htmlFor="soda-lay"
                          className="custom-control-label"
                        >
                          Lay
                        </label>
                      </div>

                      <div className="custom-control-inline total-soda">
                        <div>
                          Total Bets:
                          <span className="text-success mr-2">
                            {logData[selectedLogId]?.totalBet}
                          </span>
                          Total Win:
                          <span className="text-success">
                            {logData[selectedLogId]?.totalWin}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive report-table-modal mt-2 report-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="bet-nation">
                              <div>Nation</div>
                            </th>
                            <th className="text-right bet-user-rate">
                              <div>Rate</div>
                            </th>
                            <th className="text-right bet-user-rate">
                              <div>Bhav</div>
                            </th>
                            <th className="text-right bet-amount">
                              <div>Amount</div>
                            </th>
                            <th className="text-right bet-amount">
                              <div>Win</div>
                            </th>
                            <th className="bet-date">
                              <div>Date</div>
                            </th>
                            <th className="bet-ip">
                              <div>Ip Address</div>
                            </th>
                            <th className="bet-remark">
                              <div>Browser Details</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {logData[selectedLogId]?.betResponseDTOList?.map(
                            (log) => (
                              <tr
                                className={` ${
                                  log.type == "lay"
                                    ? "lay-border"
                                    : "back-border"
                                }`}
                              >
                                <td className="bet-nation">
                                  <div>
                                    <div className="d-inline-block vm custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        defaultValue={0}
                                        id="__BVID__2616"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="__BVID__2616"
                                      ></label>
                                    </div>
                                    <span className="d-inline">
                                      {log?.runner_name}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-right bet-user-rate">
                                  <div>{log?.rate}</div>
                                </td>
                                <td className="text-right bet-user-rate">
                                  <div>
                                    {log?.back_rate
                                      ? log.back_rate
                                      : log.lay_rate
                                      ? log.lay_rate
                                      : "-"}
                                  </div>
                                </td>
                                <td className="text-right bet-amount">
                                  <div>{log.amount}</div>
                                </td>
                                <td className="text-right bet-amount">
                                  <div>
                                    {log.loss ? `-${log.loss}` : log.profit}
                                  </div>
                                </td>
                                <td className="bet-date">
                                  <div>
                                    {moment(log.created_date + "Z").format(
                                      "DD/MM/YYYY hh:mm"
                                    )}
                                  </div>
                                </td>
                                <td className="bet-ip">
                                  <div>{log.ipAddress}</div>
                                </td>
                                <td className="bet-remark">
                                  <Popover preventOverflow={true}>
                                    <PopoverTrigger>
                                      <Text
                                        _hover={{ cursor: "pointer" }}
                                        color="blue.500"
                                      >
                                        <u title="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36">
                                          Detail
                                        </u>
                                      </Text>
                                    </PopoverTrigger>
                                    <PopoverContent bg="gray">
                                      {log?.browserDetails}
                                    </PopoverContent>
                                  </Popover>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
