import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import {
  BsFileEarmarkExcel,
  BsFileEarmarkPdf,
  BsFileExcelFill,
  BsFilePdfFill,
} from "react-icons/bs";
import CustomTable from "../../components/Table";
import { betHistoryAPI, currentBetsAPI } from "../../service/AuthService";
import CustomInput from "../../components/CustomInput";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  buttonColors,
  exportToCsvReactCsv,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import { useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import ExporttoPDF from "../../assets/img/pdf_img.svg";
import ExporttoExcel from "../../assets/img/excel_img.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

export default function CGBetHistory() {
  const userData = useSelector((state) => state.account.userData);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [data, setData] = React.useState([]);
  const [betType, setBetType] = React.useState("ALL");
  const [betStatus, setBetStatus] = React.useState("MATCHED");
  const [totalElements, setTotalElements] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [totalBets_, setTotalBets] = React.useState(0);
  const [totalAmount_, setTotalAmount] = React.useState(0);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [csvReport, setCsvReport] = React.useState([]);
  const [reportType, setReportType] = React.useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = React.useState("CRICKET");
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const columns = [
    {
      name: "Date & Time",
      id: "created_date",
      element: (item) => (
        <Td border="none">
          <Text>
            {moment(item.created_date + "Z").format("DD-MM-YYYY HH:mm:ss")}
          </Text>
        </Td>
      ),
    },
    {
      name: "Game Gategory",
      id: "event_type",
    },
    {
      name: "Game Name",
      id: "event_name",
    },
    {
      name: "Amount",
      id: "amount",
    },
    {
      name: "Status",
      id: "runner_name",
      element: (item) => (
        <Td border="none">
          <Text>Placed</Text>
        </Td>
      ),
    },
    // {
    //   name: "Device",
    //   id: "side",
    // },
    // {
    //   name: "IP",
    //   id: "",
    // },
  ];
  const isMobileView = window.innerWidth < 768;

  const betHistoryAPIHandler = useCallback(async () => {
    const payload = {
      from: moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
      matched: betStatus === "MATCHED",
      page: 0,
      size: 10,
      sport: "CASINO",
      to: moment(endDate)
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS"),
      userName: userData.username,
    };

    const res = await betHistoryAPI(payload);
    console.log(res, "resData");
    if (res.status === 200) {
      setData(res.data.response.content);
    }
  }, [betStatus, endDate, startDate, type, userData.username]);

  useEffect(() => {
    betHistoryAPIHandler();
  }, [betHistoryAPIHandler]);

  const search = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") return betHistoryAPIHandler();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["comment", "debitInfo", "creditInfo", "updatedBalance"],
        e.target.value
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    betHistoryAPIHandler();
  };

  const handleReset = (e) => {
    setStartDate(moment().format("YYYY-MM-DDTHH:MM"));
    setEndDate(moment().format("YYYY-MM-DDTHH:MM"));
  };

  const tableRef = useRef(null);

  const generateReportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#table' });
    doc.save('table.pdf');
  };

  return (
    <Box
      bg={{ base: "#fff", md: "#fff" }}
      borderRadius={10}
      textColor="lightgray"
      className="setting-page-main bet-history-main"
    >
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        marginTop="-5px"
      >
        <Text color="black" fontSize="16px" fontWeight="600" >
          Club Game Bet History
        </Text>
      </Box>
      <Box
        w={{ base: "100%", md: "60%" }}
        display="flex"
        alignItems="center"
        justifyContent={{
          base: "flex-start",
          md: "flex-start",
        }}
      >
        <Box
          flexDirection={{
            base: "row",
            md: "row",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="2"
          width={"100%"}
          className="input-box-head"
        >
          <CustomInput
            placeholder="Select Start Date"
            type="date"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
              width: isMobileView ? "50%" : "40rem",
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
            max={moment().format("YYYY-MM-DD")}
            className="col-select"
          />
          <CustomInput
            placeholder="Select Start Date"
            type="date"
            bg="transparent"
            borderRadius="5"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: isMobileView ? "50%" : "40rem",
            }}
            size="md"
            max={moment().format("YYYY-MM-DD")}
            min={moment(startDate).format("YYYY-MM-DD")}
            className="col-select"
          />
          <Box display="flex">
            <Button
              className="cg-gradiant-btn cg-go-btn"
              size="sm"
              onClick={handleSubmit}
              mr={1}
            >
              Go
            </Button>

            <Button
              className="cg-gradiant-btn cg-reset-btn"
              size="sm"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={"flex-start"}
        flexDirection={{ base: "row", md: "row" }}
        w="100%"
      >
        <Box
          w={{ base: "auto", md: "auto" }}
          borderRadius={{ base: "5px", md: "5px" }}
          display="flex"
          alignItems="center"
          m={{ base: "10px", md: "10px" }}
          border="1px solid #c7c7c7"
        >
          <CustomInput
            placeholder="Search"
            border="none"
            color="gray"
            _placeholder={{ color: "gray" }}
            _focus={{ boxShadow: "none" }}
            onChange={search}
            p={{
              base: "20px",
              md: "20px",
            }}
            outline="none"
            mt="-10px"
            containerStyle={{
              paddingTop: "5px",
              width: "70%",
            }}
            value={searchValue}
          />{" "}
          {searchValue ? (
            <CloseIcon
              onClick={() => {
                setSearchValue("");
                betHistoryAPIHandler();
              }
              }
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
              cursor="pointer"
            />
          ) : (
            <SearchIcon
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
            />
          )}
        </Box>
        <Box display="flex" mt={{ base: "15px", md: "0px" }}>
          <img
            src={ExporttoPDF}
            alt='PDF Logo'
            onClick={generateReportPDF}
            style={{ cursor: 'pointer' }}
          />

          <DownloadTableExcel
            filename='users table'
            sheet='users'
            currentTableRef={tableRef.current}
          >
            <img src={ExporttoExcel} alt='PPT Logo' style={{ cursor: 'pointer' }} />{' '}
          </DownloadTableExcel>
        </Box>
      </Box>

      <CustomTable
        headerBg="#363636"
        columns={columns}
        rows={data}
        showPagination={true}
        rowsPerPage_={pageSize}
        totalPages_={totalElements}
        currentPage_={pageNumber}
      />
    </Box>
  );
}
