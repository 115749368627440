/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  css,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';
import './exportTab.css';
import TabsContent from './tabsContent';
import { buttonColors } from '../../utils/constants';
import { useReducer } from 'react';
import { useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import SportOddsTable from './SportOddsTable';
export default function ExportTabs() {
  const { sportName } = useParams();
  const location = useLocation();
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const tabListRef = useRef();

  const isSelectedSportCricket = location.pathname.includes('cricket');
  const isSelectedSportSoccer = location.pathname.includes('soccer');
  const isSelectedSportTennis = location.pathname.includes('tennis');
  const isSelectedInPlay = location.pathname.includes('in-play');
  const isHomePage = location.pathname === '/';

  const handleScroll = (direction) => {
    const tabList = tabListRef.current;
    if (tabList) {
      const scrollAmount = 200;
      if (direction === 1) {
        tabList.scrollLeft += scrollAmount;
      } else {
        tabList.scrollLeft -= scrollAmount;
      }
    }
  };
  const [_selected, setSelected] = React.useState('cricket');
  const [sports, setSports] = React.useState([
    {
      name: 'Cricket',
      id: 'cricket',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/4.png',
    },
    {
      name: 'Football',
      id: 'soccer',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/1.png',
    },
    {
      name: 'Tennis',
      id: 'tennis',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/2.png',
    },
    {
      name: 'Basketball',
      id: 'basketball',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/15.png',
    },
    {
      name: 'Volleyball',
      id: 'volleyball',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/18.png',
    },
    {
      name: 'Snooker',
      id: 'snooker',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/59.png',
    },
    // {
    //   name: "Handball",
    //   id: "handball",
    // },
    {
      name: 'Horse Racing',
      id: 'horse_racing',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/10.png',
    },
    // {
    //   name: "Handball",
    //   id: null,
    // },
    // {
    //   name: "Ice Hockey",
    //   id: null,
    // },
  ]);

  useEffect(() => {
    if (sportName) {
      const _sports = [...sports];
      const filtered = _sports.filter((k) => k.id === sportName);

      setSports(filtered);
      setSelected(sportName);
    }
  }, []);

  return (
    <div
      style={{
        position: 'relative',
      }}
      className='sport-tabs '
    >
      <a onClick={() => handleScroll(-1)} className='arrow-tabs arrow-left'>
        {/* <Image
          h='5px'
          src='https://sitethemedata.com/v115/static/front/img/arrow-down.svg'
        /> */}
      </a>
      {/* {sportName} */}
      {(isSelectedInPlay || isHomePage) && (
        <>
          <SportOddsTable sportName='cricket' />
          <SportOddsTable sportName='soccer' />
          <SportOddsTable sportName='tennis' />
        </>
      )}
      { isSelectedSportCricket && (
        <SportOddsTable sportName='cricket' />
      )}
      { isSelectedSportSoccer && (
        <SportOddsTable sportName='soccer' />
      )}
      { isSelectedSportTennis && (
        <SportOddsTable sportName='tennis' />
      )}
      <a onClick={() => handleScroll(1)} className='arrow-tabs arrow-right'>
        {/* <Image
          ml='10px'
          h='5px'
          src='https://sitethemedata.com/v115/static/front/img/arrow-down.svg'
        /> */}
      </a>
    </div>
  );
}
