import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import PlaceBet from '../../game/PlaceBet';
import './css/tpo.css';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import { ASSETS_URL } from '../../../utils/constants';
import { getCasinoPlExposureAPI } from '../../../service/casino';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';
export default function TeenPattiOpen() {
  const location = useLocation();
  const dispatch = useDispatch();
  const odds_ = useSelector((state) => state.gameData.teen8Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [plExposure, setPlExposure] = useState(null);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [belowCards, setBelowCards] = useState([]);
  const [parsedCards, setParsedCards] = useState([]);
  const toast = useToast();
  const gameName = 'TEEN_8';
  const CustomButton = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : 'sm'}
      my='2'
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='pattiButton'
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );

  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('teen8'));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display='flex'
      flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
    
    >
      <Box w={{ base: '100%', sm: '100%', lg: '70%' }} mr='1'>
        <Box
          className='casino-video'
          mb='2'
          pos='relative'
        >
          {odds?.cards && (
            <CardDrawer
              cards={odds?.cards[odds?.cards.length - 1].slice(0, 3)}
            />
          )}
          <div className='market-title'>TeenPatti Open</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3049`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}tp open-rules.jpg`} />
        </Box>
        <div className='card-content m-t-0 teenpatti-open'>
          <div className='table-responsive m-b-10 main-market'>
            <table
              id='teenpatti-tbl33'
              className='table coupon-table table table-bordered'
            >
              <thead>
                <tr>
                  <th className='box-4'></th>
                  <th className='box-3 back-color'>
                    Back (Min: <span>100</span>
                    Max: <span>100000</span>)
                  </th>
                  <th className='box-3 back-color'>
                    (Min: <span>100</span>
                    Max: <span>25000</span>)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 1</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[0]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    onClick={() => setEvent('Player 1')}
                    className='box-3 back text-center'
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 1').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 1')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 1')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 1</span>{' '}
                      <span className='d-block'>
                        {' '}
                        {getExposureForEntity('Pair plus 1')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 2</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[1]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td 
                  className='box-3 back teen-section'
                  onClick={() => setEvent('Player 2')}>
                    <button className='back'>
                      <span className='odd'>{getData('Player 2').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 2')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 2')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 2</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 2')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 3</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[2]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Player 3')}
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 3').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 3')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 3')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 3</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 3')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 4</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[3]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Player 4')}
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 4').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 4')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 4')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 4</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 4')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 5</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[4]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Player 5')}
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 5').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 5')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 5')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 5</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 5')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 6</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[5]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Player 6')}
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 6').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 6')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Pair plus 6')}
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 6</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 6')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 7</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[6]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Player 7')}
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 7').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 7')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 7')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 7</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 7')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className={`bet-info ${isLocked ? 'suspended' : ''}`}>
                  <td className='box-4'>
                    <div className='player-box'>
                      <b>Player 8</b>{' '}
                      <span className='patern-name card-icon m-l-20 column'>
                        {odds?.cards?.[7]?.slice(0, 3).map((item, index) => (
                          <GridItem
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            flexDirection='row'
                            justifyContent='center'
                          >
                            <Image
                              h='30px'
                              w='25px'
                              my={1}
                              src={ASSETS_URL + item + '.png'}
                              alt={`Card ${index}`}
                            />
                          </GridItem>
                        ))}
                      </span>
                    </div>
                  </td>
                  <td
                    className='box-3 back teen-section'
                    onClick={() => setEvent('Player 8')}
                  >
                    <button className='back'>
                      <span className='odd'>{getData('Player 8').back1}</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player 8')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    onClick={() => setEvent('Pair plus 8')}
                    className='box-3 back teen-section'
                  >
                    <button className='back'>
                      <span className='odd'>Pair plus 8</span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Pair plus 8')?.exposure}
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet
        game='casino'
        eventId={gameName}
        inningsRule={
          <div className='card m-b-10'>
            <div className='card-header' style={{ backgroundColor: '#9c3b3b' }}>
              <h6 className='card-title d-inline-block'>Rules</h6>
            </div>
            <div
              className='card-body'
              style={{ padding: '10px', backgroundColor: 'white' }}
            >
              <table className='table table-bordered rules-table'>
                <tbody>
                  <tr className='text-center'>
                    <th colspan='2'>Pair Plus</th>
                  </tr>
                  <tr>
                    <td width='60%'>Pair (Double)</td>
                    <td>1 To 1</td>
                  </tr>

                  <tr>
                    <td width='60%'>Flush (Color)</td>
                    <td>1 To 4</td>
                  </tr>

                  <tr>
                    <td width='60%'>Straight (Rown)</td>
                    <td>1 To 6</td>
                  </tr>

                  <tr>
                    <td width='60%'>Trio (Teen)</td>
                    <td>1 To 30</td>
                  </tr>

                  <tr>
                    <td width='60%'>Straight Flush (Pakki Rown)</td>
                    <td>1 To 40</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      />
    </Box>
  );
}
