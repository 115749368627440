import { Button, HStack, Input, useNumberInput } from '@chakra-ui/react';

export default function NumberInputPlusMinus({
  onChange,
  value,
  step,
  min,
  max,
  ...props
}) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: step,
      min: min,
      max: max,
      precision: 2,
      value,
    });

  const inc = getIncrementButtonProps({
    onClick: () => onChange(null, step),
  });
  const dec = getDecrementButtonProps({
    onClick: () => onChange(null, -step),
  });
  const input = getInputProps();

  return (
    <HStack maxW="320px" className="plus-minus-input">
      <Button {...dec}>-</Button>
      <Input {...input} onChange={(e) => onChange(e, parseFloat(e.target.value))} />
      <Button {...inc}>+</Button>
    </HStack>
  );
}
