import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Select,
  Td,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/Table";
import { getActivityLogAPI } from "../../service/AuthService";
import { getAccountStatementAPI } from "../../service/UserService";
import {
  bgColor,
  buttonColors,
  exportToCsvReactCsv,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import moment from "moment";
import { SearchIcon } from "@chakra-ui/icons";
export default function ActivityLog() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [type, setType] = React.useState("LOGIN");
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD HH:mm")
  );
  const [endDate, setEndDate] = React.useState(
    moment().format("YYYY-MM-DD HH:mm")
  );
  const getActivityLog = async () => {
    let endDate_ = new Date(endDate);
    endDate_.setDate(endDate_.getDate() + 1);
    let payload = {
      pageSize: rowsPerPage,
      from: new Date(startDate),
      to: endDate_,
      activityLogType: type,
    };
    const {
      response: {
        content,
        size,
        totalElements,
        totalPages,
        number,
        numberOfElements,
      },
    } = await getActivityLogAPI(payload);
    setData(content);
    setTotalPages(totalPages);
    setLoading(false);
    setRowsPerPage(size);
    setTotalElements(totalElements);
  };
  useEffect(() => {
    getActivityLog();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    getActivityLog();
  };
  const search = async (e) => {
    if (e.target.value == "") return getActivityLog();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["userName", "ipAddress"],
        e.target.value
      )
    );
  };
  const generateReport = () => {
    exportToCsvReactCsv(data, "activity_log");
  };
  return (
    <Box
      mt="5"
      p={{ base: 0, md: 2 }}
      bg={{ base: "transparent", md: "#eee" }}
      textColor="#A6A6A6"
    >
      <Box
        display="flex"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
      >
        <Text textColor="white" fontSize="xl">
          Activity Log
        </Text>
        <Box
          mt={2}
          bg={{ base: "#363636", md: "#494949" }}
          w={{ base: "100%", md: "auto" }}
          borderRadius={{ base: "0px", md: "72px" }}
          display="flex"
          alignItems="center"
        >
          <CustomInput
            placeholder="Search"
            border="none"
            color="gray"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={search}
            p={{
              base: "15px",
              md: "20px",
            }}
            mt="-10px"
            containerStyle={{
              width: "80%",
            }}
          />
          <SearchIcon
            mx="10px"
            color="gray"
            ml={{
              base: "40px",
            }}
          />
        </Box>
      </Box>
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box
          flexDirection={{
            base: "column",
            md: "row",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="2"
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <CustomInput
            placeholder="Select Start Date"
            type="datetime-local"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            label="From"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            textColor="#A6A6A6"
            size="md"
            borderRadius="5"
          />
          <CustomInput
            placeholder="Select Start Date"
            type="datetime-local"
            bg="transparent"
            borderRadius="5"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            label="To"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor="#A6A6A6"
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            size="md"
          />
          <CustomSelect
            borderRadius="5"
            label="Type"
            textColor="#A6A6A6"
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            size="md"
            onChange={(e) => setType(e.target.value)}
            borderColor="#AAA391"
            options={[
              {
                label: "Login",
                value: "LOGIN",
              },
              {
                label: "Change Password",
                value: "CHANGE_PASSWORD",
              },
            ]}
          ></CustomSelect>
          <Button
            px="80px"
            py="20px"
            mx="2"
            size="sm"
            bg={buttonColors}
            onClick={handleSubmit}
            alignSelf="center"
            color="black"
            borderRadius={5}
            mt={{ base: "2", md: "7" }}
            w={{ base: "100%", md: "auto" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Flex w="100%" justifyContent="flex-end">
        <Image
          onClick={generateReport}
          h="8"
          w="8"
          src="https://img.icons8.com/color/512/microsoft-excel-2019--v1.png"
        />

        <Image
          h="8"
          w="8"
          src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
        />
      </Flex>
      <CustomTable
        columns={[
          { name: "Username", id: "userName" },
          {
            name: "Date",
            id: "createdAt",
            element: (row) => {
              return (
                <Td border="none">
                  <Text textColor="#A6A6A6" fontSize="sm">
                    {moment(row?.createdAt + "Z").format("DD-MM-YYYY HH:mm:ss")}
                  </Text>
                </Td>
              );
            },
          },

          { name: "Ip Address", id: "ipAddress" },
        ]}
        rows={data}
        showPagination={false}
        totalPages_={totalPages}
        rowsPerPage_={rowsPerPage}
        currentPage_={pageNumber}
        headerBg="#363636"
      />
    </Box>
  );
}
