import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnouncementAPI, searchAPI } from '../../service/AuthService';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, Checkbox, IconButton, Img, MenuButton, Text, useDisclosure } from '@chakra-ui/react';
import Header from '.';
import Rules from './Rules';
import royalExch from '../../assets/img/royalExch.svg';
import announcementIcon from "../../assets/img/announcement_icon.png";
import LoginModal from "../../pages/login/loginModal";
import personIcon from "../../assets/img/personIcon.png";
import coinIcon from "../../assets/img/coinIcon.svg";
import SideMenu from "./SideMenu";
import { FiMenu } from 'react-icons/fi';
import "./header.css";


import InPlayIcon from "../../assets/img/InPlay.svg";
import multiMarkets from "../../assets/img/multi-markets.svg";
import winner from "../../assets/img/winner.svg";
import election from "../../assets/img/election.svg";
import clubCards from "../../assets/img/club-Cards.svg";
import pokerChip from "../../assets/img/poker-chip.svg";
import aviator from "../../assets/img/aviator.svg";
import horseIcon from "../../assets/icons/horse.png";
import electionIcon from "../../assets/img/election.svg";
import cricketIcon from "../../assets/icons/cricket.png";
import soccerIcon from "../../assets/icons/soccer.png";
import tennisIcon from "../../assets/icons/tennis.png";
import MobileSidebar from '../sidebar/mobileSidebar';
import Drawer from '../drawer';

const MobileHeader = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useSelector((state) => state.account.userData);
  const [isDropMenuOpen, setIsDropMenuOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [announcement, setAnnouncement] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSport, setSelectedSport] = useState('');
  const token = useSelector((state) => state.account.userData.token);

  const [options, setOptions] = useState({
    exposure: true,
    balance: true,
  });

  const search = async (value) => {
    setSearchValue(value);
    if (!value) {
      setIsSearchResultOpen(false);
    }
    if (value.length >= 3) {
      const res = await searchAPI(value);
      if (res && res.code === 200) {
        setSearchResults(res.response);
        setIsSearchResultOpen(true);
      }
    }
  };

  useEffect(() => {
    if (!searchOpen) {
      setSearchValue('');
      setIsSearchResultOpen(false);
    }
  }, [searchOpen]);


  const getAnnouncement = async () => {
    const res = await getAnnouncementAPI();
    console.log("announcement", res);
    if (res?.status === 200) {
      setAnnouncement(res.data?.response.announcement);
    }
  };

  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      setCurrentDate(now.toLocaleString("en-US", options));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleCloseSideMenu = (event) => {
    if (
      !event.target.closest(".account") &&
      !event.target.closest(".close-icon")
    ) {
      setIsDropMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseSideMenu);
    return () => {
      document.removeEventListener("click", handleCloseSideMenu);
    };
  }, []);


  const handleToggleMenu = () => {
    setIsDropMenuOpen(!isDropMenuOpen);
  };

  
  return (
    <>
        <div className="header-top col-md-12">
        
          <div className="float-left logo-box">
            <MobileSidebar onClose={onClose} isSidebarOpen={isDrawerOpen} setIsSidebarOpen={setIsDrawerOpen} />
          </div>
          <div className="float-left logo-box">
            <a href="/" className="logo">
              <Img src={royalExch} className="logo-icon" />
            </a>
          </div>
          <ul className="float-right d-flex align-items-center">
            {!token ? (
              <>
                <LoginModal isOpen={isOpen} onClose={onClose} />
                <li className="float-left download-apklink">
                  <div>
                    <a
                      href="javascript:void(0)"
                      onClick={onOpen}
                      className="rules-link m-r-5"
                    >
                      <Button className="d-flex home-login-button">
                        <Img src={personIcon} alt="img" />
                        <Text>Login</Text>
                      </Button>
                    </a>
                  </div>
                </li>
              </>
            ) : (
              <>
                <li className="account float-left">
                  <span onClick={() => setIsDropMenuOpen(!isDropMenuOpen)}>
                    <div className="balance-box">
                      <img
                        src={coinIcon}
                        alt="icon"
                        style={{ width: 25, height: 25 }}
                      />
                      <span>{userData.balance}</span>
                    </div>
                    <div className="balance-box">
                      <text>Exp :</text>
                      <span>{userData.liability}</span>
                    </div>
                  </span>
                  <SideMenu
                    isDropMenuOpen={isDropMenuOpen}
                    handleCloseSideMenu={handleCloseSideMenu}
                  />
                     {/* <Drawer isDropMenuOpen={isDropMenuOpen} handleCloseSideMenu={() => setIsDropMenuOpen(false)} /> */}
   
                </li>
              </>
            )}
          </ul>
        </div>

        <div className="mob-header-announcement bg-gradiant">
          <img className="mob-announcement-icon" src={announcementIcon} alt="icon" />
          <div className="mob-announcement-text">
            Enjoy Unmatched Betting Excitement and Access 500+ Casino and Online Games
          </div>
          <div className="mob-announcement-date">{currentDate}</div>
        </div>


        <div className="header-bottom col-md-12">
          <ul className="list-unstyled horizontal-scroll">
            {/* <li className="nav-item active">
              <a href="/" className={location.pathname === "/" ? "active" : ""}>
                <b>Home</b>
              </a>
            </li> */}
            <li className="nav-item active">
              <a
                href="/in-play"
                className={location.pathname.includes("in-play") ? "active" : ""}
              >
                <img src={InPlayIcon} alt="In-Play" className="icon" /><br />
                <b>In-Play</b>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/games/cricket"
                className={location.pathname.includes("cricket") ? "active" : ""}
              >
                <img src={cricketIcon} alt="In-Play" className="icon" /><br />
                <b>Cricket</b>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/games/soccer"
                className={location.pathname.includes("soccer") ? "active" : ""}
              >
                <img src={soccerIcon} alt="soccer" className="icon" /><br />
                <b>Soccer</b>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/games/tennis"
                className={location.pathname.includes("tennis") ? "active" : ""}
              >
                <img src={tennisIcon} alt="tennis" className="icon" /><br />
                <b>Tennis</b>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/live-casino"
                className={
                  location.pathname.includes("live-casino") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={pokerChip} alt="live-casino" className="icon" /><br />
                <span>
                  <b>Live Casino</b>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("horse") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={horseIcon} alt="horse" className="icon" /><br />
                <b>Horse</b>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("indian-poker") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={clubCards} alt="indian-poker" className="icon" /><br />
                <b>Indian Poker II</b>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("aviator") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={aviator} alt="aviator" className="icon" /><br />
                <span>
                  <b>Aviator</b>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("ae-sexy") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={pokerChip} alt="ae-sexy" className="icon" /><br />
                <span>
                  <b>Ae Sexy</b>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("evolution") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={pokerChip} alt="evolution" className="icon" /><br />
                <span>
                  <b>Evolution</b>
                </span>
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("vivo") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={pokerChip} alt="vivo" className="icon" /><br />
                <span>
                  <b>Vivo</b>
                </span>
              </a>
            </li> */}
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("bet-games") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={pokerChip} alt="bet-games" className="icon" /><br />
                <span>
                  <b>Bet Games</b>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  location.pathname.includes("casino-III") && !searchParams.get("name")
                    ? "active"
                    : ""
                }
              >
                <img src={pokerChip} alt="casino-III" className="icon" /><br />
                <span>
                  <b>Casino III</b>
                </span>
              </a>
            </li>
          </ul>
        </div>
    </>
  );
};

export default MobileHeader;
