import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  WrapItem,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyFancyFor, buttonColors } from '../../utils/constants';
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from './profitLoss';
import CustomInput from '../../components/CustomInput';
import {
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from '../../service/cricket';
import {
  placeSoccerBetAPI,
  placeTennisBetAPI,
} from '../../service/soccer&tennis';
import { placeCasinoBetAPI } from '../../service/casino';
import { getButtonValuesAPI } from '../../service/AuthService';
import NumberInputPlusMinus from '../../components/NumberInput';

const BetPlaceContainer = ({ game, eventId }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const selectedEventForBet_ = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const selectedEventForBet = useMemo(
    () => selectedEventForBet_,
    [selectedEventForBet_]
  );
  var { amountButtonValues } = useSelector((state) => state.gameData);
  var gameSettings_ = useSelector((state) => state.gameData[game + 'Settings']);
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }

  const getProfit = (value) => {
    let profit;
    let loss;
    let selectedAmount_ = value;
    if (game == 'cricket' && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == 'casino' || game == 'tennis' || game == 'soccer') {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == 'cricket' && selectedEventForBet.isFancy) {
      if (
        applyFancyFor.find(
          (item) =>
            item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
        )
      ) {
        if (selectedEventForBet?.type == 'lay') {
          profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
        } else {
          profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
        }
      } else {
        profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
      }
    } else {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    return {
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
    };
  };

  const setSelectedAmount = (value, type) => {
    console.log(value, 'value');
    if (type == 'CHANGE') {
      dispatch({
        type: 'gameData/setSelectedEventForBet',
        payload: {
          amount: value,
          profit: getProfit(value)?.profit,
          loss: getProfit(value)?.loss,
        },
      });
    } else {
      dispatch({
        type: 'gameData/setSelectedEventForBet',
        payload: {
          amount: parseInt(selectedEventForBet?.amount) + value,
          profit: getProfit(parseInt(selectedEventForBet?.amount) + value)
            ?.profit,
          loss: getProfit(parseInt(selectedEventForBet?.amount) + value)?.loss,
        },
      });
    }
  };

  const handleOddUpdate = (e, value) => {
    if (selectedEventForBet?.marketName !== 'MATCH_ODDS') return;
  
    let odd;
    if (value !== undefined) {
      odd = (parseFloat(selectedEventForBet.selectedOdd) + value).toFixed(2);
    } else {
      odd = e.target.value;
    }
  
    if (!String(odd).match(/^[0-9]*\.?[0-9]*$/)) odd = 0;
  
    let profit, loss;
    const selectedAmount = selectedEventForBet.selectedAmount || 1000;
  
    if (odd > 0) {
      if (selectedEventForBet.isFancy && game === 'cricket') {
        ({ profit, loss } = cricketFancyPL(odd, selectedAmount, selectedEventForBet.type));
      } else if (selectedEventForBet.isBookmaker && game === 'cricket') {
        ({ profit, loss } = cricketBookMakerPL(odd, selectedAmount, selectedEventForBet.type));
      } else if (['casino', 'tennis', 'soccer'].includes(game)) {
        ({ profit, loss } = casinoPL(odd, selectedAmount, selectedEventForBet.type));
      }
    }
  
    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload: {
        selectedOdd: odd || 0,
        profit: odd ? parseFloat(profit).toFixed(2) : null,
        loss: odd ? parseFloat(loss).toFixed(2) : null,
      },
    });
  };
  
  const handleAmountUpdate = (e, value) => {
    let newAmount;
    if (value !== undefined) {
      newAmount = parseFloat(selectedEventForBet.amount) + value;
    } else {
      newAmount = e.target.value;
    }
  
    if (newAmount > 1000000) newAmount = 1000000;
    if (newAmount < 0) newAmount = 0;
  
    setSelectedAmount(newAmount, 'CHANGE');
  };
  

  const resetBet = () => {
    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload: null,
    });
  };

  const placeBet = async () => {
    let delay;
    setIsLoading(true);

    if (
      selectedEventForBet.marketName == 'MATCH_ODDS' ||
      selectedEventForBet.marketName == 'BOOKMAKER_ODDS_2' ||
      selectedEventForBet.marketName == 'BOOKMAKER_ODDS_1'
    ) {
      delay = gameSettings?.delayOdds;
    } else {
      delay = gameSettings?.delaySession;
    }

    let selectedAmount_ = selectedEventForBet?.amount;
    if (selectedEventForBet.selectedOdd <= 0) {
      toast({
        description: 'Please select a valid odd',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      setIsLoading(false);
      return;
    }
    if (game == 'cricket' || game == 'tennis' || game == 'soccer') {
      if (
        (selectedEventForBet.marketName == 'MATCH_ODDS' &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == 'BOOKMAKER_ODDS_2' &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == 'FANCY_ODDS' &&
          gameSettings?.sessionMinStack > selectedAmount_) ||
        (selectedEventForBet.marketName == 'FANCY_ODDS_2' &&
          gameSettings?.sessionMinStack > selectedAmount_)
      ) {
        toast({
          description: `Minimum bet amount is ${
            selectedEventForBet.marketName == 'FANCY_ODDS_2'
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == 'FANCY_ODDS'
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == 'BOOKMAKER_ODDS_2'
              ? gameSettings?.bookmakerMinStack
              : gameSettings?.minStack
          }`,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        setIsLoading(false);
        return;
      }
      if (
        (selectedEventForBet.marketName == 'MATCH_ODDS' &&
          gameSettings?.maxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == 'BOOKMAKER_ODDS_2' &&
          gameSettings?.bookmakerMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == 'FANCY_ODDS' &&
          gameSettings?.sessionMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == 'FANCY_ODDS_2' &&
          gameSettings?.sessionMaxStack < selectedAmount_)
      ) {
        toast({
          description: `Maximum bet amount is ${
            selectedEventForBet.marketName == 'FANCY_ODDS_2'
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == 'FANCY_ODDS'
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == 'BOOKMAKER_ODDS_2'
              ? gameSettings?.bookmakerMaxStack
              : gameSettings?.maxStack
          }`,

          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        setIsLoading(false);
        return;
      }
    }

    let profit;
    let loss;
    if (game == 'cricket' && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == 'casino' || game == 'tennis' || game == 'soccer') {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else {
      profit = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    let payload = {
      amount: selectedAmount_,
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
      marketId: selectedEventForBet.marketId,
      selectionId: selectedEventForBet.selectedId,
      type: selectedEventForBet.type,
      eventId: parseInt(selectedEventForBet.eventId),
      runnerName: selectedEventForBet.runnerName,
      gameType:
        selectedEventForBet.marketName != 'MATCH_ODDS' ||
        selectedEventForBet.marketName != 'BOOKMAKER_ODDS_1' ||
        selectedEventForBet.marketName != 'BOOKMAKER_ODDS_2'
          ? selectedEventForBet.gameType
          : selectedEventForBet.marketName,
      marketName: selectedEventForBet.marketName,
    };

    if (game == 'casino') {
      payload.casinoGames = eventId;
    }
    if (selectedEventForBet.type == 'back') {
      payload['back'] = parseFloat(selectedEventForBet.selectedOdd);
    } else {
      payload['lay'] = parseFloat(selectedEventForBet.selectedOdd);
    }
    if (game == 'cricket' && !selectedEventForBet.isFancy) {
      const { response, code, data } = await placeCricketBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
      } else if (code == 'ERR_BAD_RESPONSE') {
        toast({
          title: 'Something Went Wrong ',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: response || 'Something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      }
    } else if (game == 'cricket' && selectedEventForBet.isFancy) {
      let fancyPayload = {
        amount: selectedAmount_,
        eventId: parseInt(selectedEventForBet.eventId),
        marketId: selectedEventForBet.marketId,
        marketName:
          selectedEventForBet.marketName != 'MATCH_ODDS' ||
          selectedEventForBet.marketName != 'BOOKMAKER_ODDS_1' ||
          selectedEventForBet.marketName != 'BOOKMAKER_ODDS_2'
            ? selectedEventForBet.gameType
            : selectedEventForBet.marketName,
        gameType: selectedEventForBet.gameType,
      };

      if (selectedEventForBet.type === 'lay') {
        fancyPayload['layRate'] = selectedEventForBet.selectedRate;
        fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
      } else {
        fancyPayload['backRate'] = selectedEventForBet.selectedRate;
        fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
      }

      if (
        applyFancyFor.find(
          (item) =>
            item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
        )
      ) {
        if (selectedEventForBet?.type == 'lay') {
          fancyPayload.profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          fancyPayload.loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
        } else {
          fancyPayload.profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          fancyPayload.loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
        }
      } else {
        fancyPayload.profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        fancyPayload.loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
        fancyPayload.selectionId = selectedEventForBet.selectedId;
        fancyPayload.runnerName = selectedEventForBet.runnerName;
        if (selectedEventForBet.type === 'lay') {
          fancyPayload['layRate'] = selectedEventForBet.selectedOdd;
        } else {
          fancyPayload['backRate'] = selectedEventForBet.selectedOdd;
        }
      }

      const { response, code, data } = await placeFancyCricketBetAPI(
        fancyPayload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: 'gameData/setSelectedEventForBet',
          payload: null,
        });
      } else if (code == 'ERR_BAD_RESPONSE') {
        toast({
          title: 'Something Went Wrong ',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: response || 'Something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      }
    } else if (game == 'soccer') {
      const { response, code } = await placeSoccerBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: 'gameData/setSelectedEventForBet',
          payload: null,
        });
        dispatch({
          type: 'gameData/setSelectedEventForBet',
          payload: null,
        });
      } else if (code == 'ERR_BAD_RESPONSE') {
        toast({
          title: 'Something Went Wrong ',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: response || 'Something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      }
    } else if (game == 'tennis') {
      const { response, code } = await placeTennisBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: 'gameData/setSelectedEventForBet',
          payload: null,
        });
      } else if (code == 'ERR_BAD_RESPONSE') {
        toast({
          title: 'Something Went Wrong ',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: response || 'Something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      }
    } else if (game == 'casino') {
      const { response, code } = await placeCasinoBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: 'gameData/setSelectedEventForBet',
          payload: null,
        });
      } else if (code == 'ERR_BAD_RESPONSE') {
        toast({
          title: 'Something Went Wrong ',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          title: response || 'Something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      }
    }

    setTimeout(async () => {
      dispatch({
        type: 'gameData/setSelectedEventForBet',
        payload: null,
      });
      setIsLoading(false);
    }, delay * 1000);
  };

  return (
    <Box w="100%" className="place-bet-box-new">
      <Box className="place-bet-body">
        <Flex gap="0px" flexDir="column" justify="flex-end" align="flex-end">
          <Flex
            width="100%"
            justify="flex-end"
            gap="10px"
            bg={
              selectedEventForBet?.type === 'back'
                ? 'rgba(114, 187, 239, 0.75)'
                : 'rgba(249, 148, 186, 0.75)'
            }
            padding="5px"
          >
            <Flex align="center">
              <Text fontWeight={600}>
                {selectedEventForBet?.runnerName} - MATCH ODDS
              </Text>
            </Flex>
  
            <NumberInputPlusMinus
              value={selectedEventForBet?.selectedOdd}
              onChange={handleOddUpdate}
              step={0.01}
            />
            <NumberInputPlusMinus
              value={selectedEventForBet?.amount}
              onChange={handleAmountUpdate}
              step={100}
              max={1000000}
            />
          </Flex>
          <Flex
            width="100%"
            justify="flex-end"
            gap="5px"
            bg={
              selectedEventForBet?.type === 'back'
                ? 'rgba(114, 187, 239, 0.5)'
                : 'rgba(249, 148, 186, 0.5)'
            }
            padding="5px"
          >
            {amountButtonValues[game === 'casino' ? 'casino' : 'sport'].map(
              (amt) => (
                <Box
                  className="amount-box"
                  onClick={() => setSelectedAmount(amt.value)}
                  key={amt.label}
                >
                  {amt.label}
                </Box>
              )
            )}
            <Button
              className="submit-btn"
              color="#fff"
              onClick={placeBet}
              bg="green"
              isDisabled={selectedEventForBet?.amount === 0 || isLoading}
            >
              {isLoading ? (
                <Image
                  h="30px"
                  w="30px"
                  src={require('../../assets/img/spinner.gif')}
                />
              ) : (
                'Place bet'
              )}
            </Button>
            <Button onClick={resetBet} className="reset-btn">
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
  
};

export default BetPlaceContainer;
