import React from 'react';

const WatchLiveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
    >
      <circle cx='22' cy='22' r='22' fill='url(#paint0_linear_195_3631)' />
      <path
        d='M32.2143 20.9498C33.2619 21.5546 33.2619 23.0667 32.2143 23.6716L18.0714 31.837C17.0238 32.4418 15.7143 31.6858 15.7143 30.4761L15.7143 14.1453C15.7143 12.9356 17.0238 12.1796 18.0714 12.7844L32.2143 20.9498Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_195_3631'
          x1='44'
          y1='22'
          x2='0'
          y2='22'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#4B29DD' />
          <stop offset='1' stop-color='#F44635' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WatchLiveIcon;
