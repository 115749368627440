import { Stomp } from "@stomp/stompjs";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  Redirect,
  Navigate,
} from "react-router-dom";
import PrivateOutlet from "./layout/PrivateOutlet";
import PublicOutlet from "./layout/PublicOutlet";
import AccountStatement from "./pages/accountStatement/AccountStatement";
import ActivityLog from "./pages/activityLog/ActivityLog";
import BetHistory from "./pages/betHistory/BetHistory";
import CasinoList from "./pages/casino/CasinoList";
import Cards32A from "./pages/casino/games/32Cards-A";
import Cards32B from "./pages/casino/games/32Cards-B";
import JudgementCards3 from "./pages/casino/games/3CardsJudgement";
import AmarAkbarAnthony from "./pages/casino/games/AmabAkbarAnthony";
import AndarBahar from "./pages/casino/games/AndarBahar";
import AndarBahar2 from "./pages/casino/games/AndarBahar-2";
import BollywoodCasino from "./pages/casino/games/BollywoodCasino";
import CasinoMeter from "./pages/casino/games/CasinoMeter";
import CasinoQueen from "./pages/casino/games/CasinoQueen";
import DragonTiger1Day from "./pages/casino/games/DragonTiger1Day";
import DragonTiger202 from "./pages/casino/games/DragonTiger202";
import DragonTiger201 from "./pages/casino/games/DragonTiger201";
import InstantWorli from "./pages/casino/games/InstantWorli";
import Lucky7A from "./pages/casino/games/Lucky7A";
import Lucky7B from "./pages/casino/games/Lucky7B";
import Poker20 from "./pages/casino/games/Poker20-20";
import Race2020 from "./pages/casino/games/Race2020";
import TeenPatti from "./pages/casino/games/TeenPatti";
import TeenPatti1Day from "./pages/casino/games/Teenpatti1Day";
import CasinoResult from "./pages/casinoResults/CasinoResults";

import Dashboard from "./pages/dashboard";
import GamePage from "./pages/game/GamePage";

import ProfitLossReport from "./pages/profitLossReport/ProfitLossReport";
import UnSetteledBet from "./pages/unSetteledBet/UnSetteledBet";
import Video from "./pages/Video";

import store, { saveState } from "./redux/store";
import {
  CASINO_SERVICE_URL,
  createSocket,
  CRICKET_SERVICE_URL,
  IS_PROD,
  SOCCER_AND_TENNIS_SERVICE_URL,
} from "./utils/constants";
import DragonTigerLion from "./pages/casino/games/DragonTigerLion";
import TeenPattiTest from "./pages/casino/games/TeenPattiTest";
import TeenPattiOpen from "./pages/casino/games/TeenPattiOpen";
import CasinoWar from "./pages/casino/games/CasinoWar";
import Five5Cricket from "./pages/casino/games/Five-5Cricket";
import Cricket2020 from "./pages/casino/games/Cricket20-20";
import Poker1Day from "./pages/casino/games/Poker1Day";
import Pokerr6Players from "./pages/casino/games/Poker6Players";
import Baccart from "./pages/casino/games/Baccart";
import Baccart2 from "./pages/casino/games/Baccarat2";
import Worli from "./pages/casino/games/WorliMatka";
import FiveCricket from "./pages/casino/games/Five-5Cricket";
import SuperOver from "./pages/casino/games/SuperOver";

import SecurityAuth from "./pages/settings/SecurityAuth";
import LoginAuth from "./pages/login/loginAuth";
import ChangeButtonValue from "./pages/ChangeButtonValue";
import ChangePasswordPage from "./pages/ChangePasswordPage";

import LandingPage from "./pages/landingPage";
import { setAmountButtonValues } from "./redux/features/gameSlice";
import { getButtonValuesAPI } from "./service/AuthService";
import RulesPage from "./pages/rulesPage";
import { getFingerprint, getService, hashFingerprint } from "./utils/commonFunctions";
import CGUnSetteledBet from "./pages/unSetteledBet/CGunsettledBet";
import CGBetHistory from "./pages/betHistory/CGbetHistory";
import CGAccountStatement from "./pages/accountStatement/CGaccountStatement";
import BaccaratList from "./pages/casino/BaccaratList";
import Cards32List from "./pages/casino/Cards32List";
import TeenPattiList from "./pages/casino/TeenpattiList";
import PokerList from "./pages/casino/PokerList";
import Lucky7List from "./pages/casino/Lucky7List";

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector((state) => state.account.userData);

  const { gameKey } = useSelector((state) => state.gameData);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (location.pathname.includes("live-casino")) {
      if (gameKey) {
        createSocket(
          "casino",
          dispatch,
          gameKey,
          getService(location.pathname).port,
          getService(location.pathname).service
        );
      }
    }
  }, [gameKey, dispatch]);

  const getMatches = useCallback(
    (id) => {
      const stompURL =
        id === "cricket"
          ? !IS_PROD
            ? CRICKET_SERVICE_URL.replace("http", "ws").replace(
                "/api",
                "/websocket"
              )
            : CRICKET_SERVICE_URL.replace("http", "ws").replace(
                "/cricket/api",
                "/cricket"
              ) + "/websocket"
          : id === "casino"
          ? !IS_PROD
            ? CASINO_SERVICE_URL.replace("http", "ws").replace(
                "/api",
                "/websocket"
              )
            : CASINO_SERVICE_URL.replace("http", "ws").replace("/v1", "") +
              "/websocket"
          : !IS_PROD
          ? SOCCER_AND_TENNIS_SERVICE_URL.replace("http", "ws").replace(
              "/api",
              "/websocket"
            )
          : SOCCER_AND_TENNIS_SERVICE_URL.replace("http", "ws").replace(
              "/tas/api",
              "/tas"
            ) + "/websocket";

      let stompClient = Stomp.client(stompURL);
      stompClient.debug = () => {
        return null;
      };
      stompClient.connect({}, (frame) => {
        stompClient.subscribe("/live/liveMatches", function (message) {
          try {
            let data = JSON.parse(message.body);

            if (id === "cricket") {
              dispatch({
                type: "gameData/setCricketGames",
                payload: data,
              });
            }

            if (id === "soccer" || id === "tennis") {
              dispatch({
                type: "gameData/setSoccerGames",
                payload: data.soccerAllMatchesResponseDTOS,
              });
              dispatch({
                type: "gameData/setTennisGames",
                payload: data.tennisAllMatchesResponseDTOS,
              });
            }
          } catch (e) {
            console.log(e);
          }
        });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (location.pathname === "/" || location.pathname.includes("in-play")) {
      getMatches("cricket");
      getMatches("soccer");
      getMatches("tennis");
    }
  }, [getMatches, location.pathname]);
  useEffect(() => {
    if (location.pathname.includes("tennis")) {
      getMatches("tennis");
    } else if (location.pathname.includes("soccer")) {
      getMatches("soccer");
    }
  }, [getMatches, location.pathname]);

  useEffect(() => {
    if (userData?.token && userData?.resetRequired) {
      dispatch({
        type: "accountData/setChangePopSate",
        payload: true,
      });
    }
  }, [dispatch, userData]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch({
        type: "accountData/setUserData",
        payload: user,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.includes("cricket")) {
      getMatches("cricket");
    }
  }, [getMatches, location.pathname]);

  const getButtonValues = async () => {
    const { response, code } = await getButtonValuesAPI();
    if (code == 200) {
      const convertedData = {
        casino: [],
        sport: [],
      };
      for (const key in response) {
        if (key.startsWith("buttonValueCasino")) {
          const index = key.replace("buttonValueCasino", "");
          const label = response["buttonNameCasino" + index];
          const value = response[key];
          convertedData.casino.push({ label, value });
        } else if (key.startsWith("buttonValue")) {
          const index = key.replace("buttonValue", "");
          const label = response["buttonName" + index];
          const value = response[key];
          convertedData.sport.push({ label, value });
        }
      }
      dispatch(setAmountButtonValues(convertedData));
    }
  };

  useEffect(() => {
    if (userData?.token) {
      getButtonValues();
    }
  }, [userData.token]);

  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, []);

  useEffect(() => {
    const fingerprintData = getFingerprint();
    const fingerprintHash = hashFingerprint(fingerprintData);
    console.log(fingerprintHash, 'fingerprintHash');
  }, []);

  return userData?.token ? (
    <PrivateOutlet>
      {/* {isLoading && <Loading />} */}
      <Routes>
    
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/in-play" element={<Dashboard />} />
        <Route exact path="/games/:sportName" element={<Dashboard />} />
        <Route exact path="/profile" element={<Dashboard />} />
        <Route exact path="/game/:game/:id" element={<GamePage />} />
        <Route path="/live-casino" element={<CasinoList />} />
        <Route path="/baccarat-list" element={<BaccaratList/>} />
        <Route path="/cards32-list" element={<Cards32List/>} />
        <Route path="/teenpatti-list" element={<TeenPattiList/>} />
        <Route path="/poker-list" element={<PokerList/>} />
        <Route path="/lucky7-list" element={<Lucky7List/>} />
        <Route path="/live-casino/teenpatti/20-20" element={<TeenPatti />} />
        <Route
          path="/live-casino/teenpatti/1-day"
          element={<TeenPatti1Day />}
        />
        <Route path="/live-casino/teenpatti/test" element={<TeenPattiTest />} />
        <Route path="/live-casino/teenpatti/open" element={<TeenPattiOpen />} />
        <Route
          path="/live-casino/dragon-tiger/20-20"
          element={<DragonTiger201 />}
        />
        <Route
          path="/live-casino/dragon-tiger/20-20/2"
          element={<DragonTiger202 />}
        />
        <Route path="/live-casino/card-32/a" element={<Cards32A />} />
        <Route path="/live-casino/card-32/b" element={<Cards32B />} />
        <Route path="/live-casino/race/20-20" element={<Race2020 />} />
        <Route path="/live-casino/cn/queen" element={<CasinoQueen />} />
        <Route
          path="/live-casino/dragon-tiger/1-day"
          element={<DragonTiger1Day />}
        />
        <Route path="/live-casino/lucky7/a" element={<Lucky7A />} />
        <Route path="/live-casino/lucky7/b" element={<Lucky7B />} />
        <Route path="/live-casino/andar-bahar" element={<AndarBahar />} />
        <Route path="/live-casino/andar-bahar/2" element={<AndarBahar2 />} />
        <Route
          path="/live-casino/3cardsjudgement"
          element={<JudgementCards3 />}
        />
        <Route
          path="/live-casino/cn/bollywood-table"
          element={<BollywoodCasino />}
        />
        <Route
          path="/live-casino/andar-bahar/aaa"
          element={<AmarAkbarAnthony />}
        />
        <Route path="/live-casino/poker/20-20" element={<Poker20 />} />
        <Route path="/live-casino/worli/2" element={<InstantWorli />} />
        <Route path="/live-casino/cn/meter" element={<CasinoMeter />} />
        <Route
          path="/live-casino/dragon-tiger/dtl"
          element={<DragonTigerLion />}
        />
        <Route path="/live-casino/cn/war" element={<CasinoWar />} />
        <Route path="/live-casino/cricket/five" element={<FiveCricket />} />
        <Route path="/live-casino/cricket/20-20" element={<Cricket2020 />} />
        <Route path="/live-casino/poker" element={<Poker1Day />} />
        <Route
          path="/live-casino/poker/poker-6-player"
          element={<Pokerr6Players />}
        />
        <Route path="/live-casino/baccarat" element={<Baccart />} />
        <Route path="/live-casino/baccarat/2" element={<Baccart2 />} />
        <Route path="/live-casino/worli" element={<Worli />} />
        <Route path="/live-casino/cricket/super-over" element={<SuperOver />} />
        <Route
          path="/report/account-statement"
          element={<AccountStatement />}
        />{" "}
        <Route
          path="/report/cg/account-statement"
          element={<CGAccountStatement />}
        />
        <Route path="/report/bet-history" element={<BetHistory />} />
        <Route path="/report/cg/bet-history" element={<CGBetHistory />} />
        <Route
          path="/report/profit-loss-report"
          element={<ProfitLossReport />}
        />
        <Route path="/report/unsetteled-bet" element={<UnSetteledBet />} />
        <Route path="/report/cg/unsetteled-bet" element={<CGUnSetteledBet />} />
        <Route path="/report/activity-log" element={<ActivityLog />} />
        <Route path="/report/casino-result" element={<CasinoResult />} />
        <Route path="/report/changebtnvalue" element={<ChangeButtonValue />} />
        <Route path="/report/changepassword" element={<ChangePasswordPage />} />
        <Route path="/rules" element={<RulesPage />} />
        <Route path="/settings/secureauth" element={<SecurityAuth />} />
      </Routes>
    </PrivateOutlet>
  ) : (
    <PublicOutlet>
      {/* {isLoading && <Loading />} */}
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/loginauth/2" element={<LoginAuth />} />
        <Route exact path="video" element={<Video />} />
        <Route path="/game/:game/:id" element={<GamePage />} />
        <Route path="/live-casino" element={<CasinoList />} />
        <Route path="/live-casino/dt2" element={<DragonTiger202 />} />
        <Route path="/live-casino/teenPatti20" element={<TeenPatti />} />
        <Route path="/live-casino/32cards-A" element={<Cards32A />} />
        <Route path="/live-casino/32cards-B" element={<Cards32B />} />
        <Route path="/live-casino/race20" element={<Race2020 />} />
        <Route path="/live-casino/casinoqueen" element={<CasinoQueen />} />
        <Route path="/live-casino/dt1" element={<DragonTiger201 />} />
        <Route
          path="/live-casino/dragonTigerOneDay"
          element={<DragonTiger1Day />}
        />
        <Route path="/live-casino/lucky7a" element={<Lucky7A />} />
        <Route path="/live-casino/lucky7b" element={<Lucky7B />} />
        <Route path="/live-casino/andarbahar" element={<AndarBahar />} />
        <Route path="/live-casino/abj" element={<AndarBahar2 />} />
        <Route
          path="/live-casino/3cardsjudgement"
          element={<JudgementCards3 />}
        />
        <Route path="/live-casino/btable" element={<BollywoodCasino />} />
        <Route path="/live-casino/aaa" element={<AmarAkbarAnthony />} />
        <Route path="/live-casino/teen" element={<TeenPatti1Day />} />
        <Route path="/live-casino/poker20" element={<Poker20 />} />
        <Route path="/live-casino/worli2" element={<InstantWorli />} />
        <Route path="/live-casino/cmeter" element={<CasinoMeter />} />
        <Route path="/live-casino/dtl" element={<DragonTigerLion />} />
        <Route path="/live-casino/teentest" element={<TeenPattiTest />} />
        <Route path="/live-casino/teenopen" element={<TeenPattiOpen />} />
        <Route path="/live-casino/war" element={<CasinoWar />} />
        <Route path="/live-casino/cricketv3" element={<Five5Cricket />} />
        <Route path="/live-casino/cmatch20" element={<Cricket2020 />} />
        <Route path="/live-casino/poker" element={<Poker1Day />} />
        <Route path="/live-casion/poker6" element={<Pokerr6Players />} />
        <Route path="/live-casion/baccarat" element={<Baccart />} />
        <Route path="/live-casion/baccarat2" element={<Baccart2 />} />
      </Routes>
    </PublicOutlet>
  );
}
