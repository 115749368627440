import { Box, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import TabsContent from './tabsContent';

const SportOddsTable = ({ sportName }) => {
  const [sports, setSports] = React.useState([
    {
      name: 'Cricket',
      id: 'cricket',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/4.png',
    },
    {
      name: 'Football',
      id: 'soccer',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/1.png',
    },
    {
      name: 'Tennis',
      id: 'tennis',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/2.png',
    },
    {
      name: 'Basketball',
      id: 'basketball',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/15.png',
    },
    {
      name: 'Volleyball',
      id: 'volleyball',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/18.png',
    },
    {
      name: 'Snooker',
      id: 'snooker',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/59.png',
    },
    // {
    //   name: "Handball",
    //   id: "handball",
    // },
    {
      name: 'Horse Racing',
      id: 'horse_racing',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/10.png',
    },
    // {
    //   name: "Handball",
    //   id: null,
    // },
    // {
    //   name: "Ice Hockey",
    //   id: null,
    // },
  ]);

  useEffect(() => {
    if (sportName) {
      const _sports = [...sports];
      const filtered = _sports.filter((k) => k.id === sportName);

      setSports(filtered);
    }
  }, []);

  {console.log(sports, "data not found 22")}
  return (
    <>
      {sports.length === 0 && <h5 className='norecords'>No Data Found</h5>}
      {sports.map(
        (sport) =>
          sport.id === sportName && (
            <Box className='sport-box' padding={0}>
              <TabsContent
                color='lightgray'
                id={sportName}
                icon={
                  <Image
                    h='15px'
                    w='15px'
                    objectFit='contain'
                    src={require(`../../assets/img/icons/${sport.name} Icon.svg`)}
                  />
                }
              />
            </Box>
          )
      )}
    </>
  );
};

export default SportOddsTable;
