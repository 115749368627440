import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import { BiFootball } from "react-icons/bi";
import { MdSportsCricket, MdSportsTennis } from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { getAllCricketMatchListAPI } from "../../service/cricket";
import {
  getAllSoccerMatchListAPI,
  getAllTennisMatchListAPI,
} from "../../service/soccer&tennis";
import { FaHorse, FaPlusSquare } from "react-icons/fa";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { pokerGameList, sidebarCasinoList } from "../../utils/constants";
import "./sidebar.css";

import InPlayIcon from "../../assets/img/InPlay.svg";
import multiMarkets from "../../assets/img/multi-markets.svg";
import winner from "../../assets/img/winner.svg";
import election from "../../assets/img/election.svg";
import clubCards from "../../assets/img/club-Cards.svg";
import pokerChip from "../../assets/img/poker-chip.svg";
import aviator from "../../assets/img/aviator.svg";
import horseIcon from "../../assets/icons/horse.png";
import electionIcon from "../../assets/img/election.svg";
import cricketIcon from "../../assets/icons/cricket.png";
import soccerIcon from "../../assets/icons/soccer.png";
import tennisIcon from "../../assets/icons/tennis.png";

const AllSportsLinkItems = [
  {
    name: "cricket",
    icon: (
      <img alt="cricket" style={{ height: 22, width: 22 }} src={cricketIcon} />
    ),
    isMap: false,
  },
  {
    name: "soccer",
    icon: (
      <img alt="soccer" style={{ height: 22, width: 22 }} src={soccerIcon} />
    ),
    isMap: true,
  },
  {
    name: "tennis",
    icon: (
      <img alt="tennis" style={{ height: 22, width: 22 }} src={tennisIcon} />
    ),
    isMap: true,
  },
];
const RacingLinkItems = [
  { name: "Horse racing", icon: FaHorse, isMap: true },
  { name: "Slot Game", icon: FaHorse, isMap: true },
  { name: "Live Casino", icon: FaHorse, isMap: true, link: "/live-casino" },
];

const sportsIcons = [cricketIcon, soccerIcon, tennisIcon];

const Sidebar = ({ onClose, isSidebarOpen, setIsSidebarOpen }) => {
  const naviagte = useNavigate();
  const [openAccordions, setOpenAccordions] = useState({});
  const [gameData, setGameData] = useState({
    cricket: [],
    soccer: [],
    tennis: [],
  });
  const getCricketData = async () => {
    const res = await getAllCricketMatchListAPI();
    if (res && res?.code === 200) {
      setGameData((prev) => ({ ...prev, cricket: res.response }));
    }
  };
  const getSoccerData = async () => {
    const res = await getAllSoccerMatchListAPI();
    if (res && res?.code === 200) {
      setGameData((prev) => ({ ...prev, soccer: res.response }));
    }
  };

  const getTennisData = async () => {
    const res = await getAllTennisMatchListAPI();
    if (res && res?.code === 200) {
      setGameData((prev) => ({ ...prev, tennis: res.response }));
    }
  };

  useEffect(() => {
    getCricketData();

    return () => setIsSidebarOpen(false);
  }, []);

  const navigateToGame = (id, gameEvent) => {
    setIsSidebarOpen(false);
    setOpenAccordions({});
    naviagte(`/game/${id}/${gameEvent[id + "Id"]}?gameId=${gameEvent.gameId}`, {
      state: {
        gameEvent: {
          ...gameEvent,
          eventTime: gameEvent.eventDate
            ? gameEvent.eventDate
            : gameEvent.eventTime
            ? gameEvent.eventTime
            : gameEvent.startDate,
        },
      },
    });
  };

  const handleAccordionChange = (sportName) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [sportName]: !prev[sportName],
    }));
  };

console.log("sidebar opened")
  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
      
      <Text className="sidebar-title">Popular</Text>
      {/* <Accordion defaultIndex={[1]}>
        <AccordionItem>
          <AccordionButton
            className='sidebar-heading'
            aria-controls='events'
            aria-expanded='true'
            role='button'
          >
            <Box fontSize='sm' as='span' flex='1' textAlign='left'>
              Others
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel p={0}>
            <nav className='collapse casino show'>
              <ul>
                <li className='nav-item '>
                  <a href='javascript:void(0);' className='nav-link'>
                    <span className='new-launch-text'>Live Casino</span>
                  </a>
                </li>
                {sidebarCasinoList.map((game) => (
                  <li className='nav-item'>
                    <a href='javascript:void(0);' className='nav-link'>
                      <span className='new-launch-text'>{game.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </AccordionPanel>
        </AccordionItem>
      </Accordion> */}

      <li className="nav-item">
        <a href="/in-play" className="nav-link">
          <img src={InPlayIcon} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">In-Play</span>
        </a>
      </li>

      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={winner} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">IPL Winner 2024</span>
        </a>
      </li>

      {AllSportsLinkItems.map((sportItem, index) => (
        <Accordion key={index} allowToggle index={openAccordions[sportItem.name] ? [0] : []}>
          <AccordionItem>
            <AccordionButton
              className="sidebar-heading"
              aria-controls={`events-${sportItem.name}`}
              aria-expanded={index === 0 ? "true" : "false"}
              role="button"
              onClick={() => handleAccordionChange(sportItem.name)}
            >
              <Box
                fontSize="sm"
                as="span"
                flex="1"
                textAlign="left"
                display="flex"
              >
                {/* <Image src={sportsIcons} alt="Icon" className="icon" /> */}
                {sportItem.icon}
                <Text
                  padding="5px 0 0 5px"
                  style={{ marginLeft: "15px", fontWeight: "bold" }}
                >
                  {" "}
                  {sportItem.name.toUpperCase()}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4} id={`events-${sportItem.name}`}>
              <Box maxH="70vh" overflowY="auto" className="menu_box">
                <Accordion
                  p="0"
                  allowToggle
                >
                  {gameData[sportItem.name].map((event, eventIndex) => (
                    <AccordionItem key={eventIndex} p="0" mt="1" border="none">
                      <AccordionButton p="0" className="inner-accordion">
                        <PlusSquareIcon />
                        <Text
                          flex="1"
                          className="text-dark"
                          textAlign="left"
                          ml="1"
                          fontSize="sm"
                          onClick={() => navigateToGame(sportItem.name, event)}
                        >
                          {event.eventName}
                        </Text>
                      </AccordionButton>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}

      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={clubCards} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Club Games</span>
        </a>
      </li>

      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton
            className="sidebar-heading"
            aria-expanded="false"
            role="button"
          >
            <Box
              fontSize="sm"
              as="span"
              flex="1"
              textAlign="left"
              display="flex"
            >
              <Image src={horseIcon} alt="Icon" className="icon" />
              <Text padding="5px 0 0 5px" style={{ fontWeight: "bold" }}>
                Horse
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box></Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <li className="nav-item">
        <a href="/live-casino" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Live Casino</span>
        </a>
      </li>

      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={multiMarkets} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Multi Markets</span>
        </a>
      </li>

      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Indian Poker</span>
        </a>
      </li>
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={clubCards} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Indian Poker II</span>
        </a>
      </li>
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={aviator} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Aviator</span>
        </a>
      </li>
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">AE SEXY</span>
        </a>
      </li>
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">EVOLUTION</span>
        </a>
      </li>
     
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Vivo</span>
        </a>
      </li>
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Betgames</span>
        </a>
      </li>
      <li className="nav-item">
        <a href="#" className="nav-link">
          <img src={pokerChip} alt="In-Play" className="icon" />{" "}
          <span className="new-launch-text">Casino III</span>
        </a>
      </li>
    </div>
  );
};

export default Sidebar;
