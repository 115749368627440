import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Td,
  Text,
  Thead,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation } from 'react-router-dom';
import OddsBox from './OddsBox';
import OddsRow from './OddsRow';
import './ui.css';
import { applyFancyFor } from '../../../utils/constants';
import isEmpty from 'is-empty';
import BetPlaceContainer from '../BetPlaceContainer';
export default function MatchOdds_({
  currentKey,
  data,
  game,
  setSelectedEventForBet,
  eventId,
  bm,
  num,
  fromSession,
  soccerTennis,
}) {
  const ref = useRef();
  const selectedEventForBet_ = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const selectedEventForBet = useMemo(
    () => selectedEventForBet_,
    [selectedEventForBet_]
  );
  const convertToLacs = (num) => {
    if (num > 1000 && num <= 100000) {
      return parseInt(num / 1000) + 'K';
    }
    if (num > 100000) {
      return parseInt(num / 100000) + 'L';
    } else {
      return num;
    }
  };
  var gameSettings = useSelector((state) => state.gameData[game + 'Settings']);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const getExposure = (match) => {
    let exposure_;

    let isNotFancy =
      applyFancyFor.indexOf(data.gameType) == -1 && data.gameType != 'oddeven';
    exposure_ = gameSettings?.plExposureResponseDTOs?.filter(
      (item) => item.marketIdExternal == match.marketId
    );
    if (soccerTennis) {
      exposure_ = gameSettings?.plExposureResponseDTOs?.filter(
        (item) => item.marketIdExternal == data.marketId
      );
      if (exposure_ && exposure_?.length > 0) {
        for (const data of exposure_) {
          for (const innerData of data.runnerPlExposureDTOS) {
            if (innerData.selectionId == match.selectionId) {
              if (!isEmpty(innerData)) {
                exposure_ = innerData;
              }
            }
          }
        }
      }

      return exposure_ || {};
    } else {
      if (isNotFancy && exposure_) {
        exposure_ = exposure_[0]?.runnerPlExposureDTOS?.find(
          (item) => item.selectionId == match.selectionId
        );
        return exposure_;
      }
    }

    if (exposure_ && exposure_.length > 0) return exposure_[0];
    else return null;
  };

  const setData = (eventData) => {
    if (game == 'cricket') {
      if (eventData.isFancy || eventData.isBookmaker) {
        setSelectedEventForBet({ ...eventData, profit: 0, loss: 0, amount: 0 });
      } else {
        setSelectedEventForBet({
          ...eventData,
          marketId: data[0].marketId,
          marketName: data[0].marketName,
          profit: 0,
          loss: 0,
          amount: 0,
        });
      }
    } else {
      setSelectedEventForBet({
        ...eventData,
        marketId: data.marketId,
        marketName: data.marketName,
        eventId: data.eventId,
        profit: 0,
        loss: 0,
        amount: 0,
      });
    }
  };

  /* useEffect(() => {}, [selectedEventForBet]); */
  return (
    <Wrap w='100%'>
      {!currentKey?.startsWith('bookMaker') && (
        <WrapItem w='100%' fontSize='xs'>
          <span
            style={{
              marginLeft:
                game !== 'soccer' && currentKey?.startsWith('fancy')
                  ? '-60px'
                  : '1',
            }}
            className='row-title'
          ></span>
          {!data?.areAllThreeOddsAvailable &&
          currentKey?.startsWith('fancy') ? null : (
            <Box
              display={{ base: 'flex', md: 'flex' }}
              justifyContent='space-between'
              width='100%'
            >
              <div></div>
              {/* <Text
                w="80px"
                textColor="#17a2b8"
                fontSize="sm"
                textAlign="right"
                mr={"2"}
              >
                Min: 100 Max:
                {currentKey?.startsWith("match")
                  ? convertToLacs(gameSettings?.maxStack)
                  : convertToLacs(gameSettings?.bookmakerMaxStack)}
              </Text> */}

              <Box className='back-lay-title'>
                <Box className='oddsBoxb d-modified-b bb_1'>
                  <Text>
                    {game !== 'soccer' && currentKey?.startsWith('fancy')
                      ? 'Yes'
                      : 'BACK'}
                  </Text>
                </Box>
                <Box className='oddsBoxr d-modified-l bb_2'>
                  <Text>
                    {game !== 'soccer' && currentKey?.startsWith('fancy')
                      ? 'No'
                      : 'LAY'}
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </WrapItem>
      )}
      {((currentKey?.startsWith('match') || data?.areAllThreeOddsAvailable) &&
        game == 'soccer') ||
      (currentKey?.startsWith('match') && game == 'tennis') ? (
        data?.oddDetailsDTOS &&
        data?.oddDetailsDTOS?.map((match, index) => (
          <Box w='100%' pl={{ base: '0px', md: '10px' }}>
            <OddsRow
              setSelectedEventForBet={setData}
              data={data}
              match={match}
              index={index}
              game={game}
              eventId={eventId}
            />
          </Box>
        ))
      ) : currentKey?.startsWith('match') && game == 'cricket' ? (
        data?.map((odds) =>
          odds?.oddDetailsDTOS?.map((match, index) => (
            <Box
              w='100%'
              pl={{ base: '0px', md: '10px' }}
              className='match-list-items'
            >
              <OddsRow
                setSelectedEventForBet={setData}
                data={data[0]}
                match={match}
                index={index}
                game={game}
                eventId={eventId}
                isBookmaker={false}
              />
            </Box>
          ))
        )
      ) : currentKey?.startsWith('bookMaker') && game == 'cricket' ? (
        <Box w='100%' fontSize='xs'>
          {bm != 'bm2' ? (
            <WrapItem>
              <span
                style={{
                  marginLeft: currentKey?.startsWith('fancy') ? '-60px' : '1',
                }}
                className='row-title'
              ></span>

              {currentKey?.startsWith('fancy') ? null : (
                <Box
                  display={{ base: 'flex', md: 'flex' }}
                  justifyContent='space-between'
                  width='100%'
                >
                  <Text
                    w='80px'
                    textColor='gray.300'
                    fontSize='sm'
                    textAlign='right'
                    mr={'2'}
                    color='#17a2b8 !important'
                    width='130px'
                    display='flex'
                    alignItems='center'
                  >
                    Min: 100 Max: 5000K
                  </Text>
                  <Box className='back-lay-title'>
                    <Box className='oddsBoxb d-modified-b bb_1'>
                      <Text>
                        {currentKey?.startsWith('fancy') ? 'Yes' : 'BACK'}
                      </Text>
                    </Box>
                    <Box className='oddsBoxr d-modified-l bb_2'>
                      <Text>
                        {currentKey?.startsWith('fancy') ? 'No' : 'LAY'}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </WrapItem>
          ) : (
            <Box
              mr='1'
              fontSize='xs'
              display={{ base: 'none', md: 'flex' }}
              justifyContent='flex-end'
              pr='4px'
            >
              <Box className='oddsBoxb d-modified-b'>
                <Text>{currentKey?.startsWith('fancy') ? 'Lay' : 'BACK'}</Text>
              </Box>
              <Box className='oddsBoxr d-modified-l'>
                <Text>{currentKey?.startsWith('fancy') ? 'Back' : 'LAY'}</Text>
              </Box>
            </Box>
          )}
          {Object.keys(data)?.map((key) =>
            data[key][bm].oddDetailsDTOS.map((match, index) =>
              bm == 'bm1' ? (
                <Box
                  w='100%'
                  pl={{ base: '0px', md: '10px' }}
                  mb='2'
                  className='fancy-wrap-item'
                >
                  <OddsRow
                    ref={ref}
                    setSelectedEventForBet={(data_) => {
                      setData({
                        ...data_,
                        isBookmaker: true,
                        marketId: data[key][bm]?.marketId,
                        marketName: data[key][bm]?.marketName,
                      });
                    }}
                    num={bm == 'bm2' ? 2 : 6}
                    data={data[key][bm]}
                    match={match}
                    index={index}
                    game={game}
                    eventId={eventId}
                    isBookmaker={true}
                  />
                </Box>
              ) : bm == 'bm2' ? (
                <Grid
                  columnGap={2}
                  rowGap={2}
                  w='100%'
                  display={{ base: 'block', md: 'grid' }}
                  templateColumns='repeat(1,1fr)'
                  className='grid-fancy-box'
                  pl='8px'
                  pr='6px'
                >
                  <OddsRow
                    ref={ref}
                    setSelectedEventForBet={(data_) => {
                      setData({
                        ...data_,
                        isBookmaker: true,
                        marketId: data[key][bm]?.marketId,
                        marketName: data[key][bm]?.marketName,
                      });
                    }}
                    num={bm == 'bm2' ? 2 : 6}
                    data={data[key][bm]}
                    match={match}
                    index={index}
                    game={game}
                    eventId={eventId}
                    isBookmaker={true}
                  />

                  {/* <GridItem colSpan={2}>
                    <Grid w="100%" templateColumns="repeat(2,1fr)">
                      <GridItem w="100%">
                        <Box
                          mr="1"
                          fontSize="xs"
                          display={{ base: "none", md: "flex" }}
                          justifyContent="flex-end"
                          pr="4px"
                        >
                          <Box className="oddsBoxr d-modified-l">
                            <Text>
                              {currentKey?.startsWith("fancy") ? "No" : "LAY"}
                            </Text>
                          </Box>
                          <Box className="oddsBoxb d-modified-b">
                            <Text>
                              {currentKey?.startsWith("fancy") ? "Yes" : "BACK"}
                            </Text>
                          </Box>
                        </Box>
                      </GridItem>
                    </Grid>
                  </GridItem> */}
                </Grid>
              ) : null
            )
          )}
        </Box>
      ) : (currentKey?.startsWith('bookMaker') && game == 'tennis') ||
        (currentKey?.startsWith('bookMaker') && game == 'soccer') ? (
        <Box w='100%' fontSize='xs'>
          {bm != 'bm2' ? (
            <WrapItem>
              <span
                style={{
                  marginLeft: currentKey?.startsWith('fancy') ? '-60px' : '1',
                }}
                className='row-title'
              ></span>

              {currentKey?.startsWith('fancy') ? null : (
                <Box
                  display={{ base: 'flex', md: 'flex' }}
                  justifyContent='space-between'
                  width='100%'
                >
                  <Text
                    w='80px'
                    textColor='gray.300'
                    fontSize='sm'
                    textAlign='right'
                    mr={'2'}
                    color='#17a2b8 !important'
                    width='130px'
                    display='flex'
                    alignItems='center'
                  >
                    Min: 100 Max: 5000K
                  </Text>
                  <Box className='back-lay-title'>
                    <Box className='oddsBoxb d-modified-b bb_1'>
                      <Text>
                        {currentKey?.startsWith('fancy') ? 'Yes' : 'BACK'}
                      </Text>
                    </Box>
                    <Box className='oddsBoxr d-modified-l bb_2'>
                      <Text>
                        {currentKey?.startsWith('fancy') ? 'No' : 'LAY'}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </WrapItem>
          ) : (
            <Box
              mr='1'
              fontSize='xs'
              display={{ base: 'none', md: 'flex' }}
              justifyContent='flex-end'
              pr='4px'
            >
              <Box className='oddsBoxb d-modified-b'>
                <Text>{currentKey?.startsWith('fancy') ? 'LAY' : 'BACK'}</Text>
              </Box>
              <Box className='oddsBoxr d-modified-l'>
                <Text>{currentKey?.startsWith('fancy') ? 'BACK' : 'LAY'}</Text>
              </Box>
            </Box>
          )}
          {Object.keys(data)?.map((key) =>
            data[key][bm].oddDetailsDTOS.map((match, index) =>
              bm == 'bm1' ? (
                <Box
                  w='100%'
                  pl={{ base: '0px', md: '10px' }}
                  mb='2'
                  className='fancy-wrap-item'
                >
                  <OddsRow
                    ref={ref}
                    setSelectedEventForBet={(data_) => {
                      setData({
                        ...data_,
                        isBookmaker: true,
                        marketId: data[key][bm]?.marketId,
                        marketName: data[key][bm]?.marketName,
                      });
                    }}
                    num={bm == 'bm2' ? 2 : 6}
                    data={data[key][bm]}
                    match={match}
                    index={index}
                    game={game}
                    eventId={eventId}
                    isBookmaker={true}
                  />
                </Box>
              ) : bm == 'bm2' ? (
                <Grid
                  columnGap={2}
                  rowGap={2}
                  w='100%'
                  display={{ base: 'block', md: 'grid' }}
                  templateColumns='repeat(1,1fr)'
                  className='grid-fancy-box'
                  pl='8px'
                  pr='6px'
                >
                  <OddsRow
                    ref={ref}
                    setSelectedEventForBet={(data_) => {
                      setData({
                        ...data_,
                        isBookmaker: true,
                        marketId: data[key][bm]?.marketId,
                        marketName: data[key][bm]?.marketName,
                      });
                    }}
                    num={bm == 'bm2' ? 2 : 6}
                    data={data[key][bm]}
                    match={match}
                    index={index}
                    game={game}
                    eventId={eventId}
                    isBookmaker={true}
                  />

                  {/* <GridItem colSpan={2}>
                    <Grid w="100%" templateColumns="repeat(2,1fr)">
                      <GridItem w="100%">
                        <Box
                          mr="1"
                          fontSize="xs"
                          display={{ base: "none", md: "flex" }}
                          justifyContent="flex-end"
                          pr="4px"
                        >
                          <Box className="oddsBoxr d-modified-l">
                            <Text>
                              {currentKey?.startsWith("fancy") ? "No" : "LAY"}
                            </Text>
                          </Box>
                          <Box className="oddsBoxb d-modified-b">
                            <Text>
                              {currentKey?.startsWith("fancy") ? "Yes" : "BACK"}
                            </Text>
                          </Box>
                        </Box>
                      </GridItem>
                    </Grid>
                  </GridItem> */}
                </Grid>
              ) : null
            )
          )}
        </Box>
      ) : currentKey?.startsWith('fancy') || currentKey?.startsWith('odd') ? (
        <Grid
          ml={2}
          columnGap={2}
          rowGap={2}
          w='100%'
          display={{ base: 'block', md: 'grid' }}
          templateColumns='repeat(2,1fr)'
          className='grid-fancy-box'
        >
          <GridItem colSpan={2}>
            <Grid w='100%' templateColumns='repeat(2,1fr)'>
              <GridItem w='100%'>
                <Box
                  mr='1'
                  fontSize='xs'
                  display={{ base: 'none', md: 'flex' }}
                  justifyContent='space-between'
                  pr='4px'
                >
                  <Box></Box>
                </Box>
              </GridItem>

              <GridItem>
                {data.gameType === 'HT/FT' ? null : (
                  <Box
                    display={'flex'}
                    mr='1'
                    fontSize='xs'
                    justifyContent='space-between'
                  >
                    <Box></Box>
                    <Box className='fancy-yes-no-title'>
                      {game === 'soccer' || game === 'tennis' ? (
                        <>
                          <Box
                            className='oddsBoxb d-modified-b no-bg'
                            style={{ marginLeft: 4 }}
                          >
                            <Text>
                              {currentKey?.startsWith('fancy') ? 'Back' : 'Lay'}
                            </Text>
                          </Box>
                          <Box className='oddsBoxr d-modified-l ml-0'>
                            <Text>
                              {currentKey?.startsWith('fancy') ? 'Lay' : 'Back'}
                            </Text>
                          </Box>
                        </>
                      ) : (
                        <>
                          {data?.gameType === 'fancy1' ||
                          data?.gameType?.toLowerCase() === 'khado' ? (
                            <>
                              <Box
                                className={`oddsBoxb d-modified-b ${data?.gameType?.toLowerCase()}`}
                                style={{ marginLeft: 4 }}
                              >
                                <Text>
                                  {currentKey?.startsWith('fancy')
                                    ? 'Back'
                                    : 'Lay'}
                                </Text>
                              </Box>
                              <Box className='oddsBoxr d-modified-l ml-0'>
                                <Text>
                                  {currentKey?.startsWith('fancy')
                                    ? 'Lay'
                                    : 'Back'}
                                </Text>
                              </Box>
                            </>
                          ) : data?.gameType === 'meter' ? (
                            <>
                              <Box
                                className='oddsBoxr d-modified-l no-bg'
                                style={{ marginLeft: 4 }}
                              >
                                <Text>
                                  {currentKey?.startsWith('fancy')
                                    ? 'Lay'
                                    : 'Yes'}
                                </Text>
                              </Box>
                              <Box
                                className='oddsBoxb d-modified-b no-bg'
                                style={{ marginLeft: 0 }}
                              >
                                <Text>
                                  {currentKey?.startsWith('fancy')
                                    ? 'Back'
                                    : 'No'}
                                </Text>
                              </Box>
                            </>
                          ) : data?.gameType === 'oddeven' ? (
                            <>
                              <Box
                                className='oddsBoxr d-modified-l no-bg'
                                style={{
                                  background: 'rgb(114, 187, 239)',
                                  marginLeft: 4,
                                }}
                              >
                                <Text>
                                  {currentKey?.startsWith('fancy')
                                    ? 'ODD'
                                    : 'LAY'}
                                </Text>
                              </Box>
                              <Box
                                className='oddsBoxb d-modified-b no-bg'
                                style={{ marginLeft: 0 }}
                              >
                                <Text>
                                  {currentKey?.startsWith('fancy')
                                    ? 'EVEN'
                                    : 'BACK'}
                                </Text>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                className='oddsBoxr d-modified-l no-bg'
                                style={{ marginLeft: 4 }}
                              >
                                <Text>
                                  {fromSession ? (
                                    'No'
                                  ) : (
                                    <>
                                      {currentKey?.startsWith('fancy')
                                        ? 'Back'
                                        : 'Back'}
                                    </>
                                  )}
                                </Text>
                              </Box>
                              <Box
                                className='oddsBoxb d-modified-b no-bg'
                                style={{ marginLeft: 0 }}
                              >
                                <Text>
                                  {fromSession ? (
                                    'Yes'
                                  ) : (
                                    <>
                                      {currentKey?.startsWith('fancy')
                                        ? 'Lay'
                                        : 'Lay'}
                                    </>
                                  )}
                                </Text>
                              </Box>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </GridItem>
            </Grid>
          </GridItem>

          {data?.oddDetailsDTOS?.map((match, index) => {
            return (
              <>
                <GridItem
                  w='100%'
                  display='flex'
                  justifyContent={{
                    base: 'flex-start',
                    md: 'space-between',
                  }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  p='0'
                  flexDirection={{ base: 'column', md: 'column' }}
                  className='fancy-grid-item-list'
                >
                  <Flex w='100%'>
                    <Box
                      w='100%'
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                    >
                      <Flex
                        w='100%'
                        my='5px'
                        justifyContent={{
                          base: 'space-between',
                          md: 'flex-end',
                        }}
                        alignItems='center'
                      >
                        <Text
                          className='runner-name'
                          fontSize='sm'
                          fontWeight={{ base: 400, md: 600 }}
                          w='100% !important'
                          color={{
                            base: '#A6A6A6',
                          }}
                        >
                          {match.runnerName ? match.runnerName : match.nat}{' '}
                        </Text>
                        <Text
                          display={{ base: 'block', md: 'none' }}
                          fontSize='sm'
                          color={
                            getExposure(match)?.exposure < 0
                              ? 'red.400'
                              : 'green.400'
                          }
                        >
                          {getExposure(match)?.exposure}
                        </Text>
                      </Flex>
                      <Text
                        display={{ base: 'none', md: 'block' }}
                        fontSize='xs'
                        width='100%'
                        color={
                          getExposure(match)?.exposure < 0
                            ? 'red.400'
                            : 'green.400'
                        }
                      >
                        {getExposure(match)?.exposure}
                      </Text>
                    </Box>

                    {game === 'soccer' || game === 'tennis' ? (
                      <>
                        <Flex
                          w={{ base: '100%', md: 'auto', lg: 'auto' }}
                          data-title={match.status}
                          className={
                            applyFancyFor.find(
                              (item) =>
                                item?.toLowerCase() ==
                                data?.gameType?.toLowerCase()
                            )
                              ? match.status != 'ACTIVE' &&
                                match.status != '' &&
                                match.status != null
                                ? 'suspendedtext'
                                : ''
                              : match?.status?.toLowerCase() != 'suspended'
                              ? ''
                              : 'suspended'
                          }
                          ref={ref}
                        >
                          <OddsBox
                            data={data}
                            match={match}
                            oddsKey={'back1'}
                            index={index}
                            isFancy={true}
                            onClick={(data_) => {
                              if (isEmpty(data_.selectedOdd)) return;
                              setData({
                                ...data_,
                                isFancy: true,
                                selectionId: match.selectionId,
                                marketId: match.marketId,
                                marketName: match.marketName,
                                runnerName: match.nat
                                  ? match.nat
                                  : match.runnerName,
                                gameType: data.gameType,
                              });
                            }}
                          />

                          <OddsBox
                            data={data}
                            isFancy={true}
                            match={match}
                            oddsKey={'lay1'}
                            index={index}
                            back={false}
                            onClick={(data_) => {
                              if (isEmpty(data_.selectedOdd)) return;
                              setData({
                                ...data_,
                                isBookmaker: false,
                                isFancy: true,
                                marketId: match.marketId,
                                marketName: data.marketName,
                                runnerName: match.nat
                                  ? match.nat
                                  : match.runnerName,
                                selectionId: match.selectionId,
                                gameType: data.gameType,
                              });
                            }}
                          />
                        </Flex>
                      </>
                    ) : (
                      <>
                        {data.gameType != 'oddeven' && (
                          <Flex
                            w={{ base: '100%', md: 'auto', lg: 'auto' }}
                            data-title={match.status}
                            className={
                              applyFancyFor.find(
                                (item) =>
                                  item?.toLowerCase() ==
                                  data?.gameType?.toLowerCase()
                              )
                                ? match.status != 'ACTIVE' &&
                                  match.status != '' &&
                                  match.status != null
                                  ? 'suspendedtext'
                                  : ''
                                : match?.status?.toLowerCase() != 'suspended'
                                ? ''
                                : 'suspended'
                            }
                            ref={ref}
                          >
                            {data?.gameType?.toLowerCase() === 'fancy1' ? (
                              <>
                                <OddsBox
                                  data={data}
                                  match={match}
                                  oddsKey={'back1'}
                                  index={index}
                                  isFancy={true}
                                  onClick={(data_) =>
                                    setData({
                                      ...data_,
                                      isFancy: true,
                                      selectionId: match.selectionId,
                                      marketId: match.marketId,
                                      marketName: match.marketName,
                                      runnerName: match.nat
                                        ? match.nat
                                        : match.runnerName,
                                      gameType: data.gameType,
                                      index,
                                    })
                                  }
                                />

                                <>
                                  <OddsBox
                                    data={data}
                                    isFancy={true}
                                    match={match}
                                    oddsKey={'lay1'}
                                    index={index}
                                    back={false}
                                    onClick={(data_) =>
                                      setData({
                                        ...data_,
                                        isBookmaker: false,
                                        isFancy: true,
                                        marketId: match.marketId,
                                        marketName: data.marketName,
                                        runnerName: match.nat
                                          ? match.nat
                                          : match.runnerName,
                                        selectionId: match.selectionId,
                                        gameType: data.gameType,
                                      })
                                    }
                                  />
                                </>
                              </>
                            ) : data?.gameType?.toLowerCase() === 'khado' ||
                              data?.gameType === 'Draw No Bet' ? (
                              <>
                                <OddsBox
                                  data={data}
                                  match={match}
                                  oddsKey={'back1'}
                                  index={index}
                                  isFancy={true}
                                  onClick={(data_) =>
                                    setData({
                                      ...data_,
                                      isFancy: true,
                                      selectionId: match.selectionId,
                                      marketId: match.marketId,
                                      marketName: match.marketName,
                                      runnerName: match.nat
                                        ? match.nat
                                        : match.runnerName,
                                      gameType: data.gameType,
                                    })
                                  }
                                />
                                <OddsBox
                                  data={data}
                                  isFancy={true}
                                  match={match}
                                  oddsKey={'lay1'}
                                  index={index}
                                  back={false}
                                  onClick={(data_) =>
                                    setData({
                                      ...data_,
                                      isBookmaker: false,
                                      isFancy: true,
                                      marketId: match.marketId,
                                      marketName: data.marketName,
                                      runnerName: match.nat
                                        ? match.nat
                                        : match.runnerName,
                                      selectionId: match.selectionId,
                                      gameType: data.gameType,
                                    })
                                  }
                                />
                              </>
                            ) : data?.gameType === 'meter' ? (
                              <>
                                <OddsBox
                                  data={data}
                                  isFancy={true}
                                  match={match}
                                  oddsKey={'lay1'}
                                  index={index}
                                  back={false}
                                  onClick={(data_) =>
                                    setData({
                                      ...data_,
                                      isBookmaker: false,
                                      isFancy: true,
                                      marketId: match.marketId,
                                      marketName: data.marketName,
                                      runnerName: match.nat
                                        ? match.nat
                                        : match.runnerName,
                                      selectionId: match.selectionId,
                                      gameType: data.gameType,
                                    })
                                  }
                                />
                                <OddsBox
                                  data={data}
                                  match={match}
                                  oddsKey={'back1'}
                                  index={index}
                                  isFancy={true}
                                  onClick={(data_) =>
                                    setData({
                                      ...data_,
                                      isFancy: true,
                                      selectionId: match.selectionId,
                                      marketId: match.marketId,
                                      marketName: match.marketName,
                                      runnerName: match.nat
                                        ? match.nat
                                        : match.runnerName,
                                      gameType: data.gameType,
                                    })
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {fromSession ? (
                                  <>
                                    <OddsBox
                                      data={data}
                                      isFancy={true}
                                      match={match}
                                      oddsKey={'lay1'}
                                      index={index}
                                      back={false}
                                      onClick={(data_) =>
                                        setData({
                                          ...data_,
                                          isBookmaker: false,
                                          isFancy: true,
                                          marketId: match.marketId,
                                          marketName: data.marketName,
                                          runnerName: match.nat
                                            ? match.nat
                                            : match.runnerName,
                                          selectionId: match.selectionId,
                                          gameType: data.gameType,
                                        })
                                      }
                                    />
                                    <OddsBox
                                      data={data}
                                      match={match}
                                      oddsKey={'back1'}
                                      index={index}
                                      isFancy={true}
                                      onClick={(data_) =>
                                        setData({
                                          ...data_,
                                          isFancy: true,
                                          selectionId: match.selectionId,
                                          marketId: match.marketId,
                                          marketName: match.marketName,
                                          runnerName: match.nat
                                            ? match.nat
                                            : match.runnerName,
                                          gameType: data.gameType,
                                        })
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <OddsBox
                                      data={data}
                                      match={match}
                                      oddsKey={'back1'}
                                      index={index}
                                      isFancy={true}
                                      onClick={(data_) =>
                                        setData({
                                          ...data_,
                                          isFancy: true,
                                          selectionId: match.selectionId,
                                          marketId: match.marketId,
                                          marketName: match.marketName,
                                          runnerName: match.nat
                                            ? match.nat
                                            : match.runnerName,
                                          gameType: data.gameType,
                                        })
                                      }
                                    />
                                    <OddsBox
                                      data={data}
                                      isFancy={true}
                                      match={match}
                                      oddsKey={'lay1'}
                                      index={index}
                                      back={false}
                                      onClick={(data_) =>
                                        setData({
                                          ...data_,
                                          isBookmaker: false,
                                          isFancy: true,
                                          marketId: match.marketId,
                                          marketName: data.marketName,
                                          runnerName: match.nat
                                            ? match.nat
                                            : match.runnerName,
                                          selectionId: match.selectionId,
                                          gameType: data.gameType,
                                        })
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </Flex>
                        )}
                        {data.gameType == 'oddeven' && (
                          <Flex
                            justifyContent='flex-end'
                            w={{ base: '100%', md: 'auto', lg: 'auto' }}
                            data-title={match.status}
                            className={`${
                              match.status != 'ACTIVE' &&
                              match.status != '' &&
                              match.status != null
                                ? 'suspendedtext'
                                : ''
                            } odd-even-flex`}
                            ref={ref}
                          >
                            <OddsBox
                              data={data}
                              isFancy={true}
                              match={match}
                              oddsKey={'back1'}
                              index={index}
                              overRide={true}
                              onClick={(data_) => {
                                setData({
                                  ...data_,
                                  isBookmaker: false,
                                  isFancy: true,
                                  marketId: match.marketId,
                                  marketName: data.marketName,
                                  runnerName: match.nat
                                    ? match.nat
                                    : match.runnerName,
                                  selectionId: match.selectionId,
                                  gameType: data.gameType,
                                });
                              }}
                            />
                            <OddsBox
                              data={data}
                              match={match}
                              oddsKey={'lay1'}
                              index={index}
                              isFancy={true}
                              onClick={(data_) =>
                                setData({
                                  ...data_,
                                  isFancy: true,
                                  selectionId: match.selectionId,
                                  marketId: match.marketId,
                                  marketName: match.marketName,
                                  runnerName: match.nat
                                    ? match.nat
                                    : match.runnerName,
                                  gameType: data.gameType,
                                })
                              }
                            />
                          </Flex>
                        )}
                      </>
                    )}
                  </Flex>

                  {console.log(match, 'match')}

                  {selectedEventForBet &&
                  selectedEventForBet?.selectionId === match.selectionId ? (
                    <BetPlaceContainer game={game} eventId={eventId} />
                  ) : null}
                </GridItem>
              </>
            );
          })}
        </Grid>
      ) : null}
    </Wrap>
  );
}
