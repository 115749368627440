import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/Table";
import { unSetteledBetsAPI } from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import CustomInput from "../../components/CustomInput";
import moment from "moment";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import ExporttoPDF from "../../assets/img/pdf_img.svg";
import ExporttoExcel from "../../assets/img/excel_img.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function CGUnSetteledBet() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal(true));
  const [to, setTo] = React.useState(getCurrentDateTimeLocal());
  const [selectedCategory, setSelectedCategory] = React.useState("MATCHED");
  const [startDate, setStartDate] = React.useState(
    moment().subtract(2, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));

  const [noOfPages, setNoOfPages] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);

  const getUnSetteledBets = async () => {
    let payload = {
      pageNumber: page,
      pageSize: rowsPerPage,
      search: "",
      betStatus: selectedCategory,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };
    const {
      response: { content },
    } = await unSetteledBetsAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,
      no: index + 1,
      side: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
    }));
    setData(data_);
    setNoOfPages(Math.ceil(data_.length / rowsPerPage));
    setCurrentPageData(data_.slice(0, rowsPerPage));
  };

  const search = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value.trim() === "") {
      setCurrentPageData(
        data.slice(
          pageNumber * rowsPerPage,
          pageNumber * rowsPerPage + rowsPerPage
        )
      );
    } else {
      const filteredData = data.filter((item) => {
        const commentMatch = item.comment.toLowerCase().includes(value);
        const creditInfoMatch =
          typeof item.event_name === "string" &&
          item.event_name.toLowerCase().includes(value);
        const debitInfoMatch =
          typeof item.runner_name === "string" &&
          item.runner_name.toLowerCase().includes(value);
        const updatedBalanceMatch = item.updatedBalance
          .toString()
          .toLowerCase()
          .includes(value);
        const currentBalanceMatch = item.currentBalance
          .toString()
          .toLowerCase()
          .includes(value);

        return (
          commentMatch ||
          creditInfoMatch ||
          debitInfoMatch ||
          updatedBalanceMatch ||
          currentBalanceMatch
        );
      });

      setData(filteredData);
      setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
      setCurrentPageData(filteredData.slice(0, rowsPerPage));
    }
  };

  useEffect(() => {
    getUnSetteledBets();
  }, [selectedCategory, rowsPerPage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    getUnSetteledBets();
  };

  const handleReset = (e) => {
    setStartDate(moment().format("YYYY-MM-DDTHH:MM"));
    setEndDate(moment().format("YYYY-MM-DDTHH:MM"));
  };

  const tableRef = useRef(null);

  const generateReportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#table" });
    doc.save("table.pdf");
  };

  return (
    <Box
      bg={{ base: "#fff", md: "#fff" }}
      borderRadius={10}
      border="1px solid lightgray"
      className="setting-page-main unsetteled-main"
    >
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        marginTop="-5px"
      >
        <Text color="black" fontSize="16px" fontWeight="600">
          Club Games Unsettled Bets
        </Text>
      </Box>
      {/* <Box
          className="filter-box-unsettled"
          mt="5"
          marginLeft={"-90px"}
          display="flex"
          p="2"
          w="40%"
          justifyContent={"center"}
        >
          <RadioGroup onChange={(value) => setSelectedCategory(value)}>
            <Stack direction='row'>
              <Box></Box>
              <Radio value='MATCHED'>Matched</Radio>
              <Radio value='UNMATCHED'>Un-Matched</Radio>
              <Radio value='DELETED'>Deleted</Radio>
            </Stack>
          </RadioGroup>
        </Box> */}

      <Box
        w={{base:"40%", md:"100%"}}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="input-head"
      >
        <Box
          flexDirection={{
            base: "column",
            md: "row",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="2"
          width={{
            base: "100%",
            sm: "100%",
            md: "80%",
            lg: "60%",
          }}
          className="head-body"
        >
          <CustomInput
            placeholder="Select Start Date"
            type="date"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
            max={moment().format("YYYY-MM-DD")}
          />
          <CustomInput
            placeholder="Select End Date"
            type="date"
            bg="transparent"
            borderRadius="5"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
              width: "100%",
            }}
            size="md"
            max={moment().format("YYYY-MM-DD")}
            min={moment(startDate).format("YYYY-MM-DD")}
          />

          <Box display="flex">
            <Button
              className="cg-gradiant-btn cg-go-btn"
              size="sm"
              onClick={handleSubmit}
              mr={1}
            >
              Go
            </Button>

            <Button
              className="cg-gradiant-btn cg-reset-btn"
              size="sm"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Box>

     {/* web  */}
     <Box
        display={{ base: "none", md: "flex" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={"flex-start"}
        flexDirection={{ base: "column", md: "row" }}
        w="100%"
      >
        <Box
          w={{ base: "100%", md: "auto" }}
          borderRadius={{ base: "0px", md: "5px" }}
          display="flex"
          alignItems="center"
          m={{ base: "10px", md: "10px" }}
          border="1px solid #c7c7c7"
        >
          <CustomInput
            placeholder="Search"
            border="none"
            color="gray"
            _placeholder={{ color: "gray" }}
            _focus={{ boxShadow: "none" }}
            onChange={(e) => search(e)}
            p={{
              base: "10px",
              md: "20px",
            }}
            outline="none"
            mt="-10px"
            containerStyle={{
              paddingTop: "5px",
              width: "70%",
            }}
            value={searchValue}
          />{" "}
          {searchValue ? (
            <CloseIcon
              onClick={() => {
                setSearchValue("");
                getUnSetteledBets();
              }}
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
              cursor="pointer"
            />
          ) : (
            <SearchIcon
              mx="10px"
              ml={{
                base: "40px",
              }}
              color="gray"
            />
          )}
        </Box>
        <Box display="flex">
          <img
            src={ExporttoPDF}
            alt="PDF Logo"
            onClick={generateReportPDF}
            style={{ cursor: "pointer" }}
          />

          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <img
              src={ExporttoExcel}
              alt="PPT Logo"
              style={{ cursor: "pointer" }}
            />{" "}
          </DownloadTableExcel>
        </Box>
        <Spacer />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          mr="5"
        >
          <Text className="text-black">Show</Text>
          <Select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            width="auto"
            ml="2"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
          <Text ml="2" className="text-black">
            entries
          </Text>
        </Box>
      </Box>

      {/* mobile  */}
      <Box
  display={{ base: "flex", md: "none" }}
  alignItems={{ base: "center", md: "center" }}
  justifyContent="center"
  flexDirection={{ base: "row", md: "row" }}
  w="100%"
>
  {/* Search input and icons */}
  <Box
    w={{ base: "100px", md: "auto" }}
    borderRadius="5px"
    display="flex"
    alignItems="center"
    m={{ base: "10px", md: "10px" }}
    border="1px solid #c7c7c7"
    width="25%"
  >
    <CustomInput
      placeholder="Search"
      border="none"
      color="gray"
      _placeholder={{ color: "gray" }}
      _focus={{ boxShadow: "none" }}
      onChange={(e) => search(e)}
      p={{ base: "10px", md: "20px" }}
      outline="none"
      mt="-10px"
      containerStyle={{ paddingTop: "5px", width: "90%" }}
      value={searchValue}
    />
    {searchValue ? (
      <CloseIcon
        onClick={() => {
          setSearchValue("");
          getUnSetteledBets();
        }}
        mx="10px"
        ml={{ base: "20px" }}
        color="gray"
        cursor="pointer"
      />
    ) : (
      <SearchIcon
        mx="10px"
        ml={{ base: "20px" }}
        color="gray"
      />
    )}
  </Box>

  {/* Export icons */}
  <Box display="flex" width={{ base: "15%", md: "auto" }}>
    <img
      src={ExporttoPDF}
      alt="PDF Logo"
      onClick={generateReportPDF}
      style={{ cursor: "pointer" }}
    />
    <DownloadTableExcel
      filename="users table"
      sheet="users"
      currentTableRef={tableRef.current}
    >
      <img
        src={ExporttoExcel}
        alt="PPT Logo"
        style={{ cursor: "pointer" }}
      />
    </DownloadTableExcel>
  </Box>

  {/* Show entries dropdown */}
  <Box
    display="flex"
    alignItems="center"
    justifyContent="flex-end"
    flexDirection={{ base: "row", md: "row" }}
    w={{ base: "50%", md: "auto" }}
    mr="5"
  >
    <Text className="text-black">Show</Text>
    <Select
      value={rowsPerPage}
      onChange={(e) => setRowsPerPage(Number(e.target.value))}
      width="auto"
      ml="2"
    >
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </Select>
    <Text ml="2" className="text-black">
      entries
    </Text>
  </Box>
</Box>

      <Box p="2">
        {/* <table id='table'> */}
        <CustomTable
          currentPage_={page}
          pageSize_={size}
          total_={data.length}
          showPagination={true}
          rowsPerPage_={[10, 20, 30, 40, 50]}
          columns={[
            {
              name: "No",
              id: "no",
            },
            {
              name: "Event Name",
              id: "event_name",
            },
            {
              name: "Nation",
              id: "runner_name",
            },
            {
              name: "Event Type",
              id: "event_name",
            },
            {
              name: "Market Name",
              id: "marketName",
            },
            {
              name: "Side",
              id: "side",
            },
            {
              name: "Rate",
              id: "rate",
            },
            {
              name: "Amount",
              id: "amount",
            },
            {
              name: "Place Date",
              id: "created_date",
            },
            {
              name: "Match Date",
              id: "created_date",
            },
          ]}
          rows={data}
        />
        {/* </table> */}
      </Box>
    </Box>
  );
}
