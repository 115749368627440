/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  GridItem,
  Grid,
  TabPanel,
  TabPanels,
  Tabs,
  TabList,
  Tab,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
  useToast,
  ModalCloseButton,
  ModalBody,
  Button,
  usePrefersReducedMotion,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Image,
} from '@chakra-ui/react';
import Header from '../includes/header';
import SidebarContent from '../includes/sidebar';
import DasNAv from '../includes/dasNav';
import { getUserDataAPI } from '../service/UserService';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../components/CustomInput';
import {
  changeButtonValuesAPI,
  changePasswordAPI,
  dashboardBannerAPI,
  getAnnouncementAPI,
  getButtonValuesAPI,
  getExposureTableAPI,
  getSignedUrlAPI,
  welcomeBannerAPI,
} from '../service/AuthService';
import { buttonColors, Encrypt } from '../utils/constants';
import CustomTable from '../components/Table';
import { useMemo } from 'react';
import Sidebar from '../includes/sidebar/Sidebar';
import { useState } from 'react';
import Footer from '../includes/footer';
import LastResults from '../components/LastResults';
import MobileHeader from '../includes/header/MobileHeader';
import BannerSlider from '../pages/banner/banner';
import image1 from '../assets/img/home-img-1.png';
import image2 from '../assets/img/home-img-2.png';
import image3 from '../assets/img/home-img-3.png';
import image4 from '../assets/img/home-img-4.png';
import image5 from '../assets/img/home-img-4.png';

import topGame1 from '../assets/img/Top Game/topGame1.png';
import topGame2 from '../assets/img/Top Game/topGame2.png';
import topGame3 from '../assets/img/Top Game/topGame3.png';
import topGame4 from '../assets/img/Top Game/topGame4.png';
import topGame5 from '../assets/img/Top Game/topGame5.png';
import topGame6 from '../assets/img/Top Game/topGame6.png';
import topGame7 from '../assets/img/Top Game/topGame7.png';
import topGame8 from '../assets/img/Top Game/topGame8.png';
import topGame9 from '../assets/img/Top Game/topGame9.png';
import SideMenu from '../includes/header/SideMenu';

const PrivateOutlet = (props) => {
  const userData = useSelector((state) => state.account.userData);
  const selectedEventForBet = useSelector(
    (state) => state?.gameData?.selectedEventForBet
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleSliderChange = (value) => {
    setCurrentIndex(value);
  };

  const [buttons, setButtons] = React.useState({});
  const [announcement, setAnnouncement] = React.useState({});
  const [homeImages, setHomeImages] = useState([]);
  var newHomeImages = [];

  const container1 = [image1, image2, image3];
  const container2 = [image4, image5];
  const topGames = [
    topGame1,
    topGame2,
    topGame3,
    topGame4,
    topGame5,
    topGame6,
    topGame7,
    topGame8,
    topGame9,
  ];

  const getBannerImages = async () => {
    var res = await welcomeBannerAPI();
    console.log('banner data', res);
    if (res.status === 200) {
      let data = res.data.response;

      for (let i = 0; i < data.length; i++) {
        let res = await getSignedUrlAPI(data[i].bannerUrl);
        //array store saariimages ye images mai box welcome banner
        console.log('SignedUrl', res);
        if (res?.data?.code === 200) {
          newHomeImages.push(res.data?.response);
          data[i].bannerUrl = res.data?.response;
        }
      }
      //setHomeImages(data.map((item) => item.bannerUrl));
      setHomeImages((prevHomeImages) => [...prevHomeImages, ...newHomeImages]);
      //setImages(data.map((item) => item.bannerUrl));
    }
  };
  useEffect(() => {
    getBannerImages();
  }, []);
  useEffect(() => {
    console.log('homeImagesCloud', homeImages);
  }, [homeImages]);

  const getAnnouncement = async () => {
    const res = await getAnnouncementAPI();
    if (res.code === 200) {
      setAnnouncement(res.response);
    }
  };
  const handleUpdate = (key, value) => {
    setButtons((prev) => ({ ...prev, [key]: value }));
  };
  const getButtonValues = async () => {
    const res = await getButtonValuesAPI();
    if (res.code === 200) {
      setButtons(res.response);
    }
  };
  const handleSave = async () => {
    const res = await changeButtonValuesAPI(buttons);

    if (res.code === 200) {
      toast({
        title: 'Success',
        description: 'Button values updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
    getButtonValues();
  };

  useEffect(() => {
    getButtonValues();
  }, []);
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const isChangePasswordPopOpen = useSelector(
    (state) => state.account.ui.changePasswordPopOpen
  );
  const isChangeButtonPopOpen = useSelector(
    (state) => state.account.ui.changeButtonPopOpen
  );
  const isMyMarketPopOpen = useSelector(
    (state) => state.account.ui.myMarketPopOpen
  );
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [exposureTable, setExposureTable] = React.useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(false);

  const handleCloseWelcomeOpen = () => {
    localStorage.setItem('welcome', 'closed');
    setWelcomeOpen(false);
  };
  const handleOpenWelcomeOpen = () => setWelcomeOpen(true);

  const changePassword = async () => {
    if (newPassword === '' || confirmPassword === '' || oldPassword === '') {
      toast({
        description: 'Please fill all the fields',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        description: 'New Password and Confirm Password do not match',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (newPassword === oldPassword) {
      toast({
        description: 'New Password and Old Password cannot be the same',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        description:
          'New Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
  
    try {
      var res = await changePasswordAPI(
        {
          newPassword: Encrypt(newPassword),
          password: Encrypt(oldPassword),
          resetRequired: false,
        },
        userData.token
      );
  
      if (res.code === 200) {
        toast({
          title: 'Success',
          description: 'New Password changed successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'top',
        });
  
        dispatch({
          type: 'accountData/logOut',
          payload: {},
        });
        dispatch({
          type: 'gameData/logOut',
          payload: {},
        });
  
        setTimeout(() => {
          localStorage.clear();
          window.location.href = '/';
        }, 500);
      } else {
        // Handle other possible error codes or messages
        toast({
          description: 'An error occurred while changing the password',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      // Handle API request failure, including specific cases like incorrect old password
      if (error.response && error.response.status === 400 || 403) {
        toast({
          description: 'Incorrect old password',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top',
        });
      } else {
        toast({
          description: 'Failed to change password. Please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top',
        });
      }
    }
  };
  
  
  const getExposureTable = async () => {
    var res = await getExposureTableAPI();
    if (res.code === 200) {
      setExposureTable(res.response);
    }
  };
  useEffect(() => {
    if (userData.token && userData.token != null) {
      getExposureTable();
      let interval = setInterval(() => {
        getUserData();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const getUserData = async () => {
    var res = await getUserDataAPI(userData.id);

    if (res && res?.code === 200) {
      dispatch({
        type: 'accountData/setUserProfile',
        payload: res.response,
      });
    } else {
      dispatch({
        type: 'accountData/logOut',
        payload: {},
      });
      dispatch({
        type: 'gameData/logOut',
        payload: {},
      });
    }
  };
  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSidebarOpen]);

  if (!userData.token) {
    return (window.location.href = '/');
  }

  useEffect(() => {
    const welcomeClosed = localStorage.getItem('welcome');
    if (!welcomeClosed && !userData?.resetRequired) {
      handleOpenWelcomeOpen();
    }
  }, [userData?.resetRequired]);

  return (
    <>
      <Box display={{ base: 'block', md: 'none' }}>
        <MobileHeader />
      </Box>
      <Box 
      display={{ base: 'none', md: 'block' }}
      >
        <Header setIsSidebarOpen={setIsSidebarOpen} />
      </Box>
      <div className='container-fluid container-fluid-5 snipcss-fJ9mV'>
        <div className='row row5'>
          {/*  */}
          {location.pathname.includes('account-statement') ||
          location.pathname.includes('profit-loss-report') ||
          location.pathname.includes('bet-history') ||
          location.pathname.includes('rules') ||
          location.pathname.includes('unsetteled-bet') ||
          location.pathname.includes('changebtnvalue') ||
          location.pathname.includes('secureauth') ||
          location.pathname.includes('changepassword') ? (
            <SideMenu
              isDropMenuOpen={false}
              handleCloseSideMenu={() => {}}
              fromPrivate
            />
          ) : (
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          )}

          <Box className='content-box-main'>{props.children}</Box>
        </div>
      </div>

      {/* <div>
        <BannerSlider />

        <div className="image-container" style={{ display: "flex" }}>
          {container1.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 1}`} />
          ))}
        </div>

        <div className="image-container-2" style={{ display: "flex" }}>
          {container2.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 4}`} />
          ))}
        </div>

        <div>
          <div className="top-games-text">Top Games</div>
          <div className="top-games" style={{ display: "flex" }}>
            {topGames.map((image, index) => (
              <img key={index} src={image} alt={`Image ${index + 4}`} />
            ))}
          </div>
        </div>

        <div></div>
        <br />
        <br />
      </div> */}
      <Footer />

      <Modal
        size={'lg'}
        onClose={() => {
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          if (userData?.token && userData?.resetRequired) {
            dispatch({
              type: 'accountData/logOut',
              payload: {},
            });
            dispatch({
              type: 'gameData/logOut',
              payload: {},
            });
            setTimeout(() => {
              window.location.reload();
              window.localStorage.clear();
              window.location.href = '/';
            }, 500);
          }
          dispatch({
            type: 'accountData/setChangePopSate',
            payload: false,
          });
        }}
        isOpen={isChangePasswordPopOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent zIndex={10000} marginTop={10} bg={'rgb(51,51,51)'}>
          <ModalHeader bg={'#fff'} p='1'>
            <Text fontSize='sm' color={'#fff'}>
              Change Password
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2' bg={'#fff'}>
            <CustomInput
              label='Old Password'
              type='password'
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              bg={'#ddd'}
            />
            <CustomInput
              label='New Password'
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              bg={'#ddd'}
            />
            <CustomInput
              label='Confirm Password'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              bg={'#ddd'}
            />
            <Button
              onClick={changePassword}
              mt='10px'
              w={'100%'}
              bg={"#045662 !important"}

            >
              Change Password
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size={'lg'}
        onClose={() =>
          dispatch({
            type: 'accountData/setChangeButtonState',
            payload: false,
          })
        }
        isOpen={isChangeButtonPopOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent marginTop={10} bg={'#494949'}>
          <ModalHeader bg={'#383838'} p='1' borderRadius={'5px'}>
            <Text fontSize='sm' color={'#FDCF13'}>
              Set Button Value
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2' bg={'#494949'}>
            <Button onClick={handleSave} mt='10px' w={'100%'} bg={buttonColors}>
              Submit
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size={'4xl'}
        onClose={() =>
          dispatch({
            type: 'accountData/setMyMarketPopState',
            payload: false,
          })
        }
        isOpen={isMyMarketPopOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent marginTop={10} bg={'rgb(51,51,51)'}>
          <ModalHeader bg={'#3C444B'} p='1' borderRadius={'5px'}>
            <Text fontSize='sm' color={'#FDCF13'}>
              My Market
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p='2' bg={'#2E3439'} borderRadius={'5px'}>
            <CustomTable
              rows={exposureTable}
              rowsPerPage_={20}
              showPagination={false}
              totalPages_={1}
              currentPage_={0}
              columns={[
                {
                  name: 'Event Type',
                  id: 'eventType',
                },
                {
                  name: 'Event Name',
                  id: 'eventName',
                },
                {
                  name: 'Match Name',
                  id: 'marketName',
                },
                {
                  name: 'Trade',
                  id: 'trade',
                },
              ]}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={welcomeOpen} onClose={handleCloseWelcomeOpen} isCentered>
  <ModalOverlay />
  <ModalContent
    style={{
      backgroundColor: 'transparent',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {/* Modal Close Button */}
    <ModalCloseButton
      style={{
        position: 'absolute',
        // top: '10px',
        // right: '10px',
        color: 'white',
        zIndex: '1',
        background: 'black',
      }}
    />

    {/* Modal Body */}
    <ModalBody style={{ padding: 0, background: 'none' }}>
      {/* Box containing the image */}
      <Box className='welcome-banner' style={{ width: '100%', height: '100%' }}>
        {/* Image */}
        <Image
          src={homeImages[currentIndex]}
          alt={`Image ${currentIndex}`}
          width='100%'
          height='100%'
          style={{ objectFit: 'contain' }}
        />
      </Box>
    </ModalBody>
  </ModalContent>
</Modal>

    </>
  );
};

export default PrivateOutlet;
