import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/CustomInput";
import {
  getButtonValuesAPI,
  getLiveStreamingUrlAPI,
} from "../../service/AuthService";
import Iframe from "react-iframe";
import {
  getCasinoLastTenResultsAPI,
  getMyBetsCasinoAPI,
  placeCasinoBetAPI,
} from "../../service/casino";
import {
  deleteCricketBetAPI,
  getMyBetsCricketAPI,
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from "../../service/cricket";
import {
  getMyBetsSoccerAPI,
  getMyBetsTennisAPI,
  placeSoccerBetAPI,
  placeTennisBetAPI,
} from "../../service/soccer&tennis";
import {
  applyFancyFor,
  ASSETS_URL,
  bgColor,
  buttonColors,
  secondaryColor,
} from "../../utils/constants";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from "./profitLoss";
import axios from "axios";
import CasinoResultModal from "../../components/CasinoResultModal/CasinoResultModal";
import IframeComponent from "../../components/Iframe";
import BetPlaceContainer from "./BetPlaceContainer";
import { getService } from "../../utils/commonFunctions";
import { useLocation, useSearchParams } from "react-router-dom";
let _temp = 0;
export default function PlaceBet({
  matchName,
  matchOdds,
  teamName,
  game,
  eventId,
  settings,
  w,
  inningsRule,
  isImage,
  gameId,
  seperationRequired,
  casinoGameKey,
  forMobile,
  children,
}) {
  const selectedEventForBet_ = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const imageMap = {
    S: "spade.png",
    H: "heart.png",
    D: "diamond.png",
    C: "club.png",
  };
  const selectedEventForBet = useMemo(
    () => selectedEventForBet_,
    [selectedEventForBet_]
  );
  const [channelId, setChannelId] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [lastResultId, setLastResultId] = useState("");
  const [lastBetCount, setLastBatCount] = useState(0);
  const isMobileView = window.innerWidth < 768;
  const toast = useToast();
  const dispatch = useDispatch();
  const [userBets, setUserBets] = useState([]);
  const authDetails = useSelector((state) => state.account.authDetails);
  const userData = useSelector((state) => state.account.userData);
  var gameSettings_ = useSelector((state) => state.gameData[game + "Settings"]);
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const [searchParams] = useSearchParams();
  const [amount, setAmount] = useState({ casino: [], sport: [] });
  const [isChannelIdFetched, setIsChannelIdFetched] = useState(false);
  const location = useLocation();

  const setSelectedAmount = (value, type) => {
    if (type == "CHANGE") {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          amount: value,
          profit: getProfit(value)?.profit,
          loss: getProfit(value)?.loss,
        },
      });
    } else {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          amount: parseInt(selectedEventForBet?.amount) + value,
          profit: getProfit(parseInt(selectedEventForBet?.amount) + value)
            ?.profit,
          loss: getProfit(parseInt(selectedEventForBet?.amount) + value)?.loss,
        },
      });
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const placeBet = async () => {
    let delay;
    setIsLoading(true);

    if (
      selectedEventForBet.marketName == "MATCH_ODDS" ||
      selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" ||
      selectedEventForBet.marketName == "BOOKMAKER_ODDS_1"
    ) {
      delay = gameSettings?.delayOdds;
    } else {
      delay = gameSettings?.delaySession;
    }

    let selectedAmount_ = selectedEventForBet?.amount;
    if (selectedEventForBet.selectedOdd <= 0) {
      toast({
        description: "Please select a valid odd",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
      return;
    }
    if (game == "cricket" || game == "tennis" || game == "soccer") {
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMinStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMinStack > selectedAmount_)
      ) {
        toast({
          description: `Minimum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMinStack
              : gameSettings?.minStack
          }`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
        return;
      }
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.maxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.bookmakerMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMaxStack < selectedAmount_)
      ) {
        toast({
          description: `Maximum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMaxStack
              : gameSettings?.maxStack
          }`,

          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
        return;
      }
    }

    let profit;
    let loss;
    if (game == "cricket" && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "casino" || game == "tennis" || game == "soccer") {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else {
      profit = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    let payload = {
      amount: selectedAmount_,
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
      marketId: selectedEventForBet.marketId,
      selectionId: selectedEventForBet.selectedId,
      type: selectedEventForBet.type,
      eventId: parseInt(selectedEventForBet.eventId),
      runnerName: selectedEventForBet.runnerName,
      gameType:
        selectedEventForBet.marketName != "MATCH_ODDS" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
          ? selectedEventForBet.gameType
          : selectedEventForBet.marketName,
      marketName: selectedEventForBet.marketName,
    };

    if (game == "casino") {
      payload.casinoGames = eventId;
    }
    if (selectedEventForBet.type == "back") {
      payload["back"] = parseFloat(selectedEventForBet.selectedOdd);
    } else {
      payload["lay"] = parseFloat(selectedEventForBet.selectedOdd);
    }
    if (game == "cricket" && !selectedEventForBet.isFancy) {
      const { response, code, data } = await placeCricketBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
      } else if (code == "ERR_BAD_RESPONSE") {
        toast({
          title: "Something Went Wrong ",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: response || "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } else if (game == "cricket" && selectedEventForBet.isFancy) {
      let fancyPayload = {
        amount: selectedAmount_,
        eventId: parseInt(selectedEventForBet.eventId),
        marketId: selectedEventForBet.marketId,
        marketName:
          selectedEventForBet.marketName != "MATCH_ODDS" ||
          selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
          selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
            ? selectedEventForBet.gameType
            : selectedEventForBet.marketName,
        gameType: selectedEventForBet.gameType,
      };

      if (selectedEventForBet.type === "lay") {
        fancyPayload["layRate"] = selectedEventForBet.selectedRate;
        fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
      } else {
        fancyPayload["backRate"] = selectedEventForBet.selectedRate;
        fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
      }

      if (
        applyFancyFor.find(
          (item) =>
            item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
        )
      ) {
        if (selectedEventForBet?.type == "lay") {
          fancyPayload.profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          fancyPayload.loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
        } else {
          fancyPayload.profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          fancyPayload.loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
        }
      } else {
        fancyPayload.profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        fancyPayload.loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
        fancyPayload.selectionId = selectedEventForBet.selectedId;
        fancyPayload.runnerName = selectedEventForBet.runnerName;
        if (selectedEventForBet.type === "lay") {
          fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
        } else {
          fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
        }
      }

      const { response, code, data } = await placeFancyCricketBetAPI(
        fancyPayload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      } else if (code == "ERR_BAD_RESPONSE") {
        toast({
          title: "Something Went Wrong ",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: response || "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } else if (game == "soccer") {
      const { response, code } = await placeSoccerBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      } else if (code == "ERR_BAD_RESPONSE") {
        toast({
          title: "Something Went Wrong ",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: response || "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } else if (game == "tennis") {
      const { response, code } = await placeTennisBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        // toast({
        //   title: 'Bet Placed Successfully',
        //   status: 'success',
        //   duration: 2000,
        //   isClosable: true,
        //   position: 'top',
        // });
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      } else if (code == "ERR_BAD_RESPONSE") {
        toast({
          title: "Something Went Wrong ",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: response || "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } else if (game == "casino") {
      const { response, code } = await placeCasinoBetAPI(
        getService(location.pathname).port,
        getService(location.pathname).service,
        payload,
        userData.token
      );
      if (code == 200) {
        toast({
          title: "Bet Placed Successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      } else if (code == "ERR_BAD_RESPONSE") {
        toast({
          title: "Something Went Wrong ",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: response || "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }

    setTimeout(async () => {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
      setIsLoading(false);
    }, delay * 1000);
  };

  const myBets = useCallback(async () => {
    if (game == "cricket") {
      const { response, code } = await getMyBetsCricketAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else if (game == "soccer") {
      const { response, code } = await getMyBetsSoccerAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else if (game == "tennis") {
      const { response, code } = await getMyBetsTennisAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else if (game == "casino") {
      const res = await getMyBetsCasinoAPI(
        getService(location.pathname).port,
        getService(location.pathname).service,
        eventId,
        authDetails?.uid
      );
      if (res && res?.code === 200) {
        setUserBets(res.response || []);
      }
    } else {
      setUserBets([]);
    }
  }, [eventId, game, userData.token]);

  const getButtonValues = async () => {
    const { response, code } = await getButtonValuesAPI();
    if (code == 200) {
      const convertedData = {
        casino: [],
        sport: [],
      };
      for (const key in response) {
        if (key.startsWith("buttonValueCasino")) {
          const index = key.replace("buttonValueCasino", "");
          const label = response["buttonNameCasino" + index];
          const value = response[key];
          convertedData.casino.push({ label, value });
        } else if (key.startsWith("buttonValue")) {
          const index = key.replace("buttonValue", "");
          const label = response["buttonName" + index];
          const value = response[key];
          convertedData.sport.push({ label, value });
        }
      }
      setAmount(convertedData);
    }
  };
  useEffect(() => {
    if (userData?.token) {
      getButtonValues();

      myBets();
    }
  }, [userData.token]);

  const deleteCricketBet = async (id) => {
    const { response, code } = await deleteCricketBetAPI(id);
    if (code == 200) {
      toast({
        title: "Bet Deleted Successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      myBets();
    }
  };
  useEffect(() => {}, [selectedEventForBet]);
  useEffect(() => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  }, [eventId]);
  const getProfit = (value) => {
    let profit;
    let loss;
    let selectedAmount_ = value;
    if (game == "cricket" && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "casino" || game == "tennis" || game == "soccer") {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "cricket" && selectedEventForBet.isFancy) {
      if (
        applyFancyFor.find(
          (item) =>
            item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
        )
      ) {
        if (selectedEventForBet?.type == "lay") {
          profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
        } else {
          profit = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          loss = cricketFancyPL(
            selectedEventForBet.selectedRate,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
        }
      } else {
        profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
      }
    } else {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    return {
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
    };
  };
  const getLiveStreamingUrl = useCallback(async () => {
    const res = await getLiveStreamingUrlAPI(gameId);
    if (res?.code === 200) {
      setChannelId(res.response);
      setIsChannelIdFetched(true);
    }
  }, [gameId]);
  const handleOddUpdate = (e, value) => {
    if (selectedEventForBet?.marketName != "MATCH_ODDS") return;
    let odd = value
      ? parseFloat(
          parseFloat(selectedEventForBet.selectedOdd) + parseFloat(value)
        ).toFixed(2)
      : e.target.value;
    if (!String(odd).match(/^[0-9]*\.?[0-9]*$/)) odd = false;
    let profit;
    let loss;
    let selectedAmount = selectedEventForBet.selectedAmount
      ? selectedEventForBet.selectedAmount
      : 1000;
    if (odd > 0) {
      if (selectedEventForBet.isFancy && game == "cricket") {
        profit = cricketFancyPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).profit;
        loss = cricketFancyPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).loss;
      }
      if (selectedEventForBet.isBookmaker && game == "cricket") {
        profit = cricketBookMakerPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).profit;
        loss = cricketBookMakerPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).loss;
      }
      if (game == "casino" || game == "tennis" || game == "soccer") {
        profit = casinoPL(odd, selectedAmount, selectedEventForBet.type).profit;
        loss = casinoPL(odd, selectedAmount, selectedEventForBet.type).loss;
      }
    }

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        selectedOdd: odd ? odd : 0,
        profit: odd ? parseFloat(profit).toFixed(2) : null,
        loss: odd ? parseFloat(loss).toFixed(2) : null,
      },
    });
  };
  const resetBet = () => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  };
  const getRunnerName = (runnerName, data) => {
    let runnerName_;
    if (String(data?.selectionId)?.startsWith("1")) {
      runnerName_ = runnerName + " (A)";
    } else {
      runnerName_ = runnerName + " (B)";
    }

    return runnerName_;
  };
  useEffect(() => {
    if (gameId) {
      getLiveStreamingUrl();
    }
  }, [gameId, game]);

  const betCount = (arr) => {
    if (arr.length > 0) {
      return arr.filter((j) => j.betStatus === "MATCHED").length;
    }
  };

  useEffect(() => {
    console.log(betCount(userBets), "betCount");
    const _lastBetCount =
      parseInt(localStorage.getItem("lastBetCount")) || lastBetCount;
    if (betCount(userBets) > _lastBetCount) {
      localStorage.setItem("lastBetCount", betCount(userBets));
      toast({
        title: "Bet Placed Successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  }, [userBets]);

  console.log(userBets, "userBets");

  return (
    <Box
      borderTopRadius="5px"
      width={{ base: "100%", lg: w ? w : "30%" }}
      h="full"
      className="bb-bsh"
    >
      <Modal
        size={"5xl"}
        isOpen={isResultModalOpen}
        onClose={setIsResultModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <CasinoResultModal
            marketId={lastResultId}
            onClose={setIsResultModalOpen}
            isCustom={true}
            casinoKey={casinoGameKey}
          />
        </ModalContent>
      </Modal>

      {!forMobile ? (
        <>
          {game == "cricket" || game == "tennis" || game == "soccer" ? (
            <Accordion
              w="100%"
              allowToggle
              bg="#cfcfcf"
              fontSize="sm"
              defaultIndex={[0]}
              p="0"
              className="m-b-10"
            >
              <Accordion
                w="100%"
                allowToggle
                bg="#cfcfcf"
                fontSize="sm"
                p="0"
                defaultChecked={false}
              >
                <AccordionItem p="0" w="100%" border="none">
                  <AccordionButton
                    w="100%"
                    color="gray"
                    fontWeight="bold"
                    fontSize="md"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    bg="#ddd"
                  >
                    <Text>Live TV</Text>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel p="0" w="100%">
                    <iframe
                      style={{
                        width: "100%",
                        height: "30vh",
                      }}
                      src={`https://nlivetv.lagaikhaipro.com/rtv.php?eventId=${searchParams.get(
                        "gameId"
                      )}`}
                    ></iframe>

                    {/* <iframe
                style={{ width: "100%" }}
                src={`https://sqmr.xyz/nit.php?eventId=${gameId?.gameId}`}
              ></iframe> */}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <AccordionItem p="0" w="100%" border="none">
                <AccordionButton
                  w="100%"
                  fontSize="sm"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  bg="#cfcfcf"
                >
                  <Text className="text-black" fontSize="sm">
                    MATCHED BET
                  </Text>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="0" w="100%">
                  <Table variant="unstyled" size="sm">
                    <Thead fontSize="sm">
                      <Tr fontSize="xs">
                        <Th
                          style={{
                            backgroundColor: "#27A6C3",
                            border: "1px solid white",
                          }}
                        >
                          <Text>Matched Bet</Text>
                        </Th>
                        <Th
                          style={{
                            backgroundColor: "#27A6C3",
                            border: "1px solid white",
                          }}
                        >
                          <Text>Odds</Text>
                        </Th>
                        <Th
                          style={{
                            backgroundColor: "#27A6C3",
                            border: "1px solid white",
                          }}
                        >
                          <Text>Stake</Text>
                        </Th>
                        {game != "casino" &&
                          game !== "tennis" &&
                          game !== "soccer" && (
                            <Th
                              style={{
                                backgroundColor: "#27A6C3",
                                border: "1px solid white",
                              }}
                            >
                              <Text>Market Name</Text>
                            </Th>
                          )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userBets && userBets.length === 0 && (
                        <Tr>
                          <Td colSpan={4}>No record found</Td>
                        </Tr>
                      )}
                      {game == "casino" &&
                        userBets?.map((item) => (
                          <Tr
                            backgroundColor={`${
                              item.back ? "#72BBEF" : "#FAA9BA"
                            }`}
                            fontWeight="bold"
                          >
                            <Td>
                              <Text>
                                {seperationRequired
                                  ? getRunnerName(item.runnerName)
                                  : item.runnerName}
                              </Text>
                            </Td>
                            <Td>
                              <Text>{item.lay ? item.lay : item.back}</Text>
                            </Td>
                            <Td>
                              <Text>{item.amount}</Text>
                            </Td>

                            {game == "casino" && (
                              <Td>
                                <Text fontSize="10px !important">
                                  {item.marketName == "MATCH_ODDS"
                                    ? "MATCH ODDS"
                                    : item.marketName == "BOOKMAKER_ODDS_1" ||
                                      item.marketName == "BOOKMAKER_ODDS_2"
                                    ? "BOOKMAKER ODDS"
                                    : item.marketName}
                                </Text>
                              </Td>
                            )}
                          </Tr>
                        ))}
                      {userBets
                        ?.filter((item) => item.betStatus == "MATCHED")
                        ?.map((item) => (
                          <Tr
                            backgroundColor={`${
                              item.back ? "#72BBEF" : "#FAA9BA"
                            }`}
                            fontWeight="bold"
                          >
                            <Td>
                              <Text>
                                {seperationRequired
                                  ? getRunnerName(item.runnerName, item)
                                  : item.runnerName}
                              </Text>
                            </Td>
                            <Td>
                              <Text>{item.lay ? item.lay : item.back}</Text>
                            </Td>
                            <Td>
                              <Text>{item.amount}</Text>
                            </Td>

                            {game != "casino" && (
                              <Td>
                                <Text fontSize="10px !important">
                                  {item.marketName == "MATCH_ODDS"
                                    ? "MATCH ODDS"
                                    : item.marketName == "BOOKMAKER_ODDS_1" ||
                                      item.marketName == "BOOKMAKER_ODDS_2"
                                    ? "BOOKMAKER ODDS"
                                    : item.marketName}
                                </Text>
                              </Td>
                            )}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                  {/* {userBets.filter((item) => item.betStatus == 'UNMATCHED')
                  .length > 0 && (
                  <Table variant='unstyled' size='sm'>
                    <Thead fontSize='sm'>
                      <Tr fontSize='xs'>
                        <Th>
                          <Text color='whiteAlpha.500'>Un-Matched Bet</Text>
                        </Th>
                        <Th>
                          <Text color='whiteAlpha.500'>Odds</Text>
                        </Th>
                        <Th>
                          <Text color='whiteAlpha.500'>Stake</Text>
                        </Th>
                        {game != 'casino' &&
                          game !== 'tennis' &&
                          game !== 'soccer' && (
                            <Th>
                              <Text color='whiteAlpha.500'>Market Name</Text>
                            </Th>
                          )}
                        {game == 'cricket' && <Th></Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userBets
                        .filter((item) => item.betStatus == 'UNMATCHED')
                        .map((item) => (
                          <Tr
                            borderLeft={`5px ${
                              item.back ? '#72BBEF' : '#FAA9BA'
                            } solid`}
                            fontWeight='bold'
                            bg='#ddd'
                          >
                            <Td>
                              <Text>
                                {seperationRequired
                                  ? getRunnerName(item.runnerName, item)
                                  : item.runnerName}
                              </Text>
                            </Td>
                            <Td>
                              <Text>{item.lay ? item.lay : item.back}</Text>
                            </Td>
                            <Td>
                              <Text>{item.amount}</Text>
                            </Td>
                            {game != 'casino' && (
                              <Td>
                                <Text fontSize='10px !important'>
                                  {item.marketName == 'MATCH_ODDS'
                                    ? 'MATCH ODDS'
                                    : item.marketName == 'BOOKMAKER_ODDS_1' ||
                                      item.marketName == 'BOOKMAKER_ODDS_2'
                                    ? 'BOOKMAKER ODDS'
                                    : item.marketName}
                                </Text>
                              </Td>
                            )}
                            {game == 'cricket' && (
                              <Td>
                                <Button
                                  size='xs'
                                  colorScheme='blue'
                                  onClick={() => deleteCricketBet(item.id)}
                                >
                                  Delete
                                </Button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                )} */}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <>
              <Accordion
                w="100%"
                allowToggle
                bg="#cfcfcf"
                fontSize="sm"
                defaultIndex={[0]}
                display={{
                  base: "none",
                  lg: "block",
                }}
                className="m-b-10 casino-bet-place-accordion"
              >
                <AccordionItem w="100%" border="none">
                  <AccordionButton
                    w="100%"
                    color="gray"
                    fontWeight="bold"
                    fontSize="md"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    backgroundColor="#27A6C3 !important"
                  >
                    <Text>Place Bet</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  {selectedEventForBet && (
                    <AccordionPanel w="100%" padding={0}>
                      <>
                        <Box
                          className={`place-bet-box-new ${selectedEventForBet?.type}`}
                        >
                          <Box className="place-bet-body">
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      fontFamily: "'Inter',Helvetica",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      color: "black",
                                    }}
                                  >
                                    (Bet for)
                                  </th>
                                  <th
                                    style={{
                                      fontFamily: "'Inter',Helvetica",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      color: "black",
                                    }}
                                  >
                                    Odds
                                  </th>
                                  <th
                                    style={{
                                      fontFamily: "'Inter',Helvetica",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      color: "black",
                                    }}
                                  >
                                    Stake
                                  </th>
                                  <th
                                    style={{
                                      fontFamily: "'Inter',Helvetica",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      color: "black",
                                    }}
                                  >
                                    Profit
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Text>
                                      {selectedEventForBet?.runnerName}
                                    </Text>
                                  </td>
                                  <td>
                                    {" "}
                                    <NumberInput
                                      minW="80px"
                                      w="90px"
                                      fontSize="xs"
                                      border={
                                        selectedEventForBet?.marketName !=
                                        "MATCH_ODDS"
                                          ? "1px solid #494949"
                                          : "none"
                                      }
                                      type="number"
                                      value={selectedEventForBet?.selectedOdd}
                                      color={
                                        selectedEventForBet?.marketName !=
                                        "MATCH_ODDS"
                                          ? buttonColors
                                          : "white"
                                      }
                                    >
                                      <NumberInputField
                                        onChange={handleOddUpdate}
                                        value={selectedEventForBet?.selectedOdd}
                                      />
                                      {selectedEventForBet?.marketName ==
                                        "MATCH_ODDS" && (
                                        <NumberInputStepper>
                                          <NumberIncrementStepper
                                            color="white"
                                            onClick={(e) =>
                                              handleOddUpdate(e, 0.01)
                                            }
                                          />
                                          <NumberDecrementStepper
                                            color="white"
                                            onClick={(e) =>
                                              handleOddUpdate(e, -0.01)
                                            }
                                          />
                                        </NumberInputStepper>
                                      )}
                                    </NumberInput>
                                  </td>
                                  <td>
                                    <CustomInput
                                      onChange={(e) => {
                                        if (e.target.value > 1000000) return;
                                        setSelectedAmount(e.target.value);
                                      }}
                                      type="number"
                                      value={selectedEventForBet?.amount}
                                      borderRadius={0}
                                      placeholder="Amount"
                                      border="none"
                                      backgroundColor="#ddd"
                                    />
                                  </td>
                                  <td>
                                    <span className="profit">
                                      {selectedEventForBet.profit &&
                                        parseInt(
                                          selectedEventForBet?.profit
                                        )?.toFixed(2)}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {/* <Text>{selectedEventForBet.marketName}</Text> */}
                            <WrapItem
                              alignItems="center"
                              w="100%"
                              justifyContent="space-between"
                              fontSize="sm"
                              my="2"
                            >
                              {/* <Text mr="auto" color="yellow.400">
                        {selectedEventForBet?.runnerName}
                      </Text> */}

                              {/* <Input
                   w="70px"
                   fontSize="xs"
                   p="2"
                   onChange={handleOddUpdate}
                   value={selectedEventForBet?.selectedOdd}
                 /> */}
                            </WrapItem>
                            {/* <Flex alignItems="center" justifyContent="space-between">
                      {parseInt(selectedEventForBet?.profit) ? (
                        <Flex direction="column" w="40%">
                          <Text fontSize="sm" color="green.400">
                            profit :{" "}
                            {selectedEventForBet.profit &&
                              parseInt(selectedEventForBet?.profit)?.toFixed(2)}
                          </Text>
                          <Text fontSize="sm" color="red.400">
                            loss :{" "}
                            {selectedEventForBet.loss &&
                              parseInt(selectedEventForBet?.loss).toFixed(2)}
                          </Text>
                        </Flex>
                      ) : null}
                    </Flex> */}

                            <Grid templateColumns="repeat(4, 1fr)" gap={0}>
                              {amount[
                                game == "casino" ? "casino" : "sport"
                              ].map((amt) => (
                                <GridItem
                                  onClick={() => {
                                    setSelectedAmount(amt.value);
                                  }}
                                  key={amt.label}
                                  textAlign="center"
                                  borderRadius="4px"
                                  bg={"#FFCB9A"}
                                  cursor="pointer"
                                  color="#884202"
                                  margin="1px"
                                >
                                  {amt.label}
                                </GridItem>
                              ))}
                            </Grid>
                            <Box className="buttons">
                              <Button onClick={resetBet} className="reset-btn">
                                Reset
                              </Button>
                              <Button
                                className="submit-btn"
                                color="#fff"
                                onClick={placeBet}
                                bg="red"
                                isDisabled={
                                  selectedEventForBet?.amount == 0 || isLoading
                                }
                              >
                                {isLoading ? (
                                  <Image
                                    h="30px"
                                    w="30px"
                                    src={require("../../assets/img/spinner.gif")}
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    </AccordionPanel>
                  )}
                </AccordionItem>
              </Accordion>

              <Accordion
                w="100%"
                allowToggle
                bg="#cfcfcf"
                fontSize="sm"
                defaultIndex={[0]}
                p="0"
                className="m-b-10"
              >
                <AccordionItem p="0" w="100%" border="none">
                  <AccordionButton
                    w="100%"
                    color="gray"
                    fontSize="sm"
                    fontWeight="bold"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    bg="#27A6C3 !important"
                  >
                    <Text fontSize="sm">MY BETS</Text>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel p="0" w="100%">
                    <Table variant="unstyled" size="sm">
                      <Thead fontSize="sm">
                        <Tr fontSize="xs">
                          <Th>
                            <Text className="text-black">Matched Bet</Text>
                          </Th>
                          <Th>
                            <Text className="text-black">Odds</Text>
                          </Th>
                          <Th>
                            <Text className="text-black">Stake</Text>
                          </Th>
                          {game != "casino" &&
                            game !== "tennis" &&
                            game !== "soccer" && (
                              <Th>
                                <Text className="text-black">Market Name</Text>
                              </Th>
                            )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {userBets && userBets.length === 0 && (
                          <Tr>
                            <Td colSpan={4}>No record found</Td>
                          </Tr>
                        )}
                        {game == "casino" &&
                          userBets?.map((item) => (
                            <Tr
                              backgroundColor={`${
                                item.back ? "#72BBEF" : "#FAA9BA"
                              }`}
                              fontWeight="bold"
                            >
                              <Td>
                                <Text>
                                  {seperationRequired
                                    ? getRunnerName(item.runnerName)
                                    : item.runnerName}
                                </Text>
                              </Td>
                              <Td>
                                <Text>{item.lay ? item.lay : item.back}</Text>
                              </Td>
                              <Td>
                                <Text>{item.amount}</Text>
                              </Td>

                              {game == "casino" && (
                                <Td>
                                  <Text fontSize="10px !important">
                                    {item.marketName == "MATCH_ODDS"
                                      ? "MATCH ODDS"
                                      : item.marketName == "BOOKMAKER_ODDS_1" ||
                                        item.marketName == "BOOKMAKER_ODDS_2"
                                      ? "BOOKMAKER ODDS"
                                      : item.marketName}
                                  </Text>
                                </Td>
                              )}
                            </Tr>
                          ))}
                        {userBets
                          ?.filter((item) => item.betStatus == "MATCHED")
                          ?.map((item) => (
                            <Tr
                              backgroundColor={`${
                                item.back ? "#72BBEF" : "#FAA9BA"
                              }`}
                              fontWeight="bold"
                            >
                              <Td>
                                <Text>
                                  {seperationRequired
                                    ? getRunnerName(item.runnerName, item)
                                    : item.runnerName}
                                </Text>
                              </Td>
                              <Td>
                                <Text>{item.lay ? item.lay : item.back}</Text>
                              </Td>
                              <Td>
                                <Text>{item.amount}</Text>
                              </Td>

                              {game != "casino" && (
                                <Td>
                                  <Text fontSize="10px !important">
                                    {item.marketName == "MATCH_ODDS"
                                      ? "MATCH ODDS"
                                      : item.marketName == "BOOKMAKER_ODDS_1" ||
                                        item.marketName == "BOOKMAKER_ODDS_2"
                                      ? "BOOKMAKER ODDS"
                                      : item.marketName}
                                  </Text>
                                </Td>
                              )}
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                    {/* {userBets.filter((item) => item.betStatus == 'UNMATCHED')
                  .length > 0 && (
                  <Table variant='unstyled' size='sm'>
                    <Thead fontSize='sm'>
                      <Tr fontSize='xs'>
                        <Th>
                          <Text color='whiteAlpha.500'>Un-Matched Bet</Text>
                        </Th>
                        <Th>
                          <Text color='whiteAlpha.500'>Odds</Text>
                        </Th>
                        <Th>
                          <Text color='whiteAlpha.500'>Stake</Text>
                        </Th>
                        {game != 'casino' &&
                          game !== 'tennis' &&
                          game !== 'soccer' && (
                            <Th>
                              <Text color='whiteAlpha.500'>Market Name</Text>
                            </Th>
                          )}
                        {game == 'cricket' && <Th></Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userBets
                        .filter((item) => item.betStatus == 'UNMATCHED')
                        .map((item) => (
                          <Tr
                            borderLeft={`5px ${
                              item.back ? '#72BBEF' : '#FAA9BA'
                            } solid`}
                            fontWeight='bold'
                            bg='#ddd'
                          >
                            <Td>
                              <Text>
                                {seperationRequired
                                  ? getRunnerName(item.runnerName, item)
                                  : item.runnerName}
                              </Text>
                            </Td>
                            <Td>
                              <Text>{item.lay ? item.lay : item.back}</Text>
                            </Td>
                            <Td>
                              <Text>{item.amount}</Text>
                            </Td>
                            {game != 'casino' && (
                              <Td>
                                <Text fontSize='10px !important'>
                                  {item.marketName == 'MATCH_ODDS'
                                    ? 'MATCH ODDS'
                                    : item.marketName == 'BOOKMAKER_ODDS_1' ||
                                      item.marketName == 'BOOKMAKER_ODDS_2'
                                    ? 'BOOKMAKER ODDS'
                                    : item.marketName}
                                </Text>
                              </Td>
                            )}
                            {game == 'cricket' && (
                              <Td>
                                <Button
                                  size='xs'
                                  colorScheme='blue'
                                  onClick={() => deleteCricketBet(item.id)}
                                >
                                  Delete
                                </Button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                )} */}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </>
          )}
        </>
      ) : (
        <>
          <Tabs>
            <TabList>
              <Tab>Odds</Tab>
              <Tab>My Bets</Tab>
              <Tab>Live Tv</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>{children}</TabPanel>
              <TabPanel>
                <Table variant="unstyled" size="sm">
                  <Thead fontSize="sm">
                    <Tr fontSize="xs">
                      <Th>
                        <Text color="whiteAlpha.500">Matched Bet</Text>
                      </Th>
                      <Th>
                        <Text color="whiteAlpha.500">Odds</Text>
                      </Th>
                      <Th>
                        <Text color="whiteAlpha.500">Stake</Text>
                      </Th>
                      {game != "casino" &&
                        game !== "tennis" &&
                        game !== "soccer" && (
                          <Th>
                            <Text color="whiteAlpha.500">Market Name</Text>
                          </Th>
                        )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userBets && userBets.length === 0 && (
                      <Tr>
                        <Td colSpan={4}>No record found</Td>
                      </Tr>
                    )}
                    {game == "casino" &&
                      userBets?.map((item) => (
                        <Tr
                          backgroundColor={`${
                            item.back ? "#72BBEF" : "#FAA9BA"
                          }`}
                          fontWeight="bold"
                        >
                          <Td>
                            <Text>
                              {seperationRequired
                                ? getRunnerName(item.runnerName)
                                : item.runnerName}
                            </Text>
                          </Td>
                          <Td>
                            <Text>{item.lay ? item.lay : item.back}</Text>
                          </Td>
                          <Td>
                            <Text>{item.amount}</Text>
                          </Td>

                          {game == "casino" && (
                            <Td>
                              <Text fontSize="10px !important">
                                {item.marketName == "MATCH_ODDS"
                                  ? "MATCH ODDS"
                                  : item.marketName == "BOOKMAKER_ODDS_1" ||
                                    item.marketName == "BOOKMAKER_ODDS_2"
                                  ? "BOOKMAKER ODDS"
                                  : item.marketName}
                              </Text>
                            </Td>
                          )}
                        </Tr>
                      ))}
                    {userBets
                      ?.filter((item) => item.betStatus == "MATCHED")
                      ?.map((item) => (
                        <Tr
                          backgroundColor={`${
                            item.back ? "#72BBEF" : "#FAA9BA"
                          }`}
                          fontWeight="bold"
                        >
                          <Td>
                            <Text>
                              {seperationRequired
                                ? getRunnerName(item.runnerName, item)
                                : item.runnerName}
                            </Text>
                          </Td>
                          <Td>
                            <Text>{item.lay ? item.lay : item.back}</Text>
                          </Td>
                          <Td>
                            <Text>{item.amount}</Text>
                          </Td>

                          {game != "casino" && (
                            <Td>
                              <Text fontSize="10px !important">
                                {item.marketName == "MATCH_ODDS"
                                  ? "MATCH ODDS"
                                  : item.marketName == "BOOKMAKER_ODDS_1" ||
                                    item.marketName == "BOOKMAKER_ODDS_2"
                                  ? "BOOKMAKER ODDS"
                                  : item.marketName}
                              </Text>
                            </Td>
                          )}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                {/* {userBets.filter((item) => item.betStatus == "UNMATCHED")
                  .length > 0 && (
                  <Table variant="unstyled" size="sm">
                    <Thead fontSize="sm">
                      <Tr fontSize="xs">
                        <Th>
                          <Text color="whiteAlpha.500">Un-Matched Bet</Text>
                        </Th>
                        <Th>
                          <Text color="whiteAlpha.500">Odds</Text>
                        </Th>
                        <Th>
                          <Text color="whiteAlpha.500">Stake</Text>
                        </Th>
                        {game != "casino" &&
                          game !== "tennis" &&
                          game !== "soccer" && (
                            <Th>
                              <Text color="whiteAlpha.500">Market Name</Text>
                            </Th>
                          )}
                        {game == "cricket" && <Th></Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userBets
                        .filter((item) => item.betStatus == "UNMATCHED")
                        .map((item) => (
                          <Tr
                            borderLeft={`5px ${
                              item.back ? "#72BBEF" : "#FAA9BA"
                            } solid`}
                            fontWeight="bold"
                            bg="#ddd"
                          >
                            <Td>
                              <Text>
                                {seperationRequired
                                  ? getRunnerName(item.runnerName, item)
                                  : item.runnerName}
                              </Text>
                            </Td>
                            <Td>
                              <Text>{item.lay ? item.lay : item.back}</Text>
                            </Td>
                            <Td>
                              <Text>{item.amount}</Text>
                            </Td>
                            {game != "casino" && (
                              <Td>
                                <Text fontSize="10px !important">
                                  {item.marketName == "MATCH_ODDS"
                                    ? "MATCH ODDS"
                                    : item.marketName == "BOOKMAKER_ODDS_1" ||
                                      item.marketName == "BOOKMAKER_ODDS_2"
                                    ? "BOOKMAKER ODDS"
                                    : item.marketName}
                                </Text>
                              </Td>
                            )}
                            {game == "cricket" && (
                              <Td>
                                <Button
                                  size="xs"
                                  colorScheme="blue"
                                  onClick={() => deleteCricketBet(item.id)}
                                >
                                  Delete
                                </Button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                )} */}
              </TabPanel>
              <TabPanel>
                {isChannelIdFetched && (
                  <iframe
                    style={{
                      width: '100%',
                      height: '30vh',
                    }}
                    src={`https://ss247.life/api/97bef780da2b6a17ace2e366225eeb3b962fe743/Nstreamapi.php?chid=${channelId}`}
                  ></iframe>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}

      {isMobileView && (
        <Modal
          isOpen={
            selectedEventForBet &&
            isMobileView &&
            selectedEventForBet?.selectedOdd &&
            selectedEventForBet?.selectedOdd > 0
          }
          onClose={() => {
            dispatch({
              type: "gameData/setSelectedEventForBet",
              payload: null,
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text>Bet Slip</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody bg="#cfcfcf">
              <Text>{teamName}</Text>
              <Text>{selectedEventForBet?.marketName}</Text>
              <WrapItem
                alignItems="center"
                w="100%"
                justifyContent="space-between"
                fontSize="sm"
                my="2"
              >
                <Text color="yellow.400">
                  {selectedEventForBet?.runnerName}
                </Text>
                <Input
                  w="70px"
                  fontSize="xs"
                  p="2"
                  onChange={handleOddUpdate}
                  value={selectedEventForBet?.selectedOdd}
                />
              </WrapItem>
              <Flex alignItems="center" justifyContent="space-between">
                <CustomInput
                  onChange={(e) => {
                    if (e.target.value > 1000000) return;
                    setSelectedAmount(e.target.value, "CHANGE");
                  }}
                  type="number"
                  my="2"
                  containerStyle={{ width: "30%", marginRight: "20px" }}
                  value={selectedEventForBet?.amount}
                  borderRadius={0}
                  placeholder="Amount"
                  border="none"
                  backgroundColor="#ddd"
                />

                {parseInt(selectedEventForBet?.profit) ? (
                  <Flex direction="column" w="40%">
                    <Text fontSize="sm" color="green.400">
                      profit :{" "}
                      {selectedEventForBet.profit &&
                        parseInt(selectedEventForBet?.profit)?.toFixed(2)}
                    </Text>
                    <Text fontSize="sm" color="red.400">
                      loss :{" "}
                      {selectedEventForBet.loss &&
                        parseInt(selectedEventForBet?.loss).toFixed(2)}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>

              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                {amount[game == "casino" ? "casino" : "sport"].map((amt) => (
                  <GridItem
                    onClick={() => {
                      setSelectedAmount(amt.value);
                    }}
                    key={amt.label}
                    textAlign="center"
                    p={2}
                    borderRadius="md"
                    bg={"#0088cc"}
                    cursor="pointer"
                    color="#fff"
                  >
                    {amt.label}
                  </GridItem>
                ))}
              </Grid>

              <Button
                color="black"
                onClick={placeBet}
                w="100%"
                my="4"
                bg={buttonColors}
                backgroundColor="#045662 !important"
                isDisabled={selectedEventForBet?.amount == 0 || isLoading}
              >
                {isLoading ? (
                  <Image
                    h="30px"
                    w="30px"
                    src={require("../../assets/img/spinner.gif")}
                  />
                ) : (
                  "Place Bet"
                )}
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {inningsRule && inningsRule}
    </Box>
  );
}
