import { Box, Flex, Img } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import PlaceBet from "../../game/PlaceBet";
import "./css/cmeter.css";
import { ASSETS_URL } from "../../../utils/constants";
import { getCasinoPlExposureAPI } from "../../../service/casino";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
export default function CasinoMeter() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.casinoMeterOdds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const dispatch = useDispatch();
  const gameName = "CASINO_METER";
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };
  const [isLeft, setIsLeft] = useState(false);

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id, left = true) => {
    setIsLeft(left);
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey("casinoMeter"));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const calculateCardSum = (cards) => {
    let sum = 0;
    cards.forEach((card) => {
      if (card.includes("A")) {
        sum += 1;
      } else if (card.includes("J")) {
        sum += 11;
      } else if (card.includes("Q")) {
        sum += 12;
      } else if (card.includes("K")) {
        sum += 13;
      } else {
        sum += parseInt(card);
      }
    });
    return sum;
  };
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box
        mr="1"
        w={{ base: "100%", sm: "100%", lg: "70%" }}
        className="casino-meeter-box-main"
      >
        <Box
          mb="2"
          pos="relative"
          className="casino-video"
        >
          <div className="market-title">Casino Meter</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3046`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}cmeter-rules.jpg`} />
        </Box>
        
        <div className="meter-lh-card-container mt-1">
          <h5 className="d-inline-block mb-0">
            <b data-v-22066885="">Low</b>{" "}
            <span className="text-primary ml-1">
              {" "}
              {odds?.cards && calculateCardSum(odds?.cards[0])}
            </span>
          </h5>

          <div className="d-inline-block text-center meter-lh-card">
            <Flex>
              {odds.cards &&
                odds?.cards[0]?.map(
                  (card) =>
                    card && (
                      <span>
                        <img src={ASSETS_URL + card + ".png"} />
                      </span>
                    )
                )}
            </Flex>
          </div>
        </div>
        <div className="meter-lh-card-container mt-1">
          <h5 className="d-inline-block mb-0">
            <b data-v-22066885="">High</b>{" "}
            <span className="text-primary ml-1">
              {" "}
              {odds?.cards && calculateCardSum(odds?.cards[1])}
            </span>
          </h5>

          <div className="d-inline-block text-center meter-lh-card">
            <Flex>
              {odds.cards &&
                odds?.cards[1]?.map(
                  (card) =>
                    card && (
                      <span>
                        <img src={ASSETS_URL + card + ".png"} />
                      </span>
                    )
                )}
            </Flex>
          </div>
        </div>

        <div className="row row5 mt-2">
          <div className="col-6 full-mobile">
            <div className={`meter-content ${isLocked ? "suspended" : ""}`}>
              <button className="btn-theme">
                <div className="cmeter-title">
                  <h4 className="d-inline-block">
                    <b data-v-22066885="">Low</b>
                  </h4>
                </div>
                <div
                  className="text-center"
                  style={{ display: "flex", direction: "row", justifyContent:"center" }}
                >
                  {["A", 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                    <div
                      onClick={() => setEvent("Low")}
                      className="casino-card-item"
                    >
                      <div className="card-image">
                        <img src={ASSETS_URL + item + ".png"} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="text-center"></div>
              </button>
            </div>
            <span className="float-left d-block mt-1">
              {" "}
              {getExposureForEntity("Low")?.exposure}
            </span>
            <p className="m-b-0 m-t-5 text-right min-max">
              <span data-v-22066885="">
                <b data-v-22066885="">Min:</b>10
              </span>{" "}
              <span className="m-l-5">
                <b data-v-22066885="">Max:</b>25000
              </span>
            </p>
          </div>
          <div className="col-6 full-mobile">
            <div className={`meter-content ${isLocked ? "suspended" : ""}`}>
              <button className="btn-theme">
                <div className="cmeter-title">
                  <h4 className="d-inline-block">
                    <b data-v-22066885="">High</b>
                  </h4>
                </div>
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "center",
                  }}
                >
                  {[10, "J", "Q", "K"].map((item) => (
                    <div
                      onClick={() => setEvent("High", false)}
                      className="casino-card-item"
                    >
                      <div className="card-image">
                        <img src={ASSETS_URL + item + ".png"} />
                      </div>
                    </div>
                  ))}
                </div>
              </button>
            </div>
            <span className="float-left d-block mt-1">
              {" "}
              {getExposureForEntity("High")?.exposure}
            </span>
            <p className="m-b-0 m-t-5 text-right min-max">
              <span data-v-22066885="">
                <b data-v-22066885="">Min:</b>10
              </span>{" "}
              <span className="m-l-5">
                <b data-v-22066885="">Max:</b>25000
              </span>
            </p>
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
